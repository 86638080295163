import {getSession} from "./auth";
import {Navigate, Outlet} from "react-router-dom";


const PrivateRoute = ({upgradeAccount}) => {
    const token = getSession();


    if (token !== undefined) {
        if (upgradeAccount) {
            return <Navigate to="/packages" replace />;
        } else {
            return <Outlet />;
        }
    } else {
        return <Navigate to="/login" replace />;
    }


}

export default PrivateRoute;
