import {
    Avatar,
    Button,
    Center, Dialog,
    Group,
    Image,
    Loader,
    Modal, MultiSelect, Select,
    Text,
    TextInput, ThemeIcon,
    Title,
    UnstyledButton
} from "@mantine/core";
import {HiCog, HiOutlinePencilAlt} from "react-icons/hi";
import { IoClose, IoDownload, IoInformation, IoInformationCircle, IoPersonAdd } from "react-icons/io5";
import {useDisclosure, useDocumentTitle} from "@mantine/hooks";
import {useForm} from "@mantine/form";
import {Link, useParams} from "react-router-dom";
import Connect from "../../../lib/util/Connect";
import {ErrorViewing} from "../../../lib/util/ErrorViewing";
import {modals} from "@mantine/modals";
import {useEffect, useState} from "react";
import {notifications} from "@mantine/notifications";
import {ErrorMessage} from "../../../lib/util/ErrorMessage";
import {RiInformationLine} from "react-icons/ri";
import {connect} from "react-redux";
import {getModalInfo, setModalInfo} from "../../../lib/util/user";
import {QuestionModal} from "../../../lib/util/QuestionModal";
import { MdDownload } from 'react-icons/md';

export const SelectProfile = ({user}) => {

    useDocumentTitle('Profil Seç')


    const [opened, {open, close}] = useDisclosure(false);
    const [pageLoading, setPageLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [infoStatus,setInfoStatus] = useState(false);
    const [users, setUsers] = useState([]);
    const [userInfo,setUserInfo] = useState({});
    const [editUser,setEditUser] = useState('');
    const [openProfile,setOpenProfile] = useState(false);
    const [userList,setUserList] = useState([]);
    const [selectedList,setSelectedList] = useState([]);


    const form = useForm({
        initialValues: {
            title: '',
        },

        validate: {
            title: (value) => (value !== '' ? null : 'Profil Etiketi girmelisiniz.'),
        },
    });

    useEffect(() => {

        getProfiles();

    }, []);


    useEffect(()=>{

        if (user !== null){

            setUserInfo(user);


            if (!getModalInfo()){
                if (user?.member_type === 'Test'){

                    setInfoStatus(true);
                    setModalInfo();

                }
            }


        }


    },[user]);


    const getProfiles = () => {

        Connect().get('profile/get/list').then(({data}) => {


         //   console.log('data', data);

            setUsers(data.result);



            setTimeout(() => {
                setPageLoading(false);

            }, 250);

        }).catch((e) => {
            notifications.show({
                title: 'Hata',
                withCloseButton:false,
                message: ErrorMessage(e),
                color: 'red'
            });
        });

    }

    const setProfile = (values) => {

        setLoading(true);

        Connect().post('profile/add',{
            profile_tag:values.title
        }).then(({data}) =>{

            let _user = [...users];
            let _data = data.result;

            _user.push({
                id:_data.id,
                img_pp_s3_url:_data.img_pp_s3_url,
                profile_tag:_data.profile_tag,
                uuid:_data.uuid
            });

            setUsers(_user);

            close();


        }).catch((e) => {
            notifications.show({
                title: 'Hata',
                withCloseButton:false,
                message: ErrorMessage(e),
                color: 'red'
            });
        }).finally(() => {

            setLoading(false);

        });

    }

    const removeProfile = (id) => {

        Connect().get(`profile/deleted/${id}`).then(({data}) => {


            getProfiles();

            notifications.show({
                title: 'Başarılı',
                withCloseButton:false,
                message: 'Profil Silindi',
                color:'teal'
            });

        }).catch((e) => {
            notifications.show({
                title: 'Hata',
                withCloseButton:false,
                message: ErrorMessage(e),
                color:'red'
            });
        }).finally(() => {

            setEditUser('');

        });

    }




    if (pageLoading) {
        return (<div className={"page-full-loading"}>
            <Loader color="violet" size={"xl"} variant="dots"/>
        </div>)
    }

    return (<>

        <Modal
            opened={infoStatus}
            onClose={() => setInfoStatus(false)}
            centered
            radius={"md"}
            size={550}
            className={"info-modal"}
            padding={35}
            overlayProps={{
                opacity: 0.55,
                blur: 3,
            }}
        >

           <div className="text-center" >
               <RiInformationLine color={"#29292a"} size={22}/>
           </div>

            <Text size={16} align={"center"} mb={6} fw={500}>Bilgilendirme</Text>

            <Text mb={20} align={'center'} fw={500} size={13} color={"violet"}>Deneme hesabınız aktive edilmiştir.
                Güzel günlerde kullanmanızı dileriz.
            </Text>

            <Text size={"xs"} color={"dimmed"} align={"center"}>
                Hibrit Card tarafından hediye edilen deneme hesabınızı 2 ay süre ile kullanabilirisiniz
            </Text>

            <Text size={"xs"} color={"dimmed"} mt={15} align={"center"}>
                Deneme hesabınızda sadece 1 adet Profil aktive edebilirsiniz. Standart veya Premium Hesap avantajlarından faydalanmak için şimdi satın alabilirsiniz.
            </Text>



            <Center mt={40}>
                <Link to={'/packages'} className={"cash-btn"}>Şimdi Satın Al</Link>
            </Center>

            <Button radius={20} style={{height:40}} fullWidth mt={15}  onClick={()  => setInfoStatus(false)} color={"violet"}>Tamam</Button>


        </Modal>


        <Modal padding={25} size={500} centered opened={opened} onClose={close} title="Yeni Profil Ekle">
            <form onSubmit={form.onSubmit((values) => setProfile(values))}>
                <TextInput
                    withAsterisk
                    label="Profil Etiketi"
                    className={"form-input"}
                    placeholder="Profil Etiketi"
                    {...form.getInputProps('title')}
                />
                <Group position="right" mt="xl">
                    <Button loading={loading} type="submit" color={"violet"}>Profil Ekle</Button>
                </Group>
            </form>
        </Modal>




        <div id="select-profile">

            <div id="profile-items">
              <Group>
                  <UnstyledButton component={Link} to={'/user-settings'}>
                      <HiCog size={20}/>
                  </UnstyledButton>
              </Group>
            </div>

            <Center mt={100}>
                <Image src={'/img/white-logo.svg'} width={220}/>
            </Center>


            <div className="d-table">
                <div className="table-center">

                    <Center mb={30} mt={20}>
                        <Title order={2} color={"white"}>Profil Seç</Title>
                    </Center>
                    <Center>
                        <Group spacing={30} position={"center"}>
                            {users.map((item, index) => (
                               <div style={{position:'relative'}}>

                                   <ThemeIcon size={30} color={"red"}  className={"edit-btn"} radius={"xl"} onClick={() => {
                                       QuestionModal({
                                           modals,
                                           title: 'Profili Sil',
                                           text: 'Profili silmek',
                                           confirm: () => removeProfile(item.uuid)
                                       });
                                   }}>
                                       <IoClose/>

                                   </ThemeIcon>

                                   <Link to={`/view/${item.uuid}`} className={'text-center avatar-item'} >
                                       <Avatar radius={80} size={80}
                                               src={item.img_pp_s3_url}
                                               alt={item.profile_tag}/>
                                       <Text>{item.profile_tag}</Text>
                                   </Link>
                               </div>
                            ))}



                            {(userInfo?.member_type === 'Test' || userInfo?.member_type === 'Standart') &&
                                <>
                                {users.length === 0 &&
                                    <div className={"text-center avatar-item"} onClick={() => open()}>
                                        <Avatar size={80} src={null} alt={'Profil Ekle'} radius={80}>
                                            <IoPersonAdd size={26}/>
                                        </Avatar>
                                        <Text>Profil Ekle</Text>
                                    </div>
                                }
                                </>
                            }

                            {(userInfo?.member_type === 'Premium' && users.length < 6) &&
                                <div className={"text-center avatar-item"} onClick={() => open()}>
                                    <Avatar size={80} src={null} alt={'Profil Ekle'} radius={80}>
                                        <IoPersonAdd size={26}/>
                                    </Avatar>
                                    <Text>Profil Ekle</Text>
                                </div>
                            }

                        </Group>
                    </Center>


                </div>
            </div>

        </div>


    </>)

}
const mapProps = (state) => ({
user: state.user.info
});


export default connect(mapProps)(SelectProfile);
