import {Provider} from 'react-redux';
import {Navigate, Route, Routes, useLocation, useNavigate,Switch} from 'react-router-dom';
import {ModalsProvider} from '@mantine/modals';
import {store} from "./store";
import React, {useEffect, useState} from "react";
import Content from "./lib/components/Content";
// pages
import Home from "./app/home";
import Login from "./app/user/login";
import PrivateRoute from "./lib/util/PrivateRoute";
import {notifications, Notifications} from "@mantine/notifications";
import {ColorSchemeProvider, MantineProvider} from "@mantine/core";
import Register from "./app/user/register";
import Forgot from "./app/user/forgot";
import ProfileSelect from "./app/user/profile";
import ProfileSettings from "./app/user/profile/settings";
import PanelPage from "./app/panel";
import ProfileDetail from "./app/user/profile/detail";
import Connect from "./lib/util/Connect";
import {ErrorMessage} from "./lib/util/ErrorMessage";
import {getSession} from "./lib/util/auth";
import {set_notice, set_upgrade, set_user} from "./data/actions/user";
import AnalysisPage from "./app/analysis";
import {HomeProfile} from "./app/home/profile";
import NoticePage from "./app/notice";
import NewProfile from "./app/home/new";
import MemberShipPage from "./app/membership";
import PaymentResult from "./app/payment/result";
import Packages from "./app/package";
import {HomeSelectProfile} from "./app/home/select";
import ResetPassword from "./app/user/forgot/reset";
import ErrorPage from "./app/404";




function App() {

    const [userInfo, setUserInfo] = useState({});
    const [notification,setNotification] = useState([]);
    const [colorScheme, setColorScheme] = useState('light');
    const [upgradeAccount,setUpgradeAccount] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const toggleColorScheme = (value) =>
        setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));


    const getUserInfo = () => {

        Connect().get('member/info').then(({data}) => {


            let info = data.result;
            info.ugprade_account = data.ugprade_account;

            setUserInfo(info);
            set_user(info);

            setUpgradeAccount(data.ugprade_account)

            if (data.ugprade_account){
                navigate('/packages');
            }



        }).catch((e) => {
            notifications.show({
                title: 'Hata',
                withCloseButton:false,
                message: ErrorMessage(e),
                color: 'red'
            });
        });

    }

    const getNotification = () => {

        Connect().get(`notification/list/10`).then(({data}) =>{

            setNotification(data.result);
            set_notice(data.result);

        }).catch((e) => {
            notifications.show({
                title: 'Hata',
                withCloseButton:false,
                message: ErrorMessage(e),
                color: 'red'
            });
        })

    }

    useEffect(() => {



    },[]);

    useEffect(() => {

        let token = getSession();


        if (token !== undefined){
            getUserInfo();
            getNotification();
        }

    }, []);




    useEffect(() => {

        if (location.pathname.startsWith('/#')) {
            const newPath = location.pathname.replace('/#', '');

            window.history.replaceState(null, null, newPath);
        }
    }, [location]);


    return (

        <Provider store={store}>
            <MantineProvider withGlobalStyles withNormalizeCSS>
                <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>

                    <Notifications/>
                    <ModalsProvider>

                        <Content user={userInfo} notification={notification}>
                            <Routes>
                                <Route path='*' element={<ErrorPage/>}/>
                                    <Route path={"/login"} element={<Login/>}/>
                                    <Route path={"/register"} element={<Register/>}/>
                                    <Route path={"/forgot-password"} element={<Forgot/>}/>
                                    <Route path={"/reset/password/:id"} element={<ResetPassword/>}/>
                                    <Route path={"/profile/:id"} element={<HomeProfile/>}/>
                                    <Route path={"/id/:id"} element={<HomeSelectProfile/>}/>
                                    <Route path={"/id/:id/:uid"} element={<Home/>}/>
                                    <Route path={"/welcome/:id"} element={<NewProfile/>}/>
                                    <Route path={"/payment/result"} element={<PaymentResult/>}/>
                                    <Route path={"/packages"} element={<Packages/>}/>


                                <Route element={<PrivateRoute upgradeAccount={upgradeAccount}/>}>
                                    <Route path={"/notice/:id"} element={<NoticePage/>}/>
                                    <Route path={"/analysis/:id"} element={<AnalysisPage/>}/>
                                    <Route path={"/subscription/:id"} element={<MemberShipPage/>}/>
                                    <Route path={"/panel/:id"} element={<PanelPage/>}/>
                                    <Route path={"/view/:id"} element={<ProfileDetail/>}/>
                                    <Route path={"/user-settings"} element={<ProfileSettings/>}/>
                                    <Route path={"/select-profile"} element={<ProfileSelect/>}/>
                                    <Route index path={"/"} element={<Navigate to="/select-profile"/>}/>
                                </Route>
                            </Routes>
                        </Content>
                    </ModalsProvider>
                </ColorSchemeProvider>

            </MantineProvider>
        </Provider>


    );
}

export default App;
