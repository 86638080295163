import {useState} from "react";
import _ from "lodash";
import {
    IoEarth, IoLogoBehance, IoLogoDiscord, IoLogoDribbble,
    IoLogoFacebook, IoLogoGithub,
    IoLogoInstagram,
    IoLogoLinkedin, IoLogoMedium, IoLogoPinterest, IoLogoReddit, IoLogoSnapchat, IoLogoTwitch,
    IoLogoTwitter, IoLogoVimeo, IoLogoWechat,
    IoLogoWhatsapp,
    IoLogoYoutube
} from "react-icons/io5";
import {FaDailymotion, FaPeriscope, FaTelegramPlane} from "react-icons/fa";
import {ImEllo, ImHangouts} from "react-icons/im";
import {SiSignal, SiSwarm} from "react-icons/si";


const social = [
    {
        id:1,
        slug:'website',
        title:'Web Site',

    },

    {
        id:2,
        slug:'whatsapp',
        title:'Whatsapp',

    },


    {
        id:3,
        slug:'instagram',
        title:'Instagram',

    },

    {
        id:4,
        slug:'facebook',
        title:'Facebook',

    },

    {
        id:5,
        slug:'twitter',
        title:'Twitter',

    },

    {
        id:6,
        slug:'linkedin',
        title:'Linkedin',

    },

    {
        id:7,
        slug:'youtube',
        title:'Youtube',

    },

    {
        id:8,
        slug:'zoom',
        title:'Zoom',

    },
    {
        id:9,
        slug:'telegram',
        title:'Telegram',

    },

    {
        id:10,
        slug:'bip',
        title:'Bip',

    },

    {
        id:11,
        slug:'discord',
        title:'Discord',

    },

    {
        id:12,
        slug:'dribble',
        title:'Dribble',

    },

    {
        id:13,
        slug:'vimeo',
        title:'Vimeo',

    },

    {
        id:14,
        slug:'ello',
        title:'Ello',

    },

    {
        id:15,
        slug:'github',
        title:'Github',

    },
    {
        id:16,
        slug:'hangouts',
        title:'Hangoust',

    },


    {
        id:17,
        slug:'icbc',
        title:'Icbc',

    },

    {
        id:18,
        slug:'icq',
        title:'Icq',
    },

    {
        id:19,
        slug:'kikmessenger',
        title:'Kikmessenger',
    },

    {
        id:20,
        slug:'twitch',
        title:'Twitch',
    },


    {
        id:21,
        slug:'medium',
        title:'Medium',
    },


    {
        id:22,
        slug:'nimotv',
        title:'NimoTv',
    },


    {
        id:23,
        slug:'periscope',
        title:'Periscope',
    },

    {
        id:24,
        slug:'dailymotion',
        title:'Dailymotion',

    },


    {
        id:25,
        slug:'wechat',
        title:'WeChat',

    },

    {
        id:26,
        slug:'qqtile',
        title:'Qqtile',

    },

    {
        id:27,
        slug:'sineweibo',
        title:'Sineweibo',

    },

    {
        id:28,
        slug:'dlive',
        title:'Dlive',

    },

    {
        id:29,
        slug:'pinterest',
        title:'Pinterest',

    },

    {
        id:30,
        slug:'reddit',
        title:'Reddit',

    },


    {
        id:31,
        slug:'behance',
        title:'Behance',

    },

    {
        id:32,
        slug:'swarm',
        title:'Swarm',

    },

    {
        id:33,
        slug:'signal',
        title:'Signal',

    },

    {
        id:34,
        slug:'yaya',
        title:'Yaya',

    },


    {
        id:35,
        slug:'c2',
        title:'C2',

    },


    {
        id:36,
        slug:'tango',
        title:'Tango',

    },

    {
        id:37,
        slug:'vero',
        title:'Vero',

    },

    {
        id:38,
        slug:'viber',
        title:'Viber',

    },


    {
        id:39,
        slug:'teams',
        title:'Teams',

    },

    {
        id:40,
        slug:'snapchat',
        title:'Snapchat',
    },

];

export const SocialTitle = (type = '') => {



    return _.find(social, {
        slug: type
    }).title;


}

