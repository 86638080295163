import { Button, rem, Tooltip } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import { IconCopy, IconCheck } from '@tabler/icons-react';

export function ButtonCopy({url,uuid}) {
    const clipboard = useClipboard();



    return (
        <Tooltip
            label="Link kopyalandı!"
            offset={5}
            position="bottom"
            radius="xl"
            transitionProps={{ duration: 100, transition: 'slide-down' }}
            opened={clipboard.copied}
        >
            <Button
                variant="light"
                rightIcon={
                    clipboard.copied ? (
                        <IconCheck size="1.2rem" stroke={1.5} />
                    ) : (
                        <IconCopy size="1.2rem" stroke={1.5} />
                    )
                }
                radius="xl"
                size="sm"
                styles={{
                    root: { paddingRight: rem(14) },
                    rightIcon: { marginLeft: rem(22) },
                }}
                onClick={() => clipboard.copy(url)}
            >
                {uuid}
            </Button>
        </Tooltip>
    );
}
