import {Image, ThemeIcon, UnstyledButton} from "@mantine/core";
import {
    IoEarth,
    IoLogoDiscord,
    IoLogoDribbble,
    IoLogoFacebook,
    IoLogoInstagram,
    IoLogoLinkedin,
    IoLogoTwitter,
    IoLogoYoutube,
    IoLogoWhatsapp,
    IoLogoVimeo,
    IoLogoGithub,
    IoLogoTwitch,
    IoLogoMedium,
    IoLogoWechat,
    IoLogoPinterest,
    IoLogoReddit,
    IoLogoBehance, IoLogoSnapchat
} from "react-icons/io5";
import {ImEllo,ImHangouts} from "react-icons/im"
import {FaCommentAlt, FaDailymotion, FaPeriscope, FaTelegramPlane, FaViber} from "react-icons/fa";
import {BiMessage} from "react-icons/bi";

import {SiSwarm, SiSignal, SiZoom} from "react-icons/si";
import {useEffect, useState} from "react";
import _ from "lodash";




export const SocialList = ({search = '', onClick}) => {


    const [social,setSocial] = useState([
        {
            id:1,
            slug:'website',
            title:'Web Site',
            link:'http://',
            icon:<IoEarth/>
        },

        {
            id:2,
            slug:'whatsapp',
            title:'Whatsapp',
            link:'http://wa.me/+',
            icon:<IoLogoWhatsapp/>
        },


        {
            id:3,
            slug:'instagram',
            title:'Instagram',
            link:'https://instagram.com/',
            icon:<IoLogoInstagram/>
        },

        {
            id:4,
            slug:'facebook',
            title:'Facebook',
            link:'https://facebook.com/',
            icon:<IoLogoFacebook/>
        },

        {
            id:5,
            slug:'twitter',
            title:'Twitter',
            link:'https://twitter.com/',
            icon:<IoLogoTwitter/>
        },

        {
            id:6,
            slug:'linkedin',
            title:'Linkedin',
            link:'https://linkedin.com/in/',
            icon:<IoLogoLinkedin/>
        },

        {
            id:7,
            slug:'youtube',
            title:'Youtube',
            link:'https://youtube.com/',
            icon:<IoLogoYoutube/>
        },

        {
            id:8,
            slug:'zoom',
            title:'Zoom',
            link:'https://zoom.com/',
            icon:<SiZoom className={"zoom"}/>
        },
        {
            id:9,
            slug:'telegram',
            title:'Telegram',
            link:'https://telegram.com/',
            icon:<FaTelegramPlane/>
        },

        {
            id:10,
            slug:'bip',
            title:'Bip',
            link:'https://bip.com/',
            icon:<Image className={"gray-icon"} alt={'bip'} src={'/img/icon/bip.svg'} width={19} height={19}/>
        },

        {
            id:11,
            slug:'discord',
            title:'Discord',
            link:'https://discord.com/',
            icon:<IoLogoDiscord/>
        },

        {
            id:12,
            slug:'dribble',
            title:'Dribble',
            link:'https://dribble.com/',
            icon:<IoLogoDribbble/>
        },

        {
            id:13,
            slug:'vimeo',
            title:'Vimeo',
            link:'https://vimeo.com/',
            icon:<IoLogoVimeo/>
        },

        {
            id:14,
            slug:'ello',
            title:'Ello',
            link:'https://ello.com/',
            icon:<ImEllo/>
        },

        {
            id:15,
            slug:'github',
            title:'Github',
            link:'https://github.com/',
            icon:<IoLogoGithub/>
        },
        {
            id:16,
            slug:'hangouts',
            title:'Hangoust',
            link:'https://hangouts.com/',
            icon:<ImHangouts/>
        },


        {
            id:17,
            slug:'icbc',
            title:'Icbc',
            link:'https://icbc.com/',
            icon:'Icbc'
        },

        {
            id:18,
            slug:'icq',
            title:'Icq',
            link:'https://icq.com/',
            icon:'Icq'
        },

        {
            id:19,
            slug:'kikmessenger',
            title:'Kikmessenger',
            link:'https://kikmessenger.com/',
            icon:'Kik'
        },

        {
            id:20,
            slug:'twitch',
            title:'Twitch',
            link:'https://twitch.com/',
            icon:<IoLogoTwitch/>
        },


        {
            id:21,
            slug:'medium',
            title:'Medium',
            link:'https://medium.com/',
            icon:<IoLogoMedium/>
        },


        {
            id:22,
            slug:'nimotv',
            title:'NimoTv',
            link:'https://nimotv.com/',
            icon:'NmTv'
        },


        {
            id:23,
            slug:'periscope',
            title:'Periscope',
            link:'https://periscope.com/',
            icon:<FaPeriscope/>
        },

        {
            id:24,
            slug:'dailymotion',
            title:'Dailymotion',
            link:'https://dailymotion.com/',
            icon:<FaDailymotion/>
        },


        {
            id:25,
            slug:'wechat',
            title:'WeChat',
            link:'https://wechat.com/',
            icon:<IoLogoWechat/>
        },

        {
            id:26,
            slug:'qqtile',
            title:'Qqtile',
            link:'https://qqtile.com/',
            icon:''
        },

        {
            id:27,
            slug:'sineweibo',
            title:'Sineweibo',
            link:'https://sineweibo.com/',
            icon:''
        },

        {
            id:28,
            slug:'dlive',
            title:'Dlive',
            link:'https://dlive.com/',
            icon:''
        },

        {
            id:29,
            slug:'pinterest',
            title:'Pinterest',
            link:'https://pinterest.com/',
            icon:<IoLogoPinterest/>
        },

        {
            id:30,
            slug:'reddit',
            title:'Reddit',
            link:'https://reddit.com/',
            icon:<IoLogoReddit/>
        },


        {
            id:31,
            slug:'behance',
            title:'Behance',
            link:'https://behance.com/',
            icon:<IoLogoBehance/>
        },

        {
            id:32,
            slug:'swarm',
            title:'Swarm',
            link:'https://swarm.com/',
            icon:<SiSwarm/>
        },

        {
            id:33,
            slug:'signal',
            title:'Signal',
            link:'https://signal.com/',
            icon:<SiSignal/>
        },

        {
            id:34,
            slug:'yaya',
            title:'Yaya',
            link:'https://yaya.com/',
            icon:''
        },


        {
            id:35,
            slug:'c2',
            title:'C2',
            link:'https://c2.com/',
            icon:<FaCommentAlt/>
        },


        {
            id:36,
            slug:'tango',
            title:'Tango',
            link:'https://tango.com/',
            icon:''
        },

        {
            id:37,
            slug:'vero',
            title:'Vero',
            link:'https://vero.com/',
            icon:''
        },

        {
            id:38,
            slug:'viber',
            title:'Viber',
            link:'https://viber.com/',
            icon:<FaViber/>
        },


        {
            id:39,
            slug:'teams',
            title:'Teams',
            link:'https://teams.com/',
            icon:''
        },

        {
            id:40,
            slug:'snapchat',
            title:'Snapchat',
            link:'https://snapchat.com/',
            icon:<IoLogoSnapchat/>
        },

    ]);
    const [searchData,setSearchData] = useState([]);


    useEffect(()=>{

        if (search !== ''){


            let data = [...social];

            let filteredData = _.filter(data, item => _.includes(item.title.toLowerCase(), search.toLowerCase()));

            setSearchData(filteredData);


        } else{
            setSearchData(social)
        }

    },[search])


    const socialItem = (item) => {
        return(<UnstyledButton onClick={() => onClick(item)}  className={"social-item"} mb={20} fullWidth color={"indigo"} variant={"light"} radius={"md"}>
            <ThemeIcon color={"indigo"} variant={"light"}>
                {item.icon}
            </ThemeIcon>
            {item.title}
        </UnstyledButton>)
    }


   return(<div>
       { searchData.map((item) => (socialItem(item)))}
   </div>)

}

export default SocialList;
