import {Button, Center, Group, Text, TextInput} from "@mantine/core";
import {useListState} from "@mantine/hooks";
import {IoAddSharp, IoTrash} from "react-icons/io5";
import React, {useEffect, useState} from "react";
import {SocialLink} from "../../util/socialLink";
import Connect from "../../util/Connect";
import {ErrorViewing} from "../../util/ErrorViewing";
import {modals} from "@mantine/modals";
import {SuccessViewing} from "../../util/SuccessViewing";


export const LinkForm = ({info, extraData = null, onOpen,onResult,onItem}) => {


    const [form, setForm] = useListState([]);
    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    const [blankData, setBlankData] = useState([]);

    useEffect(() => {


        if (info.MP_URL_Link.length) {

            setForm.setState([]);

            info.MP_URL_Link.map((item, index) => setForm.append({
                param_type: item.param_type,
                param: item.param,
                link: SocialLink(item.param_type),
            }));

        }

    }, []);


    useEffect(() => {



        if (extraData !== null) {
            setForm.append({
                param_type: extraData.slug,
                param: '',
                link: extraData.link,
            });
        }

    }, [extraData]);


    const addLinks = () => {

        let _data = [];

        form.map((item) => _data.push({
            param: item.param,
            param_type: item.param_type,
        }));

        setLoading(true);

        Connect().post(`panel/item/URL_Link/${info.id}/edit/all`,_data).then(({data}) =>{




            SuccessViewing('Güncelleme Tamamlandı',modals);

            onResult();


        }).catch((e) => ErrorViewing(e,modals)).finally(() =>  setLoading(false));

    }



    useEffect(()=>{


        setBlankData([]);

        let data = [];


        form.map((item,index) => {
            data.push({
                id: null,
                member_panel_id: 894,
                is_deleted: false,
                status: true,
                created_at: "2023-06-28T16:01:41.669Z",
                updated_at: "2023-06-28T16:01:41.669Z",
                param: item.param,
                param_type: item.param_type,
                order_num: index
            });
        });



        setBlankData(data);
        onItem(data, true);


    },[form]);



    return (
        <form>


            {form.map((item, index) => (
                <div className={"link-area"}>
                    <div className="t-item">
                        <div className={"form-area-input"} key={`form-link-${index}`}>
                            <Text className={"link-type"}>{item.link}</Text>
                            <div className={"input-area"}>
                                <TextInput
                                    value={form[index].param}
                                    onChange={(event) => setForm.setItemProp(index, 'param', event.currentTarget.value)}/>
                            </div>
                        </div>
                    </div>

                    <div className={"link-delete"} style={{cursor: 'pointer'}} onClick={() => {
                        setForm.remove(index);
                    }}>
                        <Center style={{height: '100%'}}>
                            <IoTrash color={"#ec3131"}/>
                        </Center>
                    </div>
                </div>
            ))}


            <Group grow className={"mobile-btn-full"}>
                <Button onClick={onOpen} color={'red'} mt={5} style={{height: 43}} leftIcon={<IoAddSharp size={17}/>}>Link
                    Ekle</Button>
                <Button onClick={() =>{
                    if (form.length){
                        addLinks();
                    }
                }}  loading={loading} color={"violet"} fullWidth style={{height: 43}} mt={5}>Güncellemeyi
                    Kaydet</Button>
            </Group>
        </form>)

}


export default LinkForm;
