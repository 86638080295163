import {Button, FileButton, Grid, Group, UnstyledButton} from "@mantine/core";
import {IoAddCircleOutline, IoDownload, IoTrash, IoTrashOutline} from "react-icons/io5";
import {useEffect, useState} from "react";
import {useListState} from "@mantine/hooks";
import Connect from "../../util/Connect";
import ConnectFile from "../../util/ConnectFile";
import {notifications} from "@mantine/notifications";
import {ErrorMessage} from "../../util/ErrorMessage";
import {QuestionModal} from "../../util/QuestionModal";
import {modals} from "@mantine/modals";


export const DocumentForm = ({info, onResult}) => {

    const [files, setFiles] = useListState([]);
    const [indexId,setIndexId] = useState("");


    useEffect(() => {



        if (info.MP_Document.length){


            setFiles.setState([]);


            info.MP_Document.map((item,index) =>{
                setFiles.append({id:item.id,file:{name:item.file_meta_name}})
            });

            setFiles.append({id:null, file:null});

        } else{

            setFiles.setState([{id:null, file:null}])
        }


    }, []);


    const setPanel = (index) => {

        setIndexId(index);


        let formData =  new FormData();



       formData.append('file',files[index].file)

        ConnectFile().post(`panel/item/Document/${info.id}/add`,formData).then(({data}) =>{

            setIndexId('');



            notifications.show({
                title: 'Başarılı',
                withCloseButton:false,
                message: 'Dosya Yüklendi',
                color: 'teal'
            });

            setFiles.setItemProp(index,'id',data.result.id);

            setFiles.append({id:null, file:null});


            onResult();

        }).catch((e) => {
            setIndexId('null');

            notifications.show({
                title: 'Hata',
                withCloseButton:false,
                message: ErrorMessage(e),
                color: 'red'
            });

        });

    }


    const removePanel = (index,id) => {


        Connect().get(`panel/item/Document/delete/${id}`).then(({data}) =>{

            notifications.show({
                title: 'Başarılı',
                withCloseButton:false,
                message: 'Dosya Silindi',
                color: 'teal'
            });

            setFiles.remove(index);

            onResult();

        }).catch((e) =>{
            notifications.show({
                title: 'Hata',
                withCloseButton:false,
                message: ErrorMessage(e),
                color: 'red'
            });
        })


    }


    const fileSize = (size)  => {

        let maxfilesize = 4 * 1024 * 1024;


        return size <= maxfilesize;



    }




    return (
        <div>
            <Grid gutter={25}>


                        {files.map((item, index) => {
                                return(
                                    <Grid.Col lg={12} key={`file-${index}`}>
                                        <div  className={item.id === null ? "mobile-btn-full-wh" : "test"} style={{width:item.id === null ? 'calc(100% - 140px)' : 'calc(100% - 40px)',  float:'left'}}>
                                            <FileButton leftIcon={<IoAddCircleOutline/>} color={item.id === null ? "teal" : "gray"} fullWidth
                                                        style={{height: 43}}
                                                        onChange={(val) => {
                                                            if (fileSize(val.size)){
                                                                setFiles.setItemProp(index, 'file', val);
                                                            } else {
                                                                notifications.show({
                                                                    title: 'Hata',
                                                                    withCloseButton:false,
                                                                    message: "Dosya Boyutu 4MB'dan fazla",
                                                                    color: 'red'
                                                                });
                                                            }

                                                        }}
                                                        accept="image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document">
                                                {(props) =>
                                                    <Button className={item.id !== null ?  'disable-btn' : ''} {...props}>{item.file !== null ? item.file.name : 'Dosya Seç (Max. 4MB)'}</Button>}
                                            </FileButton>
                                        </div>

                                        {item.id === null &&
                                            <div className={"mobile-btn-full-wh"} style={{float:'left', marginLeft:15, width:100}}>
                                                <Button disabled={item.file === null} loading={indexId === index} onClick={() => setPanel(index)} leftIcon={<IoDownload/>} style={{height:43}} color={"violet"}>Dosyayı Yükle</Button>
                                            </div>
                                        }
                                        {item.id !== null &&
                                            <div  style={{width:40,float:'right',textAlign:'right'}}>
                                                <UnstyledButton style={{height:47}} onClick={() => {
                                                    QuestionModal({
                                                        modals,
                                                        title: 'Dosyayı Sil',
                                                        text: 'Dosyayı silmek',
                                                        confirm: () => removePanel(index,item.id)
                                                    });
                                                }}>
                                                    <IoTrashOutline size={17}/>
                                                </UnstyledButton>
                                            </div>
                                        }
                                    </Grid.Col>
                                )

                        })}





            </Grid>
        </div>)

}


export default DocumentForm;
