import {
    Alert,
    Avatar,
    Box,
    Button,
    Card,
    Center,
    Checkbox,
    Container,
    CopyButton,
    createStyles,
    FileButton,
    Grid,
    Group,
    Indicator,
    List,
    Loader,
    LoadingOverlay,
    Modal,
    Radio,
    rem,
    SegmentedControl,
    Stepper,
    Switch,
    Text,
    Textarea,
    TextInput,
    ThemeIcon,
    Title,
    UnstyledButton,
    useMantineColorScheme
} from "@mantine/core";
import { useDisclosure, useDocumentTitle, useListState } from "@mantine/hooks";
import Navbar from "../../../lib/components/Nav";
import React, { useEffect, useState } from "react";
import Connect from "../../../lib/util/Connect";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IoCheckmarkDone, IoCopyOutline, IoEyeOutline, IoMoonOutline, IoSunnyOutline } from "react-icons/io5";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { RxDragHandleDots2 } from "react-icons/rx";
import { HiOutlinePencilSquare } from "react-icons/hi2";
import { modals } from "@mantine/modals";
import { useForm } from "@mantine/form";
import { QuestionModal } from "../../../lib/util/QuestionModal";
import { notifications } from "@mantine/notifications";
import { ErrorMessage } from "../../../lib/util/ErrorMessage";
import { connect } from "react-redux";
import ConnectFile from "../../../lib/util/ConnectFile";
import { SocialLink } from "../../../lib/util/socialLink";
import { SocialIcon } from "../../../lib/util/socialcon";
import { Base64 } from 'js-base64';
import _ from "lodash";
import { setPreview } from "../../../lib/util/auth";
import { PackageInfo } from "../../../lib/components/Nav/PackageInfo";
import { AiOutlineBank, AiOutlineCreditCard } from "react-icons/ai";
import PhoneInput from "react-phone-input-2";


const useStyles = createStyles((theme) => ({
    card: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    },

    avatar: {
        border: `${rem(2)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white}`,
    },


    item: {
        '& + &': {
            paddingTop: theme.spacing.sm,
            marginTop: theme.spacing.sm,
            borderTop: `${rem(1)} solid ${
                theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
            }`,
        },
    },

    switch: {
        '& *': {
            cursor: 'pointer',
        },
    },

    title: {
        lineHeight: 1,
    },

}));


export const ProfileDetail = ({user}) => {

    useDocumentTitle('Profil Detay');


    const [pageLoading, setPageLoading] = useState(true);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [editType, setEditType] = useState('');
    const [info, setInfo] = useState({});
    const [baseInfo, setBaseInfo] = useState({});
    const [opened, {open, close}] = useDisclosure(false);
    const [socialData, handlers] = useListState([]);
    const [userInfo, setUserInfo] = useState({});
    const [extraTimePacket, setExtraTimePacket] = useState({
        premium: [],
        standart: [],
        test: []
    });
    const [transitionPacket, setTransitionPacket] = useState({
        premium: [],
        standart: [],
    });
    const [packetType, setPacketType] = useState('standart');
    const [packetId, setPacketId] = useState('');
    const [profileFile, setProfileFile] = useState(null);
    const [profileCoverFile, setProfileCoverFile] = useState(null);
    const [orderLoading, setOrderLoading] = useState(false);
    const [paymentLoad, setPaymentLoad] = useState(true);
    const [active, setActive] = useState(0);
    const [paymentType, setPaymentType] = useState('');
    const [paymentUrl, setPaymentUrl] = useState('');
    const [creditType, setCreditType] = useState(false);
    const [creditLoad, setCreditLoad] = useState(false);
    const [transitionId,setTransitionId] = useState('');
    const [packetGroupType, setPacketGroupType] = useState('ExtraTime');

    const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));
    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));


    const {classes, theme} = useStyles();
    const {colorScheme, toggleColorScheme} = useMantineColorScheme();

    const navigate = useNavigate();
    const {id} = useParams();


    const form = useForm({
        initialValues: {
            profile_tag: '',
            profile_firstname: '',
            profile_lastname: '',
            profile_title: '',
            profile_content: '',
            profile_business_name: '',
            ok_content:false
        },

        validate: {
            profile_tag: (value) => (value !== '' ? null : 'Profil Etiketi boş girilemez'),
        },
    });


    const creditForm = useForm({
        initialValues: {
            phone: '+90',
            address: '',

        },

        validate: {
            phone: (value) => (value !== '' ? null : 'Telefon Numarası boş girilemez'),
            address: (value) => (value !== '' ? null : 'Adres boş girilemez'),
        },
    });


    useEffect(() => {


        getProfile();



    }, []);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://www.paytr.com/js/iframeResizer.min.js';
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);


    useEffect(() => {

        if (user !== null) {
            getPrices();
            setUserInfo(user);
            if (user.phone_code !== null && user.phone_code !== ''){
                creditForm.setFieldValue('phone',user.phone_code);
            }
        }

    }, [user]);


    useEffect(()=>{

        if (!pageLoading){

            window.addEventListener('message', function (event) {


                const event_data = event.data;


                if(typeof event_data === 'string') {

                    try {
                        const data = JSON.parse(event_data);
               //         console.log('data',data);
                        if(data?.result) {

                            if(data.result === 'success') {

                                console.log('success');

                            } else {
                                console.log('hatata');
                            }


                        } else {
                            // başarısız modal çıkar
                        }
                    }catch (e){

                    }
                }


            });

        }

    },[pageLoading]);


    const getProfile = () => {

        setOrderLoading(false);

        Connect().get(`profile/get/${id}`).then(({data}) => {


            let result = data.result;


            //   setProfileLink(Base64.encode(JSON.stringify(result)));


            setPreview(Base64.encode(JSON.stringify(result)));


            setInfo(result);
            setBaseInfo(result);

            handlers.setState(result.url_links);


            form.setValues({
                profile_tag: result.profile_tag,
                profile_firstname: result.profile_firstname,
                profile_lastname: result.profile_lastname,
                profile_title: result.profile_title,
                profile_content: result.profile_content,
                profile_business_name: result.profile_business_name,
                ok_content: result.ok_content,
            });

            setProfileFile(null);
            setProfileCoverFile(null);

            setTimeout(() => {

                setPageLoading(false);

            }, 150);

        }).catch((e) => {
            notifications.show({
                title: 'Hata',
                withCloseButton: false,
                message: ErrorMessage(e),
                color: 'red'
            });
        }).finally(() => {
            setTimeout(() => {
                setOrderLoading(true);
            }, 150);
        });

    }

    const getPrices = () => {

        Connect().get('general/packages').then(({data}) => {


            let _data = data.result;

            let _extraPackets = [];
            let _transitionPackets = [];

            const groupedData = _.groupBy(_data, 'package_type');


            let ExtraPacketGroup = _.groupBy(groupedData['ExtraTime'], 'member_type');


            if (ExtraPacketGroup.Test !== undefined) {
                _extraPackets['test'] = ExtraPacketGroup.Test;
            }

            if (ExtraPacketGroup.Standart !== undefined) {
                _extraPackets['standart'] = ExtraPacketGroup.Standart;
            }
            if (ExtraPacketGroup.Premium !== undefined) {
                _extraPackets['premium'] = ExtraPacketGroup.Premium;
            }

            let filterTransition =   _.filter(groupedData['Transition'], { member_type: user?.member_type });

            let TransitionPacketGroup = _.groupBy(filterTransition, 'transit_member_type');



            if (TransitionPacketGroup.Standart !== undefined) {
                _transitionPackets['standart'] = TransitionPacketGroup.Standart;
            }
            if (TransitionPacketGroup.Premium !== undefined) {
                _transitionPackets['premium'] = TransitionPacketGroup.Premium;
            }


            setTransitionPacket(_transitionPackets);
            setExtraTimePacket(_extraPackets);



        }).catch((e) => {

            notifications.show({
                title: 'Hata',
                withCloseButton: false,
                message: ErrorMessage(e),
                color: 'red'
            });
        });

    }


    const updateProfile = (values, load = false) => {


        let _baseInfo = {...baseInfo};

        _baseInfo[Object.keys(values)] = Object.values(values);

        setBaseInfo(_baseInfo);

        // setProfileLink(Base64.encode(JSON.stringify(_baseInfo)));


        setPreview(Base64.encode(JSON.stringify(_baseInfo)));


        setLoading(load);

        Connect().post(`profile/edit/${id}`, values).then(({data}) => {


            if (values.is_deleted) {

                navigate('/select-profile');

            } else {


                getProfile();

                notifications.show({
                    title: 'Başarılı',
                    withCloseButton: false,
                    message: 'Güncelleme Başarılı',
                    color: 'teal'
                });

            }


        }).catch((e) => {
            notifications.show({
                title: 'Hata',
                withCloseButton: false,
                message: ErrorMessage(e),
                color: 'red'
            });
        }).finally(() => setLoading(false));

    }

    const removeProfile = () => {

        Connect().get(`profile/deleted/${id}`).then(({data}) => {

            navigate('/select-profile');


            notifications.show({
                title: 'Başarılı',
                withCloseButton: false,
                message: 'Profil Silindi',
                color: 'teal'
            });

        }).catch((e) => {
            notifications.show({
                title: 'Hata',
                withCloseButton: false,
                message: ErrorMessage(e),
                color: 'red'
            });
        }).finally(() => setDeleteLoading(true))

    }

    const editProfileImage = () => {

        let formData = new FormData();

        formData.append('img', profileFile);

        ConnectFile().post(`profile/edit/pp/${id}`, formData).then(({data}) => {

            getProfile();

            notifications.show({
                title: 'Başarılı',
                withCloseButton: false,
                message: 'Güncelleme Başarılı',
                color: 'teal'
            });


        }).catch((e) => {
            notifications.show({
                title: 'Hata',
                withCloseButton: false,
                message: ErrorMessage(e),
                color: 'red'
            });
            setProfileFile(null);
        })

    }

    const editProfileCoverImage = () => {

        let formData = new FormData();

        formData.append('img', profileCoverFile);

        ConnectFile().post(`profile/edit/cover/${id}`, formData).then(({data}) => {

            getProfile();

            notifications.show({
                title: 'Başarılı',
                withCloseButton: false,
                message: 'Güncelleme Başarılı',
                color: 'teal'
            });


        }).catch((e) => {
            notifications.show({
                title: 'Hata',
                withCloseButton: false,
                message: ErrorMessage(e),
                color: 'red'
            });
            setProfileCoverFile(null);
        })

    }


    useEffect(() => {

        if (profileFile !== null) {
            editProfileImage();
        }

    }, [profileFile]);


    useEffect(() => {

        if (profileCoverFile !== null) {
            editProfileCoverImage();
        }

    }, [profileCoverFile]);


    const editStatus = (id = '', data = {}) => {


        let _baseInfo = {...baseInfo};


        _.find(_baseInfo.url_links, {
            id: id
        }).status = data.status;


        setBaseInfo(_baseInfo);
        //    setProfileLink(Base64.encode(JSON.stringify(_baseInfo)));

        setPreview(Base64.encode(JSON.stringify(_baseInfo)))


        Connect().post(`panel/item/URL_Link/edit/${id}`, data).then(({data}) => {


            notifications.show({
                title: 'Başarılı',
                withCloseButton: false,
                message: 'Durum Güncellendi',
                color: 'teal'
            });

        }).catch((e) => {
            notifications.show({
                title: 'Hata',
                withCloseButton: false,
                message: ErrorMessage(e),
                color: 'red'
            });
        });

    }


    const items = socialData.map((item, index) => (
        <Draggable key={String(item.id)} index={index} draggableId={String(item.id)}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                >
                    <Grid>
                        <Grid.Col pl={5} mt={12} lg={10}>
                            <div style={{float: 'left', paddingTop: 1}} {...provided.dragHandleProps}>
                                <RxDragHandleDots2 size={20}/>
                            </div>
                            <div style={{float: 'left', width: 'calc(100% - 30px)', paddingLeft: 10}}>
                                <div className={"item-zoom"} style={{float: 'left', marginTop: -3}}>
                                    <ThemeIcon radius={"xl"} color={"dark"} size={30}
                                               style={{fontSize: 12, fontWeight: 500}}>
                                        {SocialIcon(item.param_type)}
                                    </ThemeIcon>
                                </div>
                                <div style={{width: 'calc(100% - 35px', float: 'left', paddingLeft: 12}}>
                                    <Text color={"dimmed"} size={"sm"}>
                                        {SocialLink(item.param_type)}{item.param}
                                    </Text>
                                </div>
                            </div>
                        </Grid.Col>
                        <Grid.Col lg={2}>
                            <Group position={'right'} mt={8}>
                                <Switch className={classes.switch} color={"teal"}
                                        onChange={(event) => editStatus(item.id, {
                                            status: event.currentTarget.checked
                                        })} defaultChecked={item.status} size={"lg"}/>
                            </Group>
                        </Grid.Col>
                    </Grid>

                </div>
            )}
        </Draggable>
    ));


    const setOrder = () => {

        let data = [];

        socialData.map((item, index) => data.push({id: item.id, index}));

        let _baseInfo = {...baseInfo};


        _baseInfo.url_links = socialData;

        setBaseInfo(_baseInfo);
        //setProfileLink(Base64.encode(JSON.stringify(_baseInfo)));

        setPreview(Base64.encode(JSON.stringify(_baseInfo)))


        Connect().post('profile/social/order', {
            values: data
        }).then(({data}) => {

            notifications.show({
                title: 'Başarılı',
                withCloseButton: false,
                message: 'Sıralama Güncellendi',
                color: 'teal'
            });

        }).catch((e) => {

            notifications.show({
                title: 'Hata',
                withCloseButton: false,
                message: ErrorMessage(e),
                color: 'red'
            });

        })

    }

    useEffect(() => {

        if (orderLoading) {
            setOrder();
        }


    }, [socialData]);


    useEffect(() => {


        if (!pageLoading) {
            updateItem(form.values)
        }


    }, [form.values]);


    const updateItem = (values) => {

        let _baseInfo = {...baseInfo};


        _baseInfo['profile_business_name'] = values.profile_business_name;
        _baseInfo['profile_content'] = values.profile_content;
        _baseInfo['profile_firstname'] = values.profile_firstname;
        _baseInfo['profile_lastname'] = values.profile_lastname;
        _baseInfo['profile_lastname'] = values.profile_lastname;


        setBaseInfo(_baseInfo);

        setPreview(Base64.encode(JSON.stringify(_baseInfo)))


        // setProfileLink(Base64.encode(JSON.stringify(_baseInfo)));


    }


    const createPayment = (values) => {

        setPaymentLoad(true);
        setCreditLoad(true);

        Connect().post('payment/create', {
            package_uuid: packetId,
            address:values.address,
            phone:values.phone
        }).then(({data}) => {


            setPaymentUrl(`${data.result.token}`);

            setCreditType(true);

        }).catch((e) => {

        }).finally(() => {
            setPaymentLoad(false);
            setCreditLoad(false);
        });

    }

    const packetControl = (type) => {

        return type !== undefined;

    }

    const packetTimeTypes = (type = '') => {

        let text;

        switch (type) {
            case "standart":
                text = 'Standart Paket Süresini Uzat';
                break;
            case "premium":
                text = 'Premium Paket Süresini Uzat';
                break;
            case "text":
                text = 'Test Paket Süresini Uzat';
                break;
        }

        return text;

    }

    const packetTypes = (type = '') => {

        let text;

        switch (type) {
            case "standart":
                text = 'Standart Paketler';
                break;
            case "premium":
                text = 'Premium Paketler';
                break;
            case "text":
                text = 'Test Paketler';
                break;
        }

        return text;

    }

    const packetUpdateType = (type = '') => {

        let text;

        switch (type) {
            case "standart":
                text = "Hesabını  Yükselt";
                break;
            case "premium":
                text = 'Hesabını Yükselt';
                break;
        }

        return text;

    }


    if (pageLoading) {
        return (<div className={"page-full-loading"}>
            <Loader color="violet" size={"xl"} variant="dots"/>
        </div>)
    }


    return (<>

        <Navbar id={id} menu={'profile'}/>


        <Modal padding={"xl"} size={!creditType ? 615 : 1100} centered opened={opened} onClose={() => {
            close();
            setActive(0);
            setPaymentType('');
            setCreditType(false);
            setPacketId('');
            creditForm.setValues({
                initialValues: {
                    phone: '+90',
                    address: '',
                },
            })
        }}
               className={"packet-modal"}
               title={active === 0 ?  packetGroupType === 'ExtraTime' ? packetTimeTypes(packetType) : packetTypes(packetType) : packetGroupType === 'ExtraTime' ? packetTimeTypes(packetType)  : packetUpdateType(packetType)}>


            <Stepper className={"step-top-remove"} active={active} onStepClick={setActive} breakpoint="sm">
                <Stepper.Step label="" description="">
                    {packetGroupType === 'ExtraTime' &&
                        <>
                            {packetControl(extraTimePacket[packetType]) &&
                                extraTimePacket[packetType].map((item, index) => (
                                    <Group style={{cursor: 'pointer'}} key={`standart-${index}`} position="apart"
                                           className={classes.item} noWrap spacing="xl"
                                           onClick={() => setPacketId(item.package_uuid)}>
                                        <div style={{width: '100%', display: 'flex', alignItems: 'center'}}>

                                            <div className={"mt-item-radio"} style={{float: 'left', height: '100%'}}>
                                                <Radio
                                                    size={"lg"}
                                                    value={item.package_uuid}
                                                    name={'standard_packet'}
                                                    checked={packetId === item.package_uuid}
                                                    color={"red"}
                                                />
                                            </div>

                                            <div style={{width: 'calc(100% - 32px)', float: 'left', paddingLeft: 18}}>
                                                <Text>{item.package_name}</Text>
                                                <Text size="xs" color="dimmed">
                                                    {item.short_description}
                                                </Text>
                                                <Text size={"xs"} fw={600}
                                                      color={"dimmed"}>{Number(item.price).toFixed(2)}TL</Text>
                                            </div>

                                        </div>
                                    </Group>
                                ))
                            }
                        </>
                    }

                    {packetGroupType === 'Transition' &&
                        <>
                            {packetControl(transitionPacket[packetType]) &&
                                transitionPacket[packetType].map((item, index) => (
                                    <Group style={{cursor: 'pointer'}} key={`standart-${index}`} position="apart"
                                           className={classes.item} noWrap spacing="xl"
                                           onClick={() => setPacketId(item.package_uuid)}>
                                        <div>
                                            <Group>
                                                <Radio
                                                    size={"lg"}
                                                    value={item.package_uuid}
                                                    name={'standard_packet'}
                                                    checked={packetId === item.package_uuid}
                                                    color={"red"}
                                                />
                                                <div>
                                                    <Text>{item.package_name}</Text>
                                                    <Text size="xs" color="dimmed">
                                                        {item.short_description}
                                                    </Text>
                                                    <Text size={"xs"} fw={600}
                                                          color={"dimmed"}>{Number(item.price).toFixed(2)}TL</Text>
                                                </div>
                                            </Group>
                                        </div>
                                    </Group>
                                ))
                            }

                        </>
                    }
                    <Button mt={25} disabled={packetId === ''} onClick={() => nextStep()} fullWidth color={"red"}>Paketi
                        Yükselt</Button>

                </Stepper.Step>
                <Stepper.Step label="" description="">
                    <Text color={"dimmed"} ta={"center"} mb={24} size={"sm"}>Ödeme türünü aşağıdan
                        seçebilirsiniz.</Text>

                    <Group grow mb={10}>
                        <Button leftIcon={<AiOutlineBank/>} onClick={() => {
                            setPaymentType('bank');
                            nextStep();
                        }} color={"indigo"}>Havele/EFT</Button>
                        <Button leftIcon={<AiOutlineCreditCard/>} onClick={() => {
                            setPaymentType('credit');
                            nextStep();
                        }} color={"indigo"}>Kredi Kartı</Button>
                    </Group>

                </Stepper.Step>
                <Stepper.Step label="" description="">
                    {paymentType === 'bank' &&
                        <>
                            <Text color={"red"} fw={600} ta={"center"} mb={5}>ZİRAAT BANKASI</Text>
                            <Text color={"dimmed"} ta={"center"} mb={24} size={"sm"}>Havale/EFT işlemleriniz için Hibrit
                                Card hesap bilgilerine aşağıdan ulaşabilirsiniz.</Text>
                            <List spacing={"md"} className={"list-packet"} listStyleType={"none"} pl={20} pr={20}>
                                <List.Item>
                                    <Group grow style={{width: '100%'}}>
                                        <Text fw={600} size={"sm"}>Hesap Aktivasyon Süresi</Text>
                                        <Text fw={600} ta={"right"} size={"sm"}>Bir Saat</Text>
                                    </Group>
                                </List.Item>
                                <List.Item>
                                    <Group grow style={{width: '100%'}}>
                                        <Text fw={600} size={"sm"}>Hesap Tipi</Text>
                                        <Text fw={600} ta={"right"} size={"sm"}>Türk Lirası</Text>
                                    </Group>
                                </List.Item>
                                <List.Item>
                                    <Group grow style={{width: '100%'}}>
                                        <Text fw={600} size={"sm"}>IBAN</Text>

                                        <CopyButton value={"TR 7000 0100 2101 9748 8785 5009"}>
                                            {({copied, copy}) => (
                                                <UnstyledButton onClick={copy}>
                                                    <Text ta={"right"} color={"indigo"} fw={600} size={"sm"}>TR 7000
                                                        0100 2101 9748 8785 5009 {copied ?
                                                            <IoCheckmarkDone style={{marginBottom: -2.5}}/> :
                                                            <IoCopyOutline style={{marginBottom: -2.5}}/>}</Text>
                                                </UnstyledButton>
                                            )}
                                        </CopyButton>

                                    </Group>
                                </List.Item>
                                <List.Item>
                                    <Group grow style={{width: '100%'}}>
                                        <Text fw={600} size={"sm"}>Alıcı</Text>
                                        <Text fw={600} ta={"right"} size={"sm"}>Hibritart Medya Reklam A.Ş</Text>
                                    </Group>
                                </List.Item>
                                <List.Item>
                                    <Group grow style={{width: '100%'}}>
                                        <Text fw={600} size={"sm"}>Açıklama Kodu</Text>
                                        <CopyButton value={user?.email}>
                                            {({copied, copy}) => (
                                                <UnstyledButton onClick={copy}>
                                                    <Text ta={"right"} color={"indigo"} fw={600}
                                                          size={"sm"}>{user?.email} {copied ?
                                                        <IoCheckmarkDone style={{marginBottom: -3}}/> :
                                                        <IoCopyOutline style={{marginBottom: -3}}/>}</Text>
                                                </UnstyledButton>
                                            )}
                                        </CopyButton>
                                    </Group>
                                </List.Item>
                            </List>

                            <Alert mt={25} mb={10} color="red" ta={"center"}>
                                Ödeme işlemin başarıyla tamamlanabilmesi için kırmızı ile yazılmış <br/>
                                <b style={{
                                    color: 'rgb(224, 49, 49)',
                                    marginTop: 3,
                                    marginBottom: 3,
                                    display: 'block'
                                }}>"{user?.email}"</b>
                                mail adresini, banka uygulamanızın ödeme ekranındaki Açıklama bölümüne kopyalayınız.
                            </Alert>

                        </>
                    }

                    {paymentType === 'credit' &&
                        <>

                        {!creditType &&
                            <>
                                <Text color={"dimmed"} ta={"center"} mb={24} size={"sm"}>Kredi kartı ile ödeme için bu bilgiler zorunludur</Text>
                                <form onSubmit={creditForm.onSubmit((values) => createPayment(values))}>
                                    <div className={" form-input"}>
                                        <label className={"mantine-InputWrapper-label"}>Telefon Numarası</label>
                                        <PhoneInput
                                            value={creditForm.values.phone}
                                            countryCodeEditable={false}
                                            autoFormat={true}
                                            onChange={phone => creditForm.setFieldValue('phone',phone)}
                                        />
                                    </div>

                                    <Textarea
                                        label={"Adres"}
                                        mt={20}
                                        className={"form-input"}
                                        minRows={3}
                                        placeholder={"Adres giriniz"}
                                        {...creditForm.getInputProps('address')}
                                    />


                                    <Group position="right" mt="md">
                                        <Button loading={creditLoad} type="submit" fullWidth color={"indigo"} mt={10}>Ödemeye Git</Button>
                                    </Group>
                                </form>
                            </>

                        }

                        {creditType &&
                            <div>
                                {paymentLoad &&
                                    <Center style={{height: 100}}>
                                        <Loader color={"violet"}/>
                                    </Center>
                                }
                                {!paymentLoad &&
                                    <div id={"payment_area"}>
                                        <div>
                                            {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                                            <iframe
                                                src={`https://www.paytr.com/odeme/guvenli/${paymentUrl}`}
                                                id="paytriframe"
                                                frameBorder="0"
                                                scrolling="no"
                                                style={{ width: '100%',height: '950px !important' }}
                                            ></iframe>

                                        </div>
                                    </div>
                                }
                            </div>
                        }

                        </>
                    }

                </Stepper.Step>
                <Stepper.Completed>
                    Completed, click back button to get to previous step
                </Stepper.Completed>
            </Stepper>




        </Modal>


        <Container size={1600}>

            {/* <Alert icon={<IconAlertCircle size="1rem"/>} title="Bummer!" mb={30} variant="outline" color="green">
                Something terrible happened! You made a mistake and there is no going back, your data was lost forever!
            </Alert> */}

            <PackageInfo user={user} />

            <Grid gutter={30} mb={50}>
                <Grid.Col lg={6}>


                    <Card withBorder padding="xl" radius="md" mb={35} className={classes.card}>
                        <Card.Section sx={{
                            backgroundImage: `url(${info?.img_cover_s3_url})`,
                            backgroundSize: 'cover',
                            backgroundColor: '#000',
                            height: 140,
                            position: 'relative'
                        }}>
                            <LoadingOverlay
                                overlayColor={"#000"}
                                loaderProps={{size: 'sm', color: 'violet',}}
                                visible={profileCoverFile !== null}/>


                            {profileCoverFile === null &&
                                <FileButton onChange={setProfileCoverFile} accept="image/png,image/jpeg">
                                    {(props) => <UnstyledButton {...props}>
                                        <ThemeIcon variant={"gradient"}
                                                   gradient={{from: 'violet', to: 'blue', deg: 60}} size={34}
                                                   radius={34} className={"edit-file"}>
                                            <HiOutlinePencilSquare size={14}/>
                                        </ThemeIcon>
                                    </UnstyledButton>}
                                </FileButton>
                            }
                        </Card.Section>

                        <Center>
                            <div
                                style={{height: 80, width: 80, position: 'relative', borderRadius: 80, marginTop: -30}}>

                                <Indicator processing
                                           className={profileFile !== null ? "position-indicator active" : "position-indicator"}
                                           inline label={
                                    <FileButton onChange={setProfileFile} accept="image/png,image/jpeg">
                                        {(props) => <UnstyledButton {...props}> <HiOutlinePencilSquare size={13}
                                                                                                       color={"#fff"}/></UnstyledButton>}
                                    </FileButton>} color={"violet"} size={25}>
                                    <LoadingOverlay radius={80}
                                                    overlayColor={"#000"}
                                                    loaderProps={{size: 'sm', color: 'violet',}}
                                                    visible={profileFile !== null}/>
                                    <Avatar
                                        src={info?.img_pp_s3_url}
                                        style={{backgroundColor: '#fff'}}

                                        size={80} radius={80} className={classes.avatar}/>

                                </Indicator>
                            </div>

                        </Center>

                        <Text ta="center" fz="lg" fw={600} mt="sm">
                            {info?.profile_firstname} {info?.profile_lastname}
                        </Text>


                    </Card>


                    <Card withBorder shadow={"md"} padding={30} radius="md">
                        <Title mb={20} order={2} className={"table-title-item"}>Profil Ayarları</Title>
                        <form onSubmit={form.onSubmit((values) => updateProfile(values, true))}>

                            <Grid gutter={30}>
                                <Grid.Col lg={12}>
                                    <TextInput radius={"md"} variant={"filled"} size={"md"}
                                               placeholder={"Profil Etiketi"}
                                               {...form.getInputProps('profile_tag')}
                                    />
                                </Grid.Col>
                                <Grid.Col lg={12}>
                                    <TextInput radius={"md"} variant={"filled"} size={"md"}
                                               placeholder={"Şirket İsmi"}
                                               {...form.getInputProps('profile_business_name')}
                                    />
                                </Grid.Col>
                                <Grid.Col lg={6}>
                                    <TextInput radius={"md"} variant={"filled"} size={"md"} placeholder={"İsim"}
                                               {...form.getInputProps('profile_firstname')}
                                    />
                                </Grid.Col>
                                <Grid.Col lg={6}>
                                    <TextInput radius={"md"} variant={"filled"} size={"md"} placeholder={"Soyisim"}
                                               {...form.getInputProps('profile_lastname')}
                                    />
                                </Grid.Col>
                                <Grid.Col lg={12}>
                                    <TextInput radius={"md"} variant={"filled"} size={"md"}
                                               placeholder={"Profil Ünvanı"}
                                               {...form.getInputProps('profile_title')}
                                    />
                                </Grid.Col>
                                <Grid.Col lg={12}>
                                    <Textarea radius={"md"} minRows={4} variant={"filled"} size={"md"}
                                              placeholder={"Profil Açıklaması"}
                                              {...form.getInputProps('profile_content')}
                                    />
                                    <Checkbox mt={23} mb={-5} size={"sm"} color={"violet"} className={"checkbox-left check-req"}
                                              label={<div>
                                                  Rehberi İndiriken buradaki açıklama geçerli olsun. <Text size={"xs"} color={"dimmed"}>(Seçilmez ise Fatura Bilgisi gözükür)</Text>
                                              </div>}
                                              {...form.getInputProps('ok_content',{type:"checkbox"})}
                                    />
                                </Grid.Col>

                            </Grid>


                            <Button loading={loading} fullWidth mt={28} color={"violet"} radius={"sm"} type={"submit"}>Güncellemeyi
                                Kaydet</Button>

                        </form>
                    </Card>


                </Grid.Col>

                <Grid.Col lg={6}>


                    {userInfo?.member_type === 'Premium' &&
                        <Card withBorder padding="xl" radius="md" mb={35} className={classes.card}>
                            <Title mb={5} order={2} className={"table-title-item"}>Hesabınızın Süresini Uzatın</Title>
                            <Text size="xs" color="dimmed" mb={25}>
                                Hesabınızı kullanım süresini ödeme yaparak uzatabilirsiniz.
                            </Text>

                            <Group position={'right'}>
                                <Button onClick={() => {
                                    setPacketGroupType('ExtraTime');
                                    setPacketType('premium');
                                    open();
                                }} style={{height: 40}} pl={23} pr={23} radius={6} variant="gradient"
                                        gradient={{from: 'orange', to: 'red'}}>Süreyi Uzatmak İçin Paketleri
                                    Görüntüle</Button>
                            </Group>
                        </Card>
                    }



                    {userInfo?.member_type === 'Standart' &&
                        <Card withBorder padding="xl" radius="md" mb={35} className={classes.card}>
                            <Title mb={5} order={2} className={"table-title-item"}>Hesabınızın Süresini Uzatın veya
                                Premium'a Yükseltin</Title>
                            <Text size="xs" color="dimmed" mb={25}>
                                Hesabınızı kullanım süresini ödeme yaparak uzatabilir veya premium'a yükseltebilirsiniz.
                            </Text>

                            <Group grow>
                                <Button onClick={() => {
                                    setPacketType('standart');
                                    setPacketGroupType('ExtraTime');
                                    open();
                                }} className={"default-btn"}>Süreyi Uzatmak İçin Paketleri Görüntüle</Button>
                                <Button onClick={() => {
                                    setPacketType('premium');
                                    setPacketGroupType('Transition');
                                    open();
                                }} style={{height: 40}} pl={23} pr={23} radius={6} variant="gradient"
                                        gradient={{from: 'orange', to: 'red'}}>Premium'a Yükselt</Button>
                            </Group>
                        </Card>
                    }

                    {userInfo?.member_type === 'Test' &&
                        <Card withBorder padding="xl" radius="md" mb={35} className={classes.card}>
                            <Title mb={5} order={2} className={"table-title-item"}>Hesabınızı Yükseltin</Title>
                            <Text size="xs" color="dimmed" mb={25}>
                                Hesabınızı Standart veya Premium'a yükseltebilirsiniz.
                            </Text>

                            <Group grow>
                                <Button onClick={() => {
                                    setPacketType('standart');
                                    setPacketGroupType('Transition');
                                    open();
                                }} className={"default-btn"}>Standart'a Yükselt</Button>
                                <Button onClick={() => {
                                    setPacketType('premium');
                                    setPacketGroupType('Transition');
                                    open();
                                }} style={{height: 40}} pl={23} pr={23} radius={6} variant="gradient"
                                        gradient={{from: 'orange', to: 'red'}}>Premium'a Yükselt</Button>
                            </Group>
                        </Card>
                    }



                    <Card withBorder shadow={"md"} padding={30} radius="md">
                        <Title mb={20} order={2} className={"table-title-item"}>Tema Ayarları</Title>

                        <SegmentedControl
                            value={info.theme.toLowerCase()}
                            fullWidth
                            color={'dark'}
                            size={"md"}
                            onChange={(value) => {

                                const firstLetter = value.charAt(0)

                                const firstLetterCap = firstLetter.toUpperCase()

                                const remainingLetters = value.slice(1)

                                const capitalizedWord = firstLetterCap + remainingLetters

                                toggleColorScheme(value);
                                updateProfile({theme: capitalizedWord});
                            }}
                            data={[
                                {
                                    value: 'light',
                                    label: (
                                        <Center>
                                            <IoSunnyOutline size={15}/>
                                            <Box ml={10}>Açık</Box>
                                        </Center>
                                    ),
                                },
                                {
                                    value: 'dark',
                                    label: (
                                        <Center>
                                            <IoMoonOutline size={15}/>
                                            <Box ml={10}>Koyu</Box>
                                        </Center>
                                    ),
                                },
                            ]}
                        />
                    </Card>


                    <Card mt={30} withBorder shadow={"md"} padding={30} radius="md">
                        <Title mb={2} order={2} className={"table-title-item"}>Sosyal Medya Ayarları</Title>
                        <Text size="xs" color="dimmed" mb={15}>
                            Sosyal medya hesaplarnızı ekleyin.
                        </Text>


                        <DragDropContext
                            onDragEnd={({destination, source}) => {
                                handlers.reorder({from: source.index, to: destination?.index || 0});
                            }}
                        >
                            <Droppable droppableId="dnd-list" direction="vertical">
                                {(provided) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                        {items}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>

                        <Text size="xs" color="dimmed" mt={15} mb={15}>
                            Hesaplarınızı Sıralayın.
                        </Text>

                        <Radio.Group
                            mt={25}
                            name="icon_position"
                            label="İkonların Konumu"
                            defaultValue={info?.icon_location}
                            onChange={(val) => updateProfile({icon_location: val})}
                        >
                            <Radio color={"violet"} mt={12} mb={12} value="Top" label="Üstte"/>
                            <Radio color={"violet"} value="Bottom" label="Altta"/>
                        </Radio.Group>


                    </Card>


                    <Card mt={30} withBorder shadow={"md"} padding={30} radius="md">

                        <Group position="apart" className={classes.item} noWrap spacing="xl">
                            <div>
                                <Text>Hibrit Card logosunu gizle</Text>
                                <Text size="xs" color="dimmed">
                                    Kart logosunun gözüküp gözükmeyeceğini belirleyebilirsiniz.
                                </Text>
                            </div>
                            <Switch disabled={userInfo?.member_type === 'Test'} defaultChecked={info.hide_logo}
                                    onChange={(event) => {
                                        updateProfile({hide_logo: event.currentTarget.checked});
                                    }} color={"teal"} className={classes.switch} size="lg"/>
                        </Group>
                    </Card>


                    <Button loading={deleteLoading} color={'red'} fullWidth style={{height: 43}} mt={25}
                            onClick={() => {
                                QuestionModal({
                                    modals,
                                    title: 'Profili Sil',
                                    text: 'Profili silmek',
                                    confirm: () => removeProfile()
                                });
                            }}>Profili Sil</Button>
                </Grid.Col>
            </Grid>

        </Container>

        <div className="fixed-btn">
            <Button component={Link} to={`/profile/${info?.uuid}`} leftIcon={<IoEyeOutline/>} radius={3}
                    style={{height: 37}}
                    size={"xs"} pl={20} pr={20} color={"dark"}>Önizlemeyi Gör</Button>
        </div>


    </>)

}

const mapProps = (state) => ({
    user: state.user.info
});


export default connect(mapProps)(ProfileDetail);
