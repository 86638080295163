import {useState} from "react";
import _ from "lodash";


const social = [
    {
        id: 1,
        slug: 'website',
        link: 'http://',
    },

    {
        id: 2,
        slug: 'whatsapp',
        link: 'http://wa.me/+',
    },


    {
        id: 3,
        slug: 'instagram',
        title: 'Instagram',
        link: 'https://instagram.com/',
    },

    {
        id: 4,
        slug: 'facebook',
        link: 'https://facebook.com/',
    },

    {
        id: 5,
        slug: 'twitter',
        link: 'https://twitter.com/',
    },

    {
        id: 6,
        slug: 'linkedin',
        link: 'https://linkedin.com/in/',
    },

    {
        id: 7,
        slug: 'youtube',
        link: 'https://youtube.com/',
    },

    {
        id: 8,
        slug: 'zoom',
        link: 'https://zoom.com/',
    },
    {
        id: 9,
        slug: 'telegram',
        link: 'https://telegram.com/',
    },

    {
        id: 10,
        slug: 'bip',
        link: 'https://bip.com/',
    },

    {
        id: 11,
        slug: 'discord',
        link: 'https://discord.com/',
    },

    {
        id: 12,
        slug: 'dribble',
        link: 'https://dribble.com/',
    },

    {
        id: 13,
        slug: 'vimeo',
        link: 'https://vimeo.com/',
    },

    {
        id: 14,
        slug: 'ello',
        link: 'https://ello.com/',
    },

    {
        id: 15,
        slug: 'github',
        link: 'https://github.com/',
    },
    {
        id: 16,
        slug: 'hangouts',
        link: 'https://hangouts.com/',
    },


    {
        id: 17,
        slug: 'icbc',
        link: 'https://icbc.com/',
    },

    {
        id: 18,
        slug: 'icq',
        link: 'https://icq.com/',
    },

    {
        id: 19,
        slug: 'kikmessenger',
        link: 'https://kikmessenger.com/',
    },

    {
        id: 20,
        slug: 'twitch',
        link: 'https://twitch.com/',
    },


    {
        id: 21,
        slug: 'medium',
        link: 'https://medium.com/',
    },


    {
        id: 22,
        slug: 'nimotv',
        link: 'https://nimotv.com/',
    },


    {
        id: 23,
        slug: 'periscope',
        link: 'https://periscope.com/',
    },

    {
        id: 24,
        slug: 'dailymotion',
        link: 'https://dailymotion.com/',
    },


    {
        id: 25,
        slug: 'wechat',
        link: 'https://wechat.com/',
    },

    {
        id: 26,
        slug: 'qqtile',
        link: 'https://qqtile.com/',
    },

    {
        id: 27,
        slug: 'sineweibo',
        link: 'https://sineweibo.com/',
    },

    {
        id: 28,
        slug: 'dlive',
        link: 'https://dlive.com/',
    },

    {
        id: 29,
        slug: 'pinterest',
        link: 'https://pinterest.com/',
    },

    {
        id: 30,
        slug: 'reddit',
        link: 'https://reddit.com/',
    },


    {
        id: 31,
        slug: 'behance',
        link: 'https://behance.com/',
    },

    {
        id: 32,
        slug: 'swarm',
        link: 'https://swarm.com/',
    },

    {
        id: 33,
        slug: 'signal',
        link: 'https://signal.com/',
    },

    {
        id: 34,
        slug: 'yaya',
        link: 'https://yaya.com/',
    },


    {
        id: 35,
        slug: 'c2',
        link: 'https://c2.com/',
    },


    {
        id: 36,
        slug: 'tango',
        link: 'https://tango.com/',
    },

    {
        id: 37,
        slug: 'vero',
        link: 'https://vero.com/',
    },

    {
        id: 38,
        slug: 'viber',
        link: 'https://viber.com/',
    },


    {
        id: 39,
        slug: 'teams',
        link: 'https://teams.com/',
    },

    {
        id: 40,
        slug: 'snapchat',
        link: 'https://snapchat.com/',
    },

];

export const SocialLink = (type = '') => {



    return _.find(social, {
        slug: type
    }).link;


}

