import axios from 'axios';
import { getSession } from "./auth";
import { BASE_URL } from './constants';


export default function (auth = true) {

    const token = getSession();

    return axios.create({
        baseURL: BASE_URL,
        timeout: 30000,
        headers: auth ? {
            Authorization: 'Bearer: ' + token,
            'Content-Type': 'multipart/form-data'
        } : {
            'Content-Type': 'multipart/form-data'
        }
    });
}
