import {Button, CopyButton, Divider, List, ThemeIcon} from "@mantine/core";
import {AiOutlineFileText, AiOutlineHome, AiOutlinePhone} from "react-icons/ai";
import {SlLocationPin} from "react-icons/sl";
import {IoCopy} from "react-icons/io5";
import {HiOutlineBuildingOffice2, HiOutlineEnvelope} from "react-icons/hi2";
import {useEffect, useState} from "react";


export const BillingPanel = ({data}) => {

    const [loading, setLoading] = useState(true);


    useEffect(() => {


        if (data !== undefined) {
            setLoading(false);
        }

    }, [data]);


    if (loading) {
        return (<></>)
    }


    return (<>
        <div className="item-row-item" key={`invoice-2`}>
            <List spacing={"sm"} className={"list-item-ul"}>

                {(data.company_name !== '' && data.company_name !== null) &&
                    <List.Item icon={
                    <ThemeIcon variant="light" color="gray">
                        <AiOutlineHome/>
                    </ThemeIcon>}>{data?.company_name}</List.Item>
                }

                {(data.tax_office !== '' && data.tax_office !== null) &&

                    <List.Item icon={
                    <ThemeIcon variant="light" color="gray">
                        <HiOutlineBuildingOffice2/>
                    </ThemeIcon>}>{data?.tax_office}</List.Item>
                }

                {(data.tax_number !== '' && data.tax_number !== null) &&
                    <List.Item icon={
                        <ThemeIcon variant="light" color="gray">
                            <AiOutlineFileText/>
                        </ThemeIcon>}>{data?.tax_number}</List.Item>
                }

                {(data.address !== '' && data.address !== null) &&

                    <List.Item icon={
                        <ThemeIcon variant="light" color="gray">
                            <SlLocationPin/>
                        </ThemeIcon>}>{data?.address}</List.Item>
                }

                {(data.phone_code !== '' && data.phone_code !== null) &&
                    <List.Item icon={
                        <ThemeIcon variant="light" color="gray">
                            <AiOutlinePhone style={{transform: 'rotate(90deg)'}}/>
                        </ThemeIcon>}>+{data?.phone_code}</List.Item>
                }

                {(data.email !== '') &&
                    <List.Item icon={
                        <ThemeIcon variant="light" color="gray">
                            <HiOutlineEnvelope/>
                        </ThemeIcon>}>{data?.email}</List.Item>
                }

            </List>



            {data?.id !== null &&

                <CopyButton value={data?.tax_number}>
                {({copied, copy}) => (
                    <Button fullWidth mt={20} color={copied ? "teal" : "violet"} onClick={copy}
                            leftIcon={<IoCopy/>}>{copied ? "Kopyalandı" : "Vergi Numarasını Kopyala"} </Button>
                )}
            </CopyButton>
            }


            <Divider variant={"dashed"} mt={30} mb={-10}/>
        </div>
    </>)

}

export default BillingPanel;
