import {Button, Text} from "@mantine/core";
import HibritLogo from "../../lib/components/logo";
import {IoChevronForward} from "react-icons/io5";
import {Link, useParams} from "react-router-dom";


export const NewProfile = () => {

    const {id} = useParams();


    return (<>
        <div id="select-profile">


            <div className="d-table">
                <div className="table-center">

                    <div className="text-center">
                            <HibritLogo/>
                            <Text size={14} mt={7} color={"white"}>Kullanmaya başlayabilirsin.</Text>
                            <Button component={Link}  to={`/register/?id=${id}`} mt={20} pl={25} pr={25} className={"default-btn"} style={{borderRadius:5,borderWidth:0}} rightIcon={<IoChevronForward/>}>Yolculuğa başlayalım...</Button>
                    </div>
                </div>
            </div>

        </div>
    </>)

}

export default NewProfile;
