import {store} from '../../../store';
import {USER, NOTICE, UPGRADE} from "../../DATA_TYPE";

export function set_user(info) {
    store.dispatch({
        type: USER,
        payload: {
            info
        }
    });
}

export function set_notice(notice) {
    store.dispatch({
        type: NOTICE,
        payload: {
            notice
        }
    });
}


export function set_upgrade(upgrade) {
    store.dispatch({
        type: UPGRADE,
        payload: {
            upgrade
        }
    });
}
