import {
    Alert,
    Button,
    Card,
    Center,
    Container,
    CopyButton,
    createStyles,
    Grid,
    Group,
    List,
    Loader,
    Modal,
    Paper,
    Radio,
    rem,
    SegmentedControl,
    Stepper,
    Text,
    Textarea,
    Title,
    UnstyledButton
} from "@mantine/core";
import {useDisclosure, useDocumentTitle} from "@mantine/hooks";
import Navbar from "../../lib/components/Nav";
import React, {useEffect, useState} from "react";
import Connect from "../../lib/util/Connect";
import {IoArrowBack, IoCheckmarkDone, IoCopyOutline} from "react-icons/io5";
import {useForm} from "@mantine/form";
import {notifications} from "@mantine/notifications";
import {ErrorMessage} from "../../lib/util/ErrorMessage";
import {connect} from "react-redux";
import {AiOutlineBank, AiOutlineCreditCard} from "react-icons/ai";
import PhoneInput from "react-phone-input-2";
import {IconBoxSeam, IconCalendar} from "@tabler/icons-react";
import Moment from "react-moment";
import {Link} from "react-router-dom";
import _ from "lodash";


const useStyles = createStyles((theme) => ({
    card: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    },

    avatar: {
        border: `${rem(2)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white}`,
    },


    item: {
        '& + &': {
            paddingTop: theme.spacing.sm,
            marginTop: theme.spacing.sm,
            borderTop: `${rem(1)} solid ${
                theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
            }`,
        },
    },

    switch: {
        '& *': {
            cursor: 'pointer',
        },
    },

    title: {
        lineHeight: 1,
    },

}));


export const Packages = ({user}) => {


    useDocumentTitle('Paketler');


    const [pageLoading, setPageLoading] = useState(true);
    const [opened, {open, close}] = useDisclosure(false);
    const [userInfo, setUserInfo] = useState({});
    const [standartPacket, setStandartPacket] = useState([
        {
            id: 1,
            title: 'Hibrit Card Tasarımı',
            text: 'Standart Tasarıma Sahip Kart',
            price: 0,
            item: ''
        },
        {
            id: 2,
            title: 'Logolu Kart Tasarımı',
            text: 'Ad soyad, Şirket İsim ve Logonuzu, kartınız üzerine ekletebilirsiniz',
            price: 0,
            item: ''
        },
        {
            id: 3,
            title: 'Özel Kart Tasarımı',
            text: 'Talep ettiğiniz tasarımı kartınıza uygulatabilirsiniz',
            price: 0,
            item: ''
        }
    ]);
    const [premiumPacket, setPremiumPacket] = useState([
        {
            id: 1,
            title: 'Hibrit Card Tasarımı',
            text: 'Standart Tasarıma Sahip Kart',
            price: 0,
            item: ''
        },
        {
            id: 2,
            title: 'Logolu Kart Tasarımı',
            text: 'Ad soyad, Şirket İsim ve Logonuzu, kartınız üzerine ekletebilirsiniz',
            price: 0,
            item: ''
        },
        {
            id: 3,
            title: 'Özel Kart Tasarımı',
            text: 'Talep ettiğiniz tasarımı kartınıza uygulatabilirsiniz',
            price: 0,
            item: ''
        }
    ]);
    const [packetType, setPacketType] = useState('standart');
    const [packetId, setPacketId] = useState('');
    const [paymentLoad, setPaymentLoad] = useState(true);
    const [active, setActive] = useState(0);
    const [paymentType, setPaymentType] = useState('');
    const [paymentUrl, setPaymentUrl] = useState('');
    const [creditType, setCreditType] = useState(false);
    const [creditLoad, setCreditLoad] = useState(false);
    const [packetItems, setPacketItems] = useState([]);
    const [packetGroupType, setPacketGroupType] = useState('ExtraTime');
    const [extraTimePacket, setExtraTimePacket] = useState({
        premium: [],
        standart: [],
        test: []
    });
    const [transitionPacket, setTransitionPacket] = useState({
        premium: [],
        standart: [],
    });

    const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));
    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));


    const {classes, theme} = useStyles();



    const creditForm = useForm({
        initialValues: {
            phone: '+90',
            address: '',

        },

        validate: {
            phone: (value) => (value !== '' ? null : 'Telefon Numarası boş girilemez'),
            address: (value) => (value !== '' ? null : 'Adres boş girilemez'),
        },
    });


    useEffect(() => {



    }, []);


    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://www.paytr.com/js/iframeResizer.min.js';
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);


    useEffect(() => {

        if (user !== null && user !== undefined) {

            getPrices();


            let packet = [
                {label: 'Standart', value: 'standart'},
                {label: 'Premium', value: 'premium'}
            ];


            if (!user.ugprade_account){

                if (user.member_type === 'Standart') {

                    packet = [
                        {label: 'Premium', value: 'premium'}
                    ];
                    setPacketType('premium');

                } else if (user.member_type === 'Premium') {
                    packet = [
                        {label: 'Standart', value: 'standart'}
                    ];
                    setPacketType('standart');
                }

            }



            setPacketItems(packet);

            setUserInfo(user);
            if (user.phone_code !== null && user.phone_code !== '') {
                creditForm.setFieldValue('phone', user.phone_code);
            }
        }

    }, [user]);


    useEffect(()=>{

        if (!pageLoading){

            window.addEventListener('message', function (event) {


                const event_data = event.data;


                if(typeof event_data === 'string') {

                    try {
                        const data = JSON.parse(event_data);
                    //    console.log('data',data);
                        if(data?.result) {

                            if(data.result === 'success') {

                                console.log('success');

                            } else {
                                console.log('hatata');
                            }


                        } else {
                            // başarısız modal çıkar
                        }
                    }catch (e){

                    }
                }


            });

        }

    },[pageLoading]);


    const getPrices = () => {

        Connect().get('general/packages').then(({data}) => {

            let _data = data.result;

            let _extraPackets = [];
            let _transitionPackets = [];

            const groupedData = _.groupBy(_data, 'package_type');


            let ExtraPacketGroup = _.groupBy(groupedData['ExtraTime'], 'member_type');


            if (ExtraPacketGroup.Test !== undefined) {
                _extraPackets['test'] = ExtraPacketGroup.Test;
            }

            if (ExtraPacketGroup.Standart !== undefined) {
                _extraPackets['standart'] = ExtraPacketGroup.Standart;
            }
            if (ExtraPacketGroup.Premium !== undefined) {
                _extraPackets['premium'] = ExtraPacketGroup.Premium;
            }

            let filterTransition =   _.filter(groupedData['Transition'], { member_type: user?.member_type });

            let TransitionPacketGroup = _.groupBy(filterTransition, 'transit_member_type');



            if (TransitionPacketGroup.Standart !== undefined) {
                _transitionPackets['standart'] = TransitionPacketGroup.Standart;
            }
            if (TransitionPacketGroup.Premium !== undefined) {
                _transitionPackets['premium'] = TransitionPacketGroup.Premium;
            }


            setTransitionPacket(_transitionPackets);
            setExtraTimePacket(_extraPackets);


            setTimeout(()=>{
                setPageLoading(false);
            },100);

        }).catch((e) => {

            notifications.show({
                title: 'Hata',
                withCloseButton: false,
                message: ErrorMessage(e),
                color: 'red'
            });
        });

    }




    const createPayment = (values) => {

        setPaymentLoad(true);
        setCreditLoad(true);

        Connect().post('payment/create', {
            package_uuid: packetId,
            address:values.address,
            phone:values.phone
        }).then(({data}) => {


            setPaymentUrl(`${data.result.token}`);

            setCreditType(true);

        }).catch((e) => {

        }).finally(() => {
            setPaymentLoad(false);
            setCreditLoad(false);
        });

    }

    const packetControl = (type) => {

        return type !== undefined;

    }

    const packetTimeTypes = (type = '') => {

        let text;

        switch (type) {
            case "standart":
                text = 'Standart Paket Süresini Uzat';
                break;
            case "premium":
                text = 'Premium Paket Süresini Uzat';
                break;
            case "text":
                text = 'Test Paket Süresini Uzat';
                break;
        }

        return text;

    }

    const packetTypes = (type = '') => {

        let text;

        switch (type) {
            case "standart":
                text = 'Standart Paketler';
                break;
            case "premium":
                text = 'Premium Paketler';
                break;
            case "text":
                text = 'Test Paketler';
                break;
        }

        return text;

    }

    const packetUpdateType = (type = '') => {

        let text;

        switch (type) {
            case "standart":
                text = "Hesabını  Yükselt";
                break;
            case "premium":
                text = 'Hesabını Yükselt';
                break;
        }

        return text;

    }


    if (pageLoading) {
        return (<div className={"page-full-loading"}>
            <Loader color="violet" size={"xl"} variant="dots"/>
        </div>)
    }


    return (<>

        <Navbar/>

        <Modal padding={"xl"} size={!creditType ? 615 : 1100} centered opened={opened} onClose={() => {
            close();
            setActive(0);
            setPaymentType('');
            setCreditType(false);
            setPacketId('');
            creditForm.setValues({
                initialValues: {
                    phone: '+90',
                    address: '',
                },
            })
        }}
               className={"packet-modal"}
               title={active === 0 ?  packetGroupType === 'ExtraTime' ? packetTimeTypes(packetType) : packetTypes(packetType) : packetGroupType === 'ExtraTime' ? packetTimeTypes(packetType)  : packetUpdateType(packetType)}>


            <Stepper className={"step-top-remove"} active={active} onStepClick={setActive} breakpoint="sm">
                <Stepper.Step label="" description="">
                    {packetGroupType === 'ExtraTime' &&
                        <>
                            {packetControl(extraTimePacket[packetType]) &&
                                extraTimePacket[packetType].map((item, index) => (
                                    <Group style={{cursor: 'pointer'}} key={`standart-${index}`} position="apart"
                                           className={classes.item} noWrap spacing="xl"
                                           onClick={() => setPacketId(item.package_uuid)}>
                                        <div style={{width: '100%', display: 'flex', alignItems: 'center'}}>

                                            <div className={"mt-item-radio"} style={{float: 'left', height: '100%'}}>
                                                <Radio
                                                    size={"lg"}
                                                    value={item.package_uuid}
                                                    name={'standard_packet'}
                                                    checked={packetId === item.package_uuid}
                                                    color={"red"}
                                                />
                                            </div>

                                            <div style={{width: 'calc(100% - 32px)', float: 'left', paddingLeft: 18}}>
                                                <Text>{item.package_name}</Text>
                                                <Text size="xs" color="dimmed">
                                                    {item.short_description}
                                                </Text>
                                                <Text size={"xs"} fw={600}
                                                      color={"dimmed"}>{Number(item.price).toFixed(2)}TL</Text>
                                            </div>

                                        </div>
                                    </Group>
                                ))
                            }
                        </>
                    }

                    {packetGroupType === 'Transition' &&
                        <>
                            {packetControl(transitionPacket[packetType]) &&
                                transitionPacket[packetType].map((item, index) => (
                                    <Group style={{cursor: 'pointer'}} key={`standart-${index}`} position="apart"
                                           className={classes.item} noWrap spacing="xl"
                                           onClick={() => setPacketId(item.package_uuid)}>
                                        <div>
                                            <Group>
                                                <Radio
                                                    size={"lg"}
                                                    value={item.package_uuid}
                                                    name={'standard_packet'}
                                                    checked={packetId === item.package_uuid}
                                                    color={"red"}
                                                />
                                                <div>
                                                    <Text>{item.package_name}</Text>
                                                    <Text size="xs" color="dimmed">
                                                        {item.short_description}
                                                    </Text>
                                                    <Text size={"xs"} fw={600}
                                                          color={"dimmed"}>{Number(item.price).toFixed(2)}TL</Text>
                                                </div>
                                            </Group>
                                        </div>
                                    </Group>
                                ))
                            }

                        </>
                    }
                    <Button mt={25} disabled={packetId === ''} onClick={() => nextStep()} fullWidth color={"red"}>Paketi
                        Yükselt</Button>

                </Stepper.Step>
                <Stepper.Step label="" description="">
                    <Text color={"dimmed"} ta={"center"} mb={24} size={"sm"}>Ödeme türünü aşağıdan
                        seçebilirsiniz.</Text>

                    <Group grow mb={10}>
                        <Button leftIcon={<AiOutlineBank/>} onClick={() => {
                            setPaymentType('bank');
                            nextStep();
                        }} color={"indigo"}>Havele/EFT</Button>
                        <Button leftIcon={<AiOutlineCreditCard/>} onClick={() => {
                            setPaymentType('credit');
                            nextStep();
                        }} color={"indigo"}>Kredi Kartı</Button>
                    </Group>

                </Stepper.Step>
                <Stepper.Step label="" description="">
                    {paymentType === 'bank' &&
                        <>
                            <Text color={"red"} fw={600} ta={"center"} mb={5}>ZİRAAT BANKASI</Text>
                            <Text color={"dimmed"} ta={"center"} mb={24} size={"sm"}>Havale/EFT işlemleriniz için Hibrit
                                Card hesap bilgilerine aşağıdan ulaşabilirsiniz.</Text>
                            <List spacing={"md"} className={"list-packet"} listStyleType={"none"} pl={20} pr={20}>
                                <List.Item>
                                    <Group grow style={{width: '100%'}}>
                                        <Text fw={600} size={"sm"}>Hesap Aktivasyon Süresi</Text>
                                        <Text fw={600} ta={"right"} size={"sm"}>Bir Saat</Text>
                                    </Group>
                                </List.Item>
                                <List.Item>
                                    <Group grow style={{width: '100%'}}>
                                        <Text fw={600} size={"sm"}>Hesap Tipi</Text>
                                        <Text fw={600} ta={"right"} size={"sm"}>Türk Lirası</Text>
                                    </Group>
                                </List.Item>
                                <List.Item>
                                    <Group grow style={{width: '100%'}}>
                                        <Text fw={600} size={"sm"}>IBAN</Text>

                                        <CopyButton value={"TR 7000 0100 2101 9748 8785 5009"}>
                                            {({copied, copy}) => (
                                                <UnstyledButton onClick={copy}>
                                                    <Text ta={"right"} color={"indigo"} fw={600} size={"sm"}>TR 7000
                                                        0100 2101 9748 8785 5009 {copied ?
                                                            <IoCheckmarkDone style={{marginBottom: -2.5}}/> :
                                                            <IoCopyOutline style={{marginBottom: -2.5}}/>}</Text>
                                                </UnstyledButton>
                                            )}
                                        </CopyButton>

                                    </Group>
                                </List.Item>
                                <List.Item>
                                    <Group grow style={{width: '100%'}}>
                                        <Text fw={600} size={"sm"}>Alıcı</Text>
                                        <Text fw={600} ta={"right"} size={"sm"}>Hibritart Medya Reklam A.Ş</Text>
                                    </Group>
                                </List.Item>
                                <List.Item>
                                    <Group grow style={{width: '100%'}}>
                                        <Text fw={600} size={"sm"}>Açıklama Kodu</Text>
                                        <CopyButton value={user?.email}>
                                            {({copied, copy}) => (
                                                <UnstyledButton onClick={copy}>
                                                    <Text ta={"right"} color={"indigo"} fw={600}
                                                          size={"sm"}>{user?.email} {copied ?
                                                        <IoCheckmarkDone style={{marginBottom: -3}}/> :
                                                        <IoCopyOutline style={{marginBottom: -3}}/>}</Text>
                                                </UnstyledButton>
                                            )}
                                        </CopyButton>
                                    </Group>
                                </List.Item>
                            </List>

                            <Alert mt={25} mb={10} color="red" ta={"center"}>
                                Ödeme işlemin başarıyla tamamlanabilmesi için kırmızı ile yazılmış <br/>
                                <b style={{
                                    color: 'rgb(224, 49, 49)',
                                    marginTop: 3,
                                    marginBottom: 3,
                                    display: 'block'
                                }}>"{user?.email}"</b>
                                mail adresini, banka uygulamanızın ödeme ekranındaki Açıklama bölümüne kopyalayınız.
                            </Alert>

                        </>
                    }

                    {paymentType === 'credit' &&
                        <>

                            {!creditType &&
                                <>
                                    <Text color={"dimmed"} ta={"center"} mb={24} size={"sm"}>Kredi kartı ile ödeme için bu bilgiler zorunludur</Text>
                                    <form onSubmit={creditForm.onSubmit((values) => createPayment(values))}>
                                        <div className={" form-input"}>
                                            <label className={"mantine-InputWrapper-label"}>Telefon Numarası</label>
                                            <PhoneInput
                                                value={creditForm.values.phone}
                                                countryCodeEditable={false}
                                                autoFormat={true}
                                                onChange={phone => creditForm.setFieldValue('phone',phone)}
                                            />
                                        </div>

                                        <Textarea
                                            label={"Adres"}
                                            mt={20}
                                            className={"form-input"}
                                            minRows={3}
                                            placeholder={"Adres giriniz"}
                                            {...creditForm.getInputProps('address')}
                                        />


                                        <Group position="right" mt="md">
                                            <Button loading={creditLoad} type="submit" fullWidth color={"indigo"} mt={10}>Ödemeye Git</Button>
                                        </Group>
                                    </form>
                                </>

                            }

                            {creditType &&
                                <div>
                                    {paymentLoad &&
                                        <Center style={{height: 100}}>
                                            <Loader color={"violet"}/>
                                        </Center>
                                    }
                                    {!paymentLoad &&
                                        <div id={"payment_area"}>
                                            <div>
                                                {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                                                <iframe
                                                    src={`https://www.paytr.com/odeme/guvenli/${paymentUrl}`}
                                                    id="paytriframe"
                                                    frameBorder="0"
                                                    scrolling="no"
                                                    style={{ width: '100%',height: '950px !important' }}
                                                ></iframe>

                                            </div>
                                        </div>
                                    }
                                </div>
                            }

                        </>
                    }

                </Stepper.Step>
                <Stepper.Completed>
                    Completed, click back button to get to previous step
                </Stepper.Completed>
            </Stepper>




        </Modal>




        <Container size={1600}>

            <Grid mb={25}>
                <Grid.Col xs={12} md={4}>
                    <Paper withBorder p="md" radius="md" key="pi-info" mih={100}>
                        <Group position="apart">
                            <Text size="xs" color="dimmed" className={classes.title}>
                                HESAP OLUŞTURMA TARİHİ
                            </Text>
                            <IconCalendar size="1.4rem" stroke={1.5}/>
                        </Group>

                        <Text fz="xs" c="dimmed" mt={7}>
                            <Moment format={"DD/MM/YYYY"}>{user?.created_at}</Moment>
                        </Text>
                    </Paper>
                </Grid.Col>

                <Grid.Col xs={12} md={4}>
                    <Paper withBorder p="md" radius="md" key="pi-info" mih={100}>
                        <Group position="apart">
                            <Text size="xs" color="dimmed" className={classes.title}>
                                HESAP BİTİŞ TARİHİ
                            </Text>
                            <IconCalendar size="1.4rem" stroke={1.5}/>
                        </Group>

                        <Text fz="xs" c="dimmed" mt={7}>
                            <Moment format={"DD/MM/YYYY"}>{user?.exp_date}</Moment>
                        </Text>
                    </Paper>
                </Grid.Col>


                <Grid.Col xs={12} md={4}>
                    <Paper withBorder p="md" radius="md" key="pi-info" mih={100}>
                        <Group position="apart">
                            <Text size="xs" color="dimmed" className={classes.title}>
                                HESAP TÜRÜ
                            </Text>
                            <IconBoxSeam size="1.4rem" stroke={1.5}/>
                        </Group>

                        <Text fz="xs" c="dimmed" mt={7}>
                            {user?.member_type}
                            {user?.ugprade_account ?  ' hesap süreniz dolmuştur' : ''}
                        </Text>
                    </Paper>
                </Grid.Col>
            </Grid>


            {userInfo?.member_type === 'Premium' &&
                <Card withBorder padding="xl" radius="md" mb={35} className={classes.card}>
                    <Title mb={5} order={2} className={"table-title-item"}>Hesabınızın Süresini Uzatın</Title>
                    <Text size="xs" color="dimmed" mb={25}>
                        Hesabınızı kullanım süresini ödeme yaparak uzatabilirsiniz.
                    </Text>

                    <Group position={'right'}>
                        <Button onClick={() => {
                            setPacketGroupType('ExtraTime');
                            setPacketType('premium');
                            open();
                        }} style={{height: 40}} pl={23} pr={23} radius={6} variant="gradient"
                                gradient={{from: 'orange', to: 'red'}}>Süreyi Uzatmak İçin Paketleri
                            Görüntüle</Button>
                    </Group>
                </Card>
            }



            {userInfo?.member_type === 'Standart' &&
                <Card withBorder padding="xl" radius="md" mb={35} className={classes.card}>
                    <Title mb={5} order={2} className={"table-title-item"}>Hesabınızın Süresini Uzatın veya
                        Premium'a Yükseltin</Title>
                    <Text size="xs" color="dimmed" mb={25}>
                        Hesabınızı kullanım süresini ödeme yaparak uzatabilir veya premium'a yükseltebilirsiniz.
                    </Text>

                    <Group grow>
                        <Button onClick={() => {
                            setPacketType('standart');
                            setPacketGroupType('ExtraTime');
                            open();
                        }} className={"default-btn"}>Süreyi Uzatmak İçin Paketleri Görüntüle</Button>
                        <Button onClick={() => {
                            setPacketType('premium');
                            setPacketGroupType('Transition');
                            open();
                        }} style={{height: 40}} pl={23} pr={23} radius={6} variant="gradient"
                                gradient={{from: 'orange', to: 'red'}}>Premium'a Yükselt</Button>
                    </Group>
                </Card>
            }

            {userInfo?.member_type === 'Test' &&
                <Card withBorder padding="xl" radius="md" mb={35} className={classes.card}>
                    <Title mb={5} order={2} className={"table-title-item"}>Hesabınızı Yükseltin</Title>
                    <Text size="xs" color="dimmed" mb={25}>
                        Hesabınızı Standart veya Premium'a yükseltebilirsiniz.
                    </Text>

                    <Group grow>
                        <Button onClick={() => {
                            setPacketType('standart');
                            setPacketGroupType('Transition');
                            open();
                        }} className={"default-btn"}>Standart'a Yükselt</Button>
                        <Button onClick={() => {
                            setPacketType('premium');
                            setPacketGroupType('Transition');
                            open();
                        }} style={{height: 40}} pl={23} pr={23} radius={6} variant="gradient"
                                gradient={{from: 'orange', to: 'red'}}>Premium'a Yükselt</Button>
                    </Group>
                </Card>
            }



        </Container>


    </>)

}

const mapProps = (state) => ({
    user: state.user.info
});


export default connect(mapProps)(Packages);
