import {AiOutlineBank, AiOutlineFileText, AiOutlinePhone} from "react-icons/ai";
import {IoShareSocialOutline} from "react-icons/io5";
import {HiOutlineDocumentAdd} from "react-icons/hi";


export const panelIcon = (type = '') => {


    let icon;

    switch (type) {

        case "ContactInformation":

            icon = <AiOutlinePhone size={19}/>;

            break;

        case "URL_Link":

            icon = <IoShareSocialOutline size={19}/>;

            break;

        case "Bank_Info":

            icon = <AiOutlineBank size={19}/>;

            break;

        case "Document":

            icon = <HiOutlineDocumentAdd size={19}/>;

            break;

        case "Customer_Form":

            icon = <AiOutlineFileText size={19}/>;

            break;


        case "Invoice":

            icon = <AiOutlineFileText size={19}/>;

            break;


        default:

            icon = <AiOutlineFileText size={19}/>

            break
    }


    return icon;

}
