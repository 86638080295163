import {Button, Card, Grid, TextInput, Text, Group, ThemeIcon} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {useListState, useScrollIntoView} from "@mantine/hooks";
import {IoAddSharp, IoTrashOutline} from "react-icons/io5";
import Connect from "../../util/Connect";
import {ErrorViewing} from "../../util/ErrorViewing";
import {modals} from "@mantine/modals";
import {SuccessViewing} from "../../util/SuccessViewing";
import {QuestionModal} from "../../util/QuestionModal";
import {notifications} from "@mantine/notifications";
import {ErrorMessage} from "../../util/ErrorMessage";
import {IMaskInput} from "react-imask";

export const BankForm = ({info,onResult,onItem}) => {


    const [infoItem,setInfoItem] = useState(null);
    const [loading,setLoading] = useState(false);
    const [editIndex,setEditIndex] = useState(null);
    const [pageLoading, setPageLoading] = useState(true);
    const [blankData, setBlankData] = useState([]);

    const [form, setForm] = useListState([
        {
            id:null,
            fullname: '',
            bankTitle:'',
            department:'',
            departmentCode:'',
            accountNumber:'',
            iban:'',
            swift:'',
            bic:''
        }
    ]);




    useEffect(()=>{

    //    console.log('info.MP_Bank_Info',info.MP_Bank_Info);

        if (info.MP_Bank_Info.length){


            setForm.setState([]);

            info.MP_Bank_Info.map((item,index) => {


                setForm.append({
                    id:item.id,
                    fullname: item.fullname,
                    bankTitle:item.bank_name,
                    department:item.bank_branch,
                    departmentCode:item.bank_branch_code,
                    accountNumber:item.bank_account_number,
                    iban:item.bank_iban,
                    swift:item.bank_swift,
                    bic:item.bank_bic
                });

            });

        }

    },[]);




    useEffect(()=>{


        setBlankData([]);

        let data = [];


        form.map((item,index) => {
            data.push({
                id: null,
                member_panel_id: 897,
                is_deleted: false,
                status: true,
                created_at: "2023-06-28T17:17:32.622Z",
                updated_at: "2023-06-28T17:17:32.622Z",
                bank_account_number: item.accountNumber,
                bank_bic: item.bic,
                bank_branch: item.department,
                bank_branch_code: item.departmentCode,
                bank_iban: item.iban,
                bank_name: item.bankTitle,
                bank_swift: item.swift,
                fullname: item.fullname
            });
        });



        setBlankData(data);
        onItem(data, true);


    },[form]);



    const formSet = (id = null, index = 0) => {


        let item = form[index];

        if (item.fullname !== ''){

            setEditIndex(index)
            setLoading(true);


            Connect().post(id === null ?`panel/item/Bank_Info/${info.id}/add` : `panel/item/Bank_Info/edit/${id}`,{
                bank_account_number: item.accountNumber,
                bank_bic: item.bic,
                bank_branch:item.department,
                bank_branch_code: item.departmentCode,
                bank_iban: item.iban,
                bank_name: item.bankTitle,
                bank_swift: item.swift,
                fullname: item.fullname
            }).then(({data}) => {

                SuccessViewing('Güncelleme Tamamlandı',modals);

                if (id === null){
                    let result = data.result;
                    setForm.setItemProp(index,'id',result.id);
                }

                onResult();


            }).catch((e) => ErrorViewing(e,modals)).finally(() => {
                setLoading(false);
                setEditIndex(null);
            });

        } else {
            ErrorViewing({message:'Hesap Sahibi Girmelisiniz'},modals);
        }

    }


    const removePanel = (index,id) => {


        Connect().get(`panel/item/Bank_Info/deleted/${id}`).then(({data}) =>{

            notifications.show({
                title: 'Başarılı',
                withCloseButton:false,
                message: 'Banka Silindi',
                color: 'teal'
            });

            setForm.remove(index);

            onResult();

        }).catch((e) =>{
            notifications.show({
                title: 'Hata',
                withCloseButton:false,
                message: ErrorMessage(e),
                color: 'red'
            });
        })


    }

    return (
        <form>
            {form.map((item, index) => (
               <Card shadow={"sm"} p={"xl"} mb={20}>
                   <Grid gutter={25} key={`form-${index}`}>
                       <Grid.Col lg={12}>
                           <Text style={{marginTop:0,marginLeft:0,float:'left'}} className={"form-text-big"}>Banka Bilgileri</Text>
                           <ThemeIcon onClick={() => {
                               QuestionModal({
                                   modals,
                                   title: 'Bankayı Sil',
                                   text: 'Bankayı silmek',
                                   confirm: () => removePanel(index,item.id)
                               });
                           }} style={{float:'right',cursor:'pointer'}} color={'red'} variant={'light'}><IoTrashOutline/></ThemeIcon>


                       </Grid.Col>
                       <Grid.Col lg={12}>
                           <TextInput className={"form-input"} size={"md"}
                                      placeholder={"Hesap Sahibinin Adı Soyadı"}
                                      value={form[index].fullname}
                                      onChange={(event) => setForm.setItemProp(index,'fullname',event.currentTarget.value)}
                           />
                       </Grid.Col>
                       <Grid.Col lg={12}>
                           <TextInput className={"form-input"} size={"md"}
                                      placeholder={"Banka Adı"}
                                      value={form[index].bankTitle}
                                      onChange={(event) => setForm.setItemProp(index,'bankTitle',event.currentTarget.value)}
                           />
                       </Grid.Col>
                       <Grid.Col lg={6}>
                           <TextInput className={"form-input"} size={"md"}
                                      placeholder={"Şube"}
                                      value={form[index].department}
                                      onChange={(event) => setForm.setItemProp(index,'department',event.currentTarget.value)}
                           />
                       </Grid.Col>
                       <Grid.Col lg={6}>
                           <TextInput className={"form-input"} size={"md"}
                                      placeholder={"Şube Kodu"}
                                      value={form[index].departmentCode}
                                      onChange={(event) => setForm.setItemProp(index,'departmentCode',event.currentTarget.value)}
                           />
                       </Grid.Col>
                       <Grid.Col lg={12}>
                           <TextInput className={"form-input"} size={"md"}
                                      placeholder={"Hesap Numarası"}
                                      value={form[index].accountNumber}
                                      onChange={(event) => setForm.setItemProp(index,'accountNumber',event.currentTarget.value)}
                           />
                       </Grid.Col>
                       <Grid.Col lg={12}>
                           <TextInput className={"form-input"} size={"md"}
                                      placeholder={"IBAN"}
                                      component={IMaskInput}
                                      mask="TR 0000 0000 0000 0000"
                                      value={form[index].iban}
                                      onChange={(event) => setForm.setItemProp(index,'iban',event.currentTarget.value)}
                           />
                       </Grid.Col>
                       <Grid.Col lg={6}>
                           <TextInput className={"form-input"} size={"md"}
                                      placeholder={"Swift"}
                                      value={form[index].swift}
                                      onChange={(event) => setForm.setItemProp(index,'swift',event.currentTarget.value)}
                           />
                       </Grid.Col>
                       <Grid.Col lg={6} mb={10}>
                           <TextInput className={"form-input"} size={"md"}
                                      placeholder={"Bic"}
                                      value={form[index].bic}
                                      onChange={(event) => setForm.setItemProp(index,'bic',event.currentTarget.value)}
                           />
                       </Grid.Col>
                       <Grid.Col lg={12}>
                           <Button loading={editIndex === index}  mt={0} color={"violet"} fullWidth style={{height: 43}} onClick={() => formSet(item.id, index)}>Güncellemeyi
                               Kaydet</Button>
                       </Grid.Col>
                   </Grid>
               </Card>
            ))}


           <Group position={'right'}>
               <Button color={'red'} mt={30} style={{height:43}} leftIcon={<IoAddSharp size={17}/>} onClick={() => {
                   setForm.append({
                       id:null,
                       fullname: '',
                       bankTitle:'',
                       department:'',
                       departmentCode:'',
                       accountNumber:'',
                       iban:'',
                       swift:'',
                       bic:''
                   });

               }}>Yeni Ekle</Button>

           </Group>


        </form>)

}


export default BankForm;
