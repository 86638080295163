import { useDisclosure, useDocumentTitle } from "@mantine/hooks";
import {
    Accordion,
    Avatar,
    Button,
    Card,
    Center,
    createStyles,
    Grid,
    Group,
    Image,
    Loader,
    MantineProvider,
    Menu,
    Modal,
    MultiSelect,
    rem,
    Text,
    Title,
    Tooltip
} from '@mantine/core';
import { useEffect, useState } from "react";
import { SlShareAlt } from "react-icons/sl";
import { HiOutlineEnvelope } from "react-icons/hi2";
import { IoChevronDown } from "react-icons/io5";
import { AiOutlineDownload, AiOutlinePhone, AiOutlineQrcode } from "react-icons/ai";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Connect from "../../lib/util/Connect";
import { notifications } from "@mantine/notifications";
import { ErrorMessage } from "../../lib/util/ErrorMessage";
import QRCode from "react-qr-code";
import { shareOnMobile } from "react-mobile-share";
import { SocialIcon } from "../../lib/util/socialcon";
import { SocialLink } from "../../lib/util/socialLink";
import { SocialTitle } from "../../lib/util/SocialTitle";
import { panelIcon } from "../../lib/util/panelIcon";
import { panelType } from "../../lib/util/panelType";
import { API_URL, BASE_URL } from "../../lib/util/constants";
import _ from "lodash";
import { MdDownload } from 'react-icons/md';


const useStyles = createStyles((theme) => ({
    card: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    },

    root: {
        borderRadius: theme.radius.sm,
    },


    item: {
        backgroundColor: '#fff',
        boxShadow: theme.shadows.sm,
        borderRadius: theme.radius.sm,
        position: 'relative',
        zIndex: 0,
        transition: 'transform 150ms ease',
        marginBottom: 30,

        button: {
            lineHeight: 1.5
        },

        '&[data-active]': {
            transform: 'scale(1.03)',
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
            boxShadow: theme.shadows.md,
            borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2],
            borderRadius: theme.radius.md,
            zIndex: 1,
        },
    },

    chevron: {
        '&[data-rotate]': {
            transform: 'rotate(-90deg)',
        },
    },

    avatar: {
        border: `${rem(2)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white}`,
    },
}));


export const HomeSelectProfile = () => {

    useDocumentTitle("HibritCard - HibritMedya");

    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    const [opened, {open, close}] = useDisclosure(false);
    const [pageType,setPageType] = useState('view');
    const [social, setSocial] = useState([]);
    const [members, setMembers] = useState([]);
    const [themeColor, setThemeColor] = useState('light');
    const [users,setUsers] = useState([]);
    const [info, setInfo] = useState({});
    const [mainInfo,setMainInfo] =  useState({});
    const [defaultMail,setDefaultMail] = useState('');
    const [defaultPhone,setDefaultPhone] = useState('');
    const [openProfile, setOpenProfile] = useState(false);
    const [userList, setUserList] = useState([]);
    const [selectedList, setSelectedList] = useState([]);
    const [uuids, setUuids] = useState('');

    const {classes, theme} = useStyles();

    const {id, uid} = useParams();
    const location = useLocation();
    const navigate = useNavigate();


    useEffect(() => {

        getProfile();

    }, []);


    useEffect(() => {

        if (selectedList.length) {

            let arrText = '';

            selectedList.map((item) => {
                arrText = `${arrText}${arrText === '' ? '?' : '&'}uuids[]=${item}`
            });

            setUuids(arrText);


        } else {
            setUuids('');
        }

    }, [selectedList]);





    const getProfile = () => {

        Connect().get(`member/global/${id}`).then(({data}) => {



            if (!data.CREATED_ACCOUNT) {

                let arr = [];

                data.result.MemberProfile.map((item, index) => arr.push({ label: item.profile_tag, value: item.uuid }));

                setUserList(arr);


                if (data.result.member_type === 'Premium'){

                    setPageType('profile');
                    setUsers(data.result.MemberProfile);
                    setPageLoading(false);


                } else{

                    let PROFILE_UUID = null;


                    if (uid === undefined) {
                        setSocial(data.result.MemberProfile[0].url_links);
                        setInfo(data.result.MemberProfile[0]);
                        setThemeColor(data.result.MemberProfile[0].theme === 'Dark' ? 'dark' : 'light');


                        PROFILE_UUID = data.result.MemberProfile[0].uuid;

                        let find = _.find(data.result.MemberProfile[0].MemberProfilePanel,{
                            panel_type:"ContactInformation"
                        });


                        if (find){


                            if (find.MP_ContactInformation.length){

                                setMainInfo(find.MP_ContactInformation[0]);

                                let findMail = _.find(find.MP_ContactInformation[0].MP_ContactInformation_Mail,{
                                    is_default:true
                                });

                                if (findMail){
                                    setDefaultMail(findMail.email);
                                }

                                let findPhone = _.find(find.MP_ContactInformation[0].MP_ContactInformation_Phone,{
                                    is_default:true
                                });

                                if (findPhone){
                                    setDefaultPhone(findPhone.phone_code);
                                }

                            }



                        }


                    } else {

                        // uid

                        let result = _.find(data.result.MemberProfile, {
                            uuid: uid
                        });

                        setSocial(result.url_links);
                        setInfo(result);
                        setThemeColor(result.theme === 'Dark' ? 'dark' : 'light');

                        let find = _.find(result.MemberProfilePanel,{
                            panel_type:"ContactInformation"
                        });


                        if (find){

                            if (find.MP_ContactInformation.length){

                                setMainInfo(find.MP_ContactInformation[0]);


                                let findMail = _.find(find.MP_ContactInformation[0].MP_ContactInformation_Mail,{
                                    is_default:true
                                });

                                if (findMail){
                                    setDefaultMail(findMail.email);
                                }

                                let findPhone = _.find(find.MP_ContactInformation[0].MP_ContactInformation_Phone,{
                                    is_default:true
                                });

                                if (findPhone){
                                    setDefaultPhone(findPhone.phone_code);
                                }

                            }



                        }

                        PROFILE_UUID = uid;

                    }




                    Connect(false).get(`/member/profile/clicked/${PROFILE_UUID}`).then(({data}) => {

                        console.log('Profile Clicked ==> ', PROFILE_UUID, data);

                    });

                    setMembers(data.result.MemberProfile);


                    setPageLoading(false);

                }



            } else {


                navigate(`/welcome/${id}`);

            }


        }).catch((e) => {

            notifications.show({
                title: 'Hata',
                withCloseButton: false,
                message: ErrorMessage(e),
                color: 'red'
            });


        })

    }

    const onChangePanel = (panel_id) => {


        Connect(false).get(`/member/panel/clicked/${panel_id}`).then(({data}) => {

            console.log('Panel Clicked ==> ', panel_id, ' ==> ', data);

        });


    };


    const items = social.map((element) => (
        <div key={`social-${element.id}`}>
            <Tooltip label={SocialTitle(element.param_type)}>
                <Link style={{color: themeColor === 'white' ? '#000' : '#C1C2C5'}}
                      target={"_blank"}
                      to={`${SocialLink(element.param_type)}${element.param}`}>{SocialIcon(element.param_type)}</Link>
            </Tooltip>
        </div>
    ));


    if (pageLoading) {
        return (<div className={"page-full-loading"}>
            <Loader color="violet" size={"xl"} variant="dots"/>
        </div>)
    }

    return (<>
        <MantineProvider theme={{colorScheme: themeColor}} withGlobalStyles withNormalizeCSS>


            <Modal padding={25} size={500} centered opened={openProfile} onClose={() => setOpenProfile(false)}
                   title="Toplu Profil İndirme">

                <MultiSelect
                  label={"Profiller"}
                  placeholder="Profilleri Seç"
                  description={"Seçtiğiniz profilleri 'Toplu Profil İndir' linkine tıklayarak indirebilirsiniz"}
                  searchable
                  data={userList}
                  mb={30}
                  maxDropdownHeight={85}
                  onChange={(val) => setSelectedList(val)}
                />
                <Button disabled={selectedList.length === 0} mb={10} fullWidth rightIcon={<MdDownload size={15}/>}
                        loading={loading} color={"violet"} mt={28} component={"a"} href={`${BASE_URL}/member/global/export/vcard/selecteds${uuids}`} download onClick={()=> {
                            setLoading(true);
                            setTimeout(() => {
                                setLoading(false);
                            },3000);
                }} >Toplu Profil
                    İndir</Button>
            </Modal>


            {pageType === 'view' &&
                <div>
                    <Modal centered size={350} opened={opened} onClose={close} title="QR Kodu">
                        <Center mt={20} mb={26}>
                            <QRCode size={130} value={`${window.location.href}`}/>
                        </Center>
                    </Modal>


                    <div id="profile-top" style={{
                        backgroundImage: `url(${info?.img_cover_s3_url})`,
                        position: 'relative',
                        backgroundColor: themeColor === 'dark' ? '' : '#1a1b1e'
                    }}>

                        <Menu position={"bottom-start"} shadow="md" width={200}>
                            <Menu.Target>
                                <Button color={"dark"}
                                        className={themeColor === 'light' ? "menu-select-btn" : "menu-select-btn dark-btn"}
                                        rightIcon={
                                            <IoChevronDown/>}>{info?.profile_tag ?? info.profile_firstname + ' ' + info.profile_lastname}</Button>
                            </Menu.Target>

                            <Menu.Dropdown>
                                {members.map((item, index) => (
                                    <Menu.Item component={"a"} href={`/id/${id}/${item.uuid}`} key={`member-${index}`}
                                               icon={<Avatar size={35} radius={"xl"}
                                                             src={item.img_pp_s3_url}/>}>

                                        {item.profile_tag ?? item.profile_firstname + ' ' + item.profile_lastname}</Menu.Item>
                                ))}
                            </Menu.Dropdown>
                        </Menu>

                        <Center className={"home-card-mobile"}>
                            <Card style={{
                                width: 520,
                                backgroundColor: themeColor === 'dark' ? theme.colors.dark[8] : theme.white
                            }} shadow={"md"} padding="xl" radius="md" className={classes.card}>
                                <Avatar
                                    src={info?.img_pp_s3_url}
                                    size={80} radius={80} mx="auto" mt={20}
                                    style={{borderColor: themeColor === 'dark' ? theme.colors.dark[2] : theme.white}}
                                    className={classes.avatar}/>
                                <Text ta="center" fz="lg" fw={500} mt="sm">
                                    {info?.profile_firstname} {info?.profile_lastname}
                                </Text>
                                <Text ta="center" fz="sm" c="dimmed">
                                    {info?.profile_business_name}
                                </Text>
                                <Text mt={6} ta="center" fz="xs" c="dimmed" className={"detail-item"}>
                                    {defaultPhone !== '' &&
                                        <span>
                                    +{defaultPhone}
                              </span>
                                    }

                                    {defaultMail !== '' &&
                                        <span>
                                  {defaultMail}
                                </span>
                                    }

                                    {mainInfo?.position &&
                                        <span>
                                     {mainInfo?.position}
                                </span>
                                    }

                                </Text>
                                <Group mt="md" position="center" spacing={23}>
                                    {items}
                                </Group>
                                <Button
                                    mb={5}
                                    fullWidth
                                    radius="md"
                                    mt="xl"
                                    leftIcon={<SlShareAlt size={15}/>}
                                    onClick={() => {
                                        shareOnMobile({
                                                text: "HibritCard - HibritMedya",
                                                url: window.location.href,
                                                title: "HibritMedya",
                                            },
                                            (message) => console.log(message)
                                        );
                                    }}
                                    style={{backgroundColor: themeColor === 'dark' ? theme.colors.violet[8] : theme.colors.violet}}
                                    color={'violet'}>
                                    Paylaş
                                </Button>

                                <Grid gutter={"sm"} mt={22} mb={-3}>
                                    <Grid.Col span={3} lg={3}>
                                        <Button component={Link} to={`tel:+${info?.phone_default?.phone_code}`}
                                                className={"default-btn share-btn-small"} fullWidth
                                                leftIcon={<AiOutlinePhone style={{transform: 'rotate(90deg)'}}/>}>
                                            Ara
                                        </Button>
                                    </Grid.Col>
                                    <Grid.Col span={3} lg={3}>
                                        <Button component={Link} to={`mailto:${info?.mail_default?.email}`}
                                                className={"default-btn share-btn-small"} fullWidth
                                                leftIcon={<HiOutlineEnvelope/>}>
                                            Mail
                                        </Button>
                                    </Grid.Col>
                                    <Grid.Col span={3} lg={3}>
                                        <Button
                                            onClick={() => open()}
                                            className={"default-btn share-btn-small"} fullWidth
                                            leftIcon={<AiOutlineQrcode/>}>
                                            QR
                                        </Button>
                                    </Grid.Col>
                                    <Grid.Col span={3} lg={3}>
                                        <Button component={Link} to={`${BASE_URL}/member/global/${info?.uuid}/export/vcard`}
                                                className={"default-btn share-btn-small"} fullWidth
                                                leftIcon={<AiOutlineDownload/>}>
                                            Kaydet
                                        </Button>
                                    </Grid.Col>
                                </Grid>

                            </Card>
                        </Center>

                    </div>


                    <div className={"panel-list"}
                         style={{width: 520, margin: info?.hide_logo ? '0 auto 70px auto' : '0 auto 40px auto'}}>
                        <Accordion onChange={(value) => onChangePanel(value)} classNames={classes} className={classes.root}>

                            {info?.MemberProfilePanel.map((item) => (

                                <Accordion.Item style={{
                                    backgroundColor: themeColor === 'dark' ? theme.colors.dark[8] : theme.colors.wgite,
                                    borderColor: ''
                                }} key={`panel_${item.id}`} value={`${item.id}`}>
                                    <Accordion.Control icon={panelIcon(item.panel_type)}>{item.title}</Accordion.Control>
                                    <Accordion.Panel>
                                        {panelType(item.panel_type, item[`MP_${item.panel_type}`], themeColor, info?.uuid)}
                                    </Accordion.Panel>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </div>

                    {!info?.hide_logo &&
                        <Center style={{marginTop: 20, marginBottom: 40}}>
                            <Image alt={'HibritCard'} width={120} src={'/img/white-logo.svg'}/>
                        </Center>
                    }
                </div>
            }

            {pageType === 'profile' &&
            <div>
                <div id="select-profile">


                    <Center mt={100}>
                        <Image src={'/img/white-logo.svg'} width={220}/>
                    </Center>


                    <div className="d-table">
                        <div className="table-center">

                            <Center mb={30} mt={20}>
                                <Title order={2} color={"white"}>Profil Seç</Title>
                            </Center>
                            <Center>
                                <Group spacing={30} position={"center"}>
                                    {users.map((item, index) => (
                                        <div style={{position:'relative'}}>
                                            <Link to={`/id/${id}/${item.uuid}`} className={'text-center avatar-item'} >
                                                <Avatar radius={80} size={80}
                                                        src={item.img_pp_s3_url}
                                                        alt={item.profile_tag}/>
                                                <Text>{item.profile_tag}</Text>
                                            </Link>
                                        </div>
                                    ))}


                                </Group>
                            </Center>

                            <Center>
                                <Button rightIcon={<MdDownload size={15}/>} variant={"white"} color={"dark"} mt={28}
                                        onClick={() => setOpenProfile(true)}>Toplu Profil İndir</Button>
                            </Center>
                        </div>
                    </div>


                </div>
            </div>
            }

        </MantineProvider>
    </>)

}


export default HomeSelectProfile
