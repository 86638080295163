import {Text, Button} from '@mantine/core';
import {IoCheckmarkCircleOutline} from "react-icons/io5";
import React from "react";



export const SuccessViewing = async (message, modals, title = null) => {



    modals.openConfirmModal({
        title: '',
        hideCloseButton: true,
        centered: true,
        className:"confirm-modal",
        children: (<div className={"text-center"} style={{paddingBottom: 15, paddingTop: 13}}>
            <IoCheckmarkCircleOutline style={{marginLeft: -2, opacity: 0.9}} color={"#12b886"} size={73}/>
            <Text color={"gray"} style={{marginTop: 1, fontSize: '28px'}} weight={500}>{title !== null ? title : 'Başarılı' }</Text>
            <Text color={"gray"} style={{marginTop: 3}} size="md">{message}</Text>

        </div>),


    });

    setTimeout(() => {
        modals.closeAll();
    },700);


}




