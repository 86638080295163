import {useDisclosure, useDocumentTitle} from "@mantine/hooks";
import {
    Accordion,
    Avatar,
    Button,
    Card,
    Center,
    createStyles,
    Grid,
    Group,
    Image,
    Loader,
    MantineProvider,
    Modal,
    rem,
    Text,
    ThemeIcon,
    Tooltip
} from '@mantine/core';
import {useEffect, useState} from "react";
import {SlShareAlt} from "react-icons/sl";
import {HiOutlineEnvelope} from "react-icons/hi2";
import {IoClose} from "react-icons/io5";
import {AiOutlineDownload, AiOutlinePhone, AiOutlineQrcode} from "react-icons/ai";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Connect from "../../lib/util/Connect";
import {notifications} from "@mantine/notifications";
import {ErrorMessage} from "../../lib/util/ErrorMessage";
import QRCode from "react-qr-code";
import {shareOnMobile} from "react-mobile-share";
import {SocialIcon} from "../../lib/util/socialcon";
import {SocialLink} from "../../lib/util/socialLink";
import {SocialTitle} from "../../lib/util/SocialTitle";
import {panelIcon} from "../../lib/util/panelIcon";
import {BASE_URL} from "../../lib/util/constants";
import _ from "lodash";
import {Base64} from 'js-base64';
import {panelType} from "../../lib/util/panelType";
import {getPreview} from "../../lib/util/auth";
import { history } from '../../history';


const useStyles = createStyles((theme) => ({
    card: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    },

    root: {
        borderRadius: theme.radius.sm,
    },


    item: {
        backgroundColor: '#fff',
        boxShadow: theme.shadows.sm,
        borderRadius: theme.radius.sm,
        position: 'relative',
        zIndex: 0,
        transition: 'transform 150ms ease',
        marginBottom: 30,

        button: {
            lineHeight: 1.5
        },

        '&[data-active]': {
            transform: 'scale(1.03)',
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
            boxShadow: theme.shadows.md,
            borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2],
            borderRadius: theme.radius.md,
            zIndex: 1,
        },
    },

    chevron: {
        '&[data-rotate]': {
            transform: 'rotate(-90deg)',
        },
    },

    avatar: {
        border: `${rem(2)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white}`,
    },
}));


export const HomeProfile = () => {

    useDocumentTitle("Profil Önizleme");

    const navigate = useNavigate();


    const [pageLoading, setPageLoading] = useState(true);
    const [opened, {open, close}] = useDisclosure(false);
    const [social, setSocial] = useState([]);
    const [themeColor, setThemeColor] = useState('light');
    const [info, setInfo] = useState({});
    const [userInfo, setUserInfo] = useState({});
    const [previewStatus, setPreviewStatus] = useState(false);
    const [mainInfo,setMainInfo] =  useState({});
    const [defaultMail,setDefaultMail] = useState('');
    const [defaultPhone,setDefaultPhone] = useState('');

    const {classes, theme} = useStyles();

    const {id} = useParams();
    const location = useLocation();


    useEffect(() => {


        if (getPreview() !== undefined) {

            let data = JSON.parse(Base64.decode(getPreview()));


            setSocial(data.url_links);
            setThemeColor(data.theme === 'Dark' ? 'dark' : 'light')

            setUserInfo(data);



            getProfile(data.uuid, data);

        }


    }, []);


    const getProfile = (Id = '', baseData) => {


        Connect().get(`member/preview/${Id}`).then(({data}) => {

            let result = _.find(data.result.MemberProfile, {
                uuid: Id
            });


            if (baseData?.MemberProfile) {


                let find = _.find(baseData.MemberProfile,{
                    panel_type:"ContactInformation"
                });



                if (find){

                    if (find.MP_ContactInformation[0] !== undefined){

                        setMainInfo(find.MP_ContactInformation[0]);


                        let findMail = _.find(find.MP_ContactInformation[0].MP_ContactInformation_Mail,{
                            is_default:true
                        });

                        if (findMail){
                            setDefaultMail(findMail.email);
                        }

                        let findPhone = _.find(find.MP_ContactInformation[0].MP_ContactInformation_Phone,{
                            is_default:true
                        });

                        if (findPhone){
                            setDefaultPhone(findPhone.phone_code);
                        }


                    } else{

                        setMainInfo(find.MP_ContactInformation);


                        let findMail = _.find(find.MP_ContactInformation.MP_ContactInformation_Mail,{
                            is_default:true
                        });

                        if (findMail){
                            setDefaultMail(findMail.email);
                        }

                        let findPhone = _.find(find.MP_ContactInformation.MP_ContactInformation_Phone,{
                            is_default:true
                        });

                        if (findPhone){
                            setDefaultPhone(findPhone.phone_code);
                        }

                    }

                }



                setInfo(baseData);
                setPreviewStatus(true);

            } else {
                setInfo(result);


                let find = _.find(result?.MemberProfilePanel,{
                    panel_type:"ContactInformation"
                });


                if (find){

                    if (find.MP_ContactInformation.length){

                        setMainInfo(find.MP_ContactInformation[0]);

                        let findMail = _.find(find.MP_ContactInformation[0].MP_ContactInformation_Mail,{
                            is_default:true
                        });

                        if (findMail){
                            setDefaultMail(findMail.email);
                        }

                        let findPhone = _.find(find.MP_ContactInformation[0].MP_ContactInformation_Phone,{
                            is_default:true
                        });

                        if (findPhone){
                            setDefaultPhone(findPhone.phone_code);
                        }

                    }



                }





            }


            setTimeout(() => {
                setPageLoading(false);
            }, 100);

        }).catch((e) => {
            notifications.show({
                title: 'Hata',
                withCloseButton: false,
                message: ErrorMessage(e),
                color: 'red'
            });
        })


    }


    const items = social.map((element) => {

        if (element.status && !element.is_deleted) {

            return (<div key={`social-${element.id}`}>
                <Tooltip label={SocialTitle(element.param_type)}>
                    <Link style={{color: themeColor === 'white' ? '#000' : '#C1C2C5'}}
                          target={"_blank"}
                          to={`${SocialLink(element.param_type)}${element.param}`}>{SocialIcon(element.param_type)}</Link>
                </Tooltip>
            </div>)


        }

    });


    if (pageLoading) {
        return (<div className={"page-full-loading"}>
            <Loader color="violet" size={"xl"} variant="dots"/>
        </div>)
    }

    return (<>
        <MantineProvider theme={{colorScheme: themeColor}} withGlobalStyles withNormalizeCSS>


            <Modal centered size={350} opened={opened} onClose={close} title="QR Kodu">
                <Center mt={20} mb={26}>
                    <QRCode size={130} value={`${window.location.href.replace('profile','id')}`}/>
                </Center>
            </Modal>


            <div id="profile-top" style={{backgroundImage: `url(${userInfo?.img_cover_s3_url})`, position: 'relative'}}>
                <Center className={"home-card-mobile"}>
                    <Card style={{
                        width: 520,
                        backgroundColor: themeColor === 'dark' ? theme.colors.dark[8] : theme.white
                    }} shadow={"md"} padding="xl" radius="md" className={classes.card}>
                        <Avatar
                            src={userInfo?.img_pp_s3_url}
                            size={80} radius={80} mx="auto" mt={20}
                            style={{borderColor: themeColor === 'dark' ? theme.colors.dark[2] : theme.white}}
                            className={classes.avatar}/>
                        <Text ta="center" fz="lg" fw={500} mt="sm">
                            {userInfo?.profile_firstname} {userInfo?.profile_lastname}
                        </Text>
                        <Text ta="center" fz="sm" c="dimmed">
                            {userInfo?.profile_business_name}
                        </Text>

                        <Text mt={6} ta="center" fz="xs" c="dimmed" className={"detail-item"}>
                            {defaultPhone !== '' &&
                              <span>
                                    +{defaultPhone}
                              </span>
                            }

                            {defaultMail !== '' &&
                                <span>
                                  {defaultMail}
                                </span>
                            }



                            {mainInfo?.position &&
                                <span>
                                     {mainInfo?.position}
                                </span>
                            }

                        </Text>


                        {userInfo?.icon_location === 'Top' &&
                            <Group mt="md" position="center" spacing={23}>
                                {items}
                            </Group>
                        }
                        <Button
                            mb={5}
                            fullWidth
                            radius="md"
                            mt="xl"
                            leftIcon={<SlShareAlt size={15}/>}
                            onClick={() => {
                                shareOnMobile({
                                        text: "HibritCard - HibritMedya",
                                        url: window.location.href,
                                        title: "HibritMedya",
                                    },
                                    (message) => console.log(message)
                                );
                            }}
                            style={{backgroundColor: themeColor === 'dark' ? theme.colors.violet[8] : theme.colors.violet}}
                            color={'violet'}>
                            Paylaş
                        </Button>

                        <Grid gutter={"sm"} mt={22} mb={-3}>
                            <Grid.Col span={3} lg={3}>
                                <Button component={Link} to={`tel:+${defaultPhone}`}
                                        className={"default-btn share-btn-small"} fullWidth
                                        leftIcon={<AiOutlinePhone style={{transform: 'rotate(90deg)'}}/>}>
                                    Ara
                                </Button>
                            </Grid.Col>
                            <Grid.Col span={3} lg={3}>
                                <Button component={Link} to={`mailto:${defaultMail}`}
                                        className={"default-btn share-btn-small"} fullWidth
                                        leftIcon={<HiOutlineEnvelope/>}>
                                    Mail
                                </Button>
                            </Grid.Col>
                            <Grid.Col span={3} lg={3}>
                                <Button
                                    onClick={() => open()}
                                    className={"default-btn share-btn-small"} fullWidth
                                    leftIcon={<AiOutlineQrcode/>}>
                                    QR
                                </Button>
                            </Grid.Col>
                            <Grid.Col span={3} lg={3}>
                                <Button component={Link} to={`${BASE_URL}/member/global/${userInfo?.uuid}/export/vcard`}
                                        className={"default-btn share-btn-small"} fullWidth
                                        leftIcon={<AiOutlineDownload/>}>
                                    Kaydet
                                </Button>
                            </Grid.Col>
                        </Grid>

                    </Card>
                </Center>
            </div>


            <div className={"panel-list"}
                 style={{width: 520, margin: userInfo?.hide_logo ? '0 auto 70px auto' : '0 auto 40px auto'}}>
                <Accordion classNames={classes} className={classes.root}>


                    {!previewStatus &&
                        <>
                            {info?.MemberProfilePanel.map((item) => {

                                if (item.status) {

                                    return (<Accordion.Item style={{
                                        backgroundColor: themeColor === 'dark' ? theme.colors.dark[8] : theme.colors.wgite,
                                        borderColor: ''
                                    }} key={`panel_${item.id}`} value={`panel_${item.id}`}>
                                        <Accordion.Control
                                            icon={panelIcon(item.panel_type)}>{item.title}</Accordion.Control>
                                        <Accordion.Panel>
                                            {panelType(item.panel_type, item[`MP_${item.panel_type}`], themeColor, info?.uuid)}
                                        </Accordion.Panel>
                                    </Accordion.Item>)

                                }
                            })}
                        </>
                    }

                    {previewStatus &&
                        <>
                            {info?.MemberProfile.map((item) => {


                                if (item.status) {

                                    return (<Accordion.Item style={{
                                        backgroundColor: themeColor === 'dark' ? theme.colors.dark[8] : theme.colors.wgite,
                                        borderColor: ''
                                    }} key={`panel_${item.id}`} value={`panel_${item.id}`}>
                                        <Accordion.Control
                                            icon={panelIcon(item.panel_type)}>{item.title}</Accordion.Control>
                                        <Accordion.Panel>
                                            {panelType(item.panel_type, item[`MP_${item.panel_type}`], themeColor, info?.uuid)}
                                        </Accordion.Panel>
                                    </Accordion.Item>)

                                }
                            })}
                        </>
                    }


                </Accordion>
            </div>

            {userInfo?.icon_location === 'Bottom' &&
                <Group mt="md" position="center" spacing={23} mb={30}>
                    {items}
                </Group>
            }


            {!userInfo?.hide_logo &&
                <Center style={{marginTop: 20, marginBottom: 40}}>
                    <Image alt={'HibritCard'} width={120} src={'/img/white-logo.svg'}/>
                </Center>
            }


            <div className="fixed-btn" onClick={() => navigate(-1)}>
                    <ThemeIcon color={"dark"} size={40} radius={40} style={{cursor: 'pointer'}}>
                        <IoClose size={15}/>
                    </ThemeIcon>

            </div>




        </MantineProvider>
    </>)

}


export default HomeProfile
