
import {Button, CopyButton, Divider, List, ThemeIcon} from "@mantine/core";
import {AiOutlineBank, AiOutlineGlobal, AiOutlineUser} from "react-icons/ai";
import {SlLocationPin} from "react-icons/sl";
import {IoCardOutline, IoCopy, IoDownload, IoWalletOutline} from "react-icons/io5";
import {Link} from "react-router-dom";


export const DocumentPanel = ({data}) => {



    return (<>

        {data.map((item,index) => (
            <div className="item-row-item" key={`document-${index}`}>
                <Button component={Link}  to={item.file_s3_url} fullWidth mt={20} target={"_blank"}  color={"violet"}
                         leftIcon={<IoDownload/>}>{item.file_meta_name} </Button>

                <Divider variant={"dashed"} mt={30} mb={-10}/>
            </div>
        ))}

    </>)

}

export default DocumentPanel;
