import {Button, Divider, Textarea, TextInput,Text} from "@mantine/core";
import {IoAdd, IoCopy} from "react-icons/io5";
import {useEffect, useState} from "react";
import {useForm} from "@mantine/form";
import {notifications} from "@mantine/notifications";
import Connect from "../../../util/Connect";
import {ErrorViewing} from "../../../util/ErrorViewing";
import {useModals} from "@mantine/modals";
import {connect} from "react-redux";
import withRouter from "../../../util/withRouter";
import {Navbar} from "../../Nav";


export const MemberPanel = ({data,theme,user}) => {

    const [loading,setLoading] = useState(true);
    const [btnLoading,setBtnLoading] = useState(false);
    const modals = useModals();

   // console.log('data',data);

    const form = useForm({
        initialValues: {
            email: '',
            fullname:'',
            phone:'',
            message:'',
        },

        validate: {
            email: (value) => data.is_email ? (/^\S+@\S+$/.test(value) ? null : 'Geçersiz mail adresi') : null,
            fullname: (value) => data.is_full_name ? (value !== '' ? null : 'Ad Soyad girmelisiniz') : null,
            phone: (value) => data.is_email ?(value !== '' ? null : 'Telefon girmelisiniz') : null,
            message: (value) => data.is_message_box ? (value !== '' ? null : 'Mesaj girmelisiniz') : null,
        },
    });






    useEffect(() => {


        if (data !== undefined) {
            setLoading(false);
        }

    }, [data]);


    if (loading) {
        return (<></>)
    }



    const setForm  = (values) => {

        setBtnLoading(true);

        Connect().post(`member/send/mail/${user?.id}/${data?.id}`,{
            fullname: values.fullname,
            email: values.email,
            phone: values.phone,
            message: values.message
        }).then(({data}) =>{

            notifications.show({
                title: 'Başarılı',
                withCloseButton:false,
                message: 'Mesajınız Gönderildi',
                color: 'teal'
            });

            form.setValues({
                email: '',
                fullname:'',
                phone:'',
                message:'',
            });

        }).catch((e) => ErrorViewing(e,modals)).finally(() => setBtnLoading(false));

    }



    return (<>
        <div className="item-row-item">

            <form onSubmit={form.onSubmit((values) => setForm(values))}>


                {(data?.sender_content !== '' && data?.sender_content !== null) &&
                    <Text mb={12} mt={5} align={"center"}>{data?.sender_content}</Text>
                }



                {data?.is_full_name &&
                    <TextInput
                        label="Ad Soyad"
                        className={theme === 'light' ? "form-input" : "form-input dark-input"}
                        mb={15}
                        placeholder="Ad Soyad"
                        {...form.getInputProps('fullname')}
                    />
                }


                {data?.is_email &&
                    <TextInput
                        label="Email"
                        mb={15}
                        className={theme === 'light' ? "form-input" : "form-input dark-input"}
                        placeholder="ornek@email.com"
                        {...form.getInputProps('email')}
                    />
                }


                {data?.is_phone &&
                    <TextInput
                        label="Telefon Numarası"
                        className={theme === 'light' ? "form-input" : "form-input dark-input"}
                        mb={15}
                        placeholder="Telefon"
                        {...form.getInputProps('phone')}
                    />
                }

                {data?.is_message_box &&
                    <Textarea
                        label="Mesaj"
                        className={theme === 'light' ? "form-input" : "form-input dark-input"}
                        placeholder="Mesajınız"
                        {...form.getInputProps('message')}
                    />
                }


                {data?.id !== null &&
                    <Button style={{height:40}} type={"submit"} loading={btnLoading} fullWidth mt={25}  radius={6}  color={"violet"}>Gönder</Button>
                }


            </form>
        </div>
    </>)

}


const mapProps = (state) => ({
    user:state.user.info
});


export default connect(mapProps)(withRouter(MemberPanel));
