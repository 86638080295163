import {Card, Container, Grid, Select, Text, Title} from "@mantine/core";
import {useDocumentTitle} from "@mantine/hooks";
import Navbar from "../../lib/components/Nav";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";


export const MemberShipPage = () => {

    useDocumentTitle('Abonelik')
    const {id} = useParams();


    useEffect(() => {


    }, []);


    return (<>

        <Navbar menu={'analysis'} id={id}/>


        <Container size={1800}>
            <Grid>
                <Grid.Col lg={12}>
                    <Card mt={30} withBorder shadow={"md"} padding={30} radius="md">
                        <Title mb={2} order={2} className={"table-title-item"}>Abonelik</Title>
                        <Text size="xs" color="dimmed" mb={15}>
                            Abonelik bilgileri
                        </Text>


                    </Card>
                </Grid.Col>
            </Grid>

        </Container>

    </>)

}
export default MemberShipPage;
