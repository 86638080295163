import {Button, Grid, Switch, Text, Textarea, TextInput} from "@mantine/core";
import {useForm} from "@mantine/form";
import {useEffect, useState} from "react";
import Connect from "../../util/Connect";
import {SuccessViewing} from "../../util/SuccessViewing";
import {modals} from "@mantine/modals";
import {ErrorViewing} from "../../util/ErrorViewing";


export const MemberForm = ({info,onResult,onItem}) => {

    const [infoItem,setInfoItem] = useState(null);
    const [loading,setLoading] = useState(false);
    const [pageLoading,setPageLoading] = useState(true);

    const [blankData, setBlankData] = useState({
        id: null,
        member_panel_id: 9999999,
        is_full_name: true,
        is_email: true,
        is_phone: true,
        is_message_box: true,
        sender_mail: "alpcesur19@gmail.com",
        sender_content: "açıklama",
        is_deleted: false,
        status: true,
        created_at: "2023-06-28T15:43:47.586Z",
        updated_at: "2023-06-28T15:43:47.586Z"
    });

    const form = useForm({
        initialValues: {
            nameStatus: true,
            emailStatus: true,
            phoneStatus: true,
            messageStatus: true,
            email: '',
            contact: '',
        },

        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Geçersiz mail adresi'),
            contact: (value) => (value !== '' ? null : 'Açıklama girmelisiniz'),
        },
    });


    useEffect(()=>{


        if (info.MP_Customer_Form.length){


            let _info =  info.MP_Customer_Form[0];

            setInfoItem(_info);


            form.setValues({
                nameStatus: _info.is_full_name,
                emailStatus: _info.is_email,
                phoneStatus: _info.is_phone,
                messageStatus: _info.is_message_box,
                email: _info.sender_mail,
                contact: _info.sender_content,
            });

        }


        setTimeout(()=>{
            setPageLoading(false);
        },150);

    },[]);





    const addMember = (values) => {

        setLoading(true);

        Connect().post(infoItem !== null  ? `panel/item/Customer_Form/edit/${infoItem.id}` :  `panel/item/Customer_Form/${info.id}/add`,{
            is_full_name: values.nameStatus,
            is_email: values.emailStatus,
            is_phone: values.phoneStatus,
            is_message_box: values.messageStatus,
            sender_mail: values.email,
            sender_content: values.contact
        }).then(({data}) =>{

             if (infoItem === null){
                  setInfoItem(data.result);
              }

            onResult();

            SuccessViewing('Güncelleme Başarılı',modals);

        }).catch((e) => ErrorViewing(e,modals)).finally(()=> setLoading(false));


    }


    useEffect(()=>{




        if (!pageLoading){



            if (infoItem !== null){


                infoItem.is_full_name = form.values.nameStatus;
                infoItem.is_email = form.values.emailStatus;
                infoItem.is_phone = form.values.phoneStatus;
                infoItem.is_message_box = form.values.messageStatus;
                infoItem.sender_mail = form.values.email;
                infoItem.sender_content = form.values.contact;


                onItem(infoItem);
            } else{


                let data = {...blankData};

                data.is_full_name = form.values.nameStatus;
                data.is_email = form.values.emailStatus;
                data.is_phone = form.values.phoneStatus;
                data.is_message_box = form.values.messageStatus;
                data.sender_mail = form.values.email;
                data.sender_content = form.values.contact;

                setBlankData(data);
                onItem(data, true);

            }

        }




    },[form.values]);



    return (
        <form onSubmit={form.onSubmit((values) => addMember(values))}>
            <Grid gutter={25}>



                <Grid.Col lg={12}>
                    <Text style={{float: 'left'}} className={"form-label"}>İsim Soyisim</Text>
                    <div style={{float: 'right'}}>
                        <Switch size={"lg"} color={'teal'}{...form.getInputProps('nameStatus', {type: 'checkbox'})}/>
                    </div>
                </Grid.Col>

                <Grid.Col lg={12}>
                    <Text style={{float: 'left'}} className={"form-label"}>Email</Text>
                    <div style={{float: 'right'}}>
                        <Switch size={"lg"} color={'teal'}{...form.getInputProps('emailStatus', {type: 'checkbox'})}/>
                    </div>
                </Grid.Col>

                <Grid.Col lg={12}>
                    <Text style={{float: 'left'}} className={"form-label"}>Telefon</Text>
                    <div style={{float: 'right'}}>
                        <Switch size={"lg"} color={'teal'}{...form.getInputProps('phoneStatus', {type: 'checkbox'})}/>
                    </div>
                </Grid.Col>

                <Grid.Col lg={12}>
                    <Text style={{float: 'left'}} className={"form-label"}>Mesaj Alanı</Text>
                    <div style={{float: 'right'}}>
                        <Switch size={"lg"} color={'teal'}{...form.getInputProps('messageStatus', {type: 'checkbox'})}/>
                    </div>
                </Grid.Col>


                <Text className={"form-text-big"}>Mesaj Nereye İletilsin?</Text>

                <Grid.Col lg={12}>
                    <TextInput className={"form-input"} size={"md"} label={"Mail Adresiniz"}
                               placeholder={"placeholder@mail.com"}
                               {...form.getInputProps('email')}
                    />
                </Grid.Col>

                <Grid.Col lg={12}>
                    <Textarea className={"form-input"} size={"md"} label={"Açıklama Yazınız"} placeholder={"Açıklama"}
                              {...form.getInputProps('contact')}
                    />
                </Grid.Col>
                <Grid.Col lg={12}>
                    <Button loading={loading} color={"violet"} fullWidth style={{height: 43}} mt={5} type={"submit"}>Güncellemeyi Kaydet</Button>
                </Grid.Col>

            </Grid>
        </form>)

}


export default MemberForm;
