import {
    Button,
    Card,
    Center,
    Checkbox,
    Grid,
    Group,
    Image,
    PasswordInput,
    PinInput,
    Text,
    TextInput,
    Title,
    useMantineTheme
} from "@mantine/core";
import {useEffect, useState} from "react";
import {useDocumentTitle} from "@mantine/hooks";
import {useModals} from "@mantine/modals";
import {useForm} from "@mantine/form";
import {Link, useNavigate} from "react-router-dom";
import Connect from "../../../lib/util/Connect";
import {getSession, setSession} from "../../../lib/util/auth";
import {getMember, setMember} from "../../../lib/util/user";
import {notifications} from "@mantine/notifications";
import {ErrorMessage} from "../../../lib/util/ErrorMessage";


export const Login = () => {


    useDocumentTitle('HibritCard - HibritMedya');

    const theme = useMantineTheme();
    const [loading, setLoading] = useState(false);
    const [codeStatus, setCodeStatus] = useState(false);
    const [codeLoading,setCodeLoading] = useState(false);
    const [token, setToken] = useState('');
    const [userData, setUserData] = useState({});
    const modals = useModals();
    let navigate = useNavigate();
    const [code,setCode] = useState('');


    useEffect(() => {

        let token = getSession();

        if (token !== undefined) {
            navigate('/select-profile');
        }


        if (getMember()) {
            form.setFieldValue('termsOfService', true);
        }


    },[]);


    const form = useForm({
        initialValues: {
            email: '',
            password: '',
            termsOfService: false
        },

        validate: {
            password: (value) => ( value  !== ''  ? null : 'Şifre girmelisiniz.'),
            email: (value) => (value !== '' ? null : 'E-posta veya kullanıcı adı girmelisiniz'),
        },
    });



    const setLogin = (values) => {

        setLoading(true);

        Connect().post('member/login', {
            email: values.email,
            password: values.password
        }).then(({data}) => {


            setToken(data.result.token);
            setSession(data.result.token);


            if (data.result.payload.is_completed) {



                if (values.termsOfService) {
                    setMember();
                }

                window.location.href = '/select-profile';


            } else {

                if (values.termsOfService) {
                    setMember();
                }

                setCodeStatus(true);

            }


        }).catch((e) => {
            notifications.show({
                title: 'Hata',
                withCloseButton:false,
                message: ErrorMessage(e),
                color:'red'
            });
        }).finally(() => setLoading(false));

    }

    const setPinCode = () => {



        if (code.length === 6){
            setCodeLoading(true);


            Connect().post('member/verification/code',{
                verification_code:code
            }).then(({data}) =>{


                window.location.href = '/select-profile';


            }).catch((e) => {
                notifications.show({
                    title: 'Hata',
                    withCloseButton:false,
                    message: ErrorMessage(e),
                    color:'red'
                });
            }).finally(()=>{
                setCodeLoading(false);
            });
        } else{

            notifications.show({
                title: 'Hata',
                withCloseButton:false,
                message: 'Kodu eksiksiz girmelisiniz!',
                color:'red'
            });

        }


    }


    return(<>

        <div className={"userPage"}>
            <Grid gutter={0} className={"h-100"}>
                <Grid.Col lg={5} className={"user-left"}>
                  <Center className={"h-100"}>
                      <Image className={"user-img"} src={'/img/white-logo.svg'} fit={"contain"} width={500}/>
                  </Center>
                </Grid.Col>
                <Grid.Col lg={7}>


                    <div className="table">
                        <div className="table-cell">
                            <Card className={"default-card login-card"} p={40} radius={"md"} shadow={"md"}
                                  style={{width: 550, margin: '0 auto'}}>

                                {!codeStatus &&
                                    <>
                                        <Title order={2}>Giriş Yap</Title>
                                        <Text className={"text"}>Kullanıcı bilgilerinizi girerek sisteme giriş
                                            yapabilirsiniz.</Text>

                                        <form onSubmit={form.onSubmit((values) => setLogin(values))}>
                                            <TextInput
                                                label="E-posta veya Kullanıcı Adı"
                                                placeholder="example@ornek.com"
                                                className={"form-input"}
                                                mt={13}
                                                {...form.getInputProps('email')}
                                            />

                                            <PasswordInput
                                                label="Şifre"
                                                placeholder="Şifreniz"
                                                className={"form-input"}
                                                mt={23}
                                                {...form.getInputProps('password')}
                                            />

                                            <Grid mt={3}>

                                                <Grid.Col lg={7} span={6}>
                                                    <Checkbox
                                                        className={"checkbox-left"}
                                                        mt="md"
                                                        color={"violet"}
                                                        size={"sm"}
                                                        label="Beni Hatırla"
                                                        {...form.getInputProps('termsOfService', {type: 'checkbox'})}
                                                    />
                                                </Grid.Col>

                                                <Grid.Col lg={5} span={6}>
                                                    <Group position={'right'}>
                                                        <Link className={"forgot-btn"} to={'/forgot-password'}>Şifremi
                                                            Unuttum</Link>
                                                    </Group>
                                                </Grid.Col>

                                            </Grid>

                                            <Group grow mt="xl">
                                                <Button loading={loading} className={"active-btn"} color={"violet"}
                                                        type="submit">Giriş Yap</Button>
                                                <Button component={Link} to={'/register'} className={"default-btn"}>Kayıt
                                                    Ol</Button>
                                            </Group>
                                        </form>
                                    </>
                                }

                                {codeStatus &&
                                    <>
                                        <Title order={2}>Kartınızı Doğrulayın</Title>
                                        <Text mb={20} className={"text"}>Lütfen kartınız ile birlikte gelen 6 haneli
                                            doğrulama kodunu giriniz.</Text>


                                        <Group mt={30} position={"center"}>
                                            <PinInput size={"md"} length={6}
                                                      onChange={(val) => setCode(val)}
                                            />
                                        </Group>

                                        <Button
                                            fullWidth mt={30}
                                            loading={codeLoading}
                                            onClick={() => setPinCode()}
                                            className={"active-btn"} color={"violet"}>Doğrulama ve devam et</Button>


                                    </>
                                }

                            </Card>
                        </div>
                    </div>



                </Grid.Col>
            </Grid>
        </div>

    </>)

}


export default Login;
