import {Button, Center, Image, Text,Title} from "@mantine/core";
import {Link} from "react-router-dom";


export const ErrorPage = () => {

    return (<div className="page-404">
            <Center style={{height: '100%'}}>
                <div style={{display: 'inline-block', textAlign: 'center'}}>
                    <Center>
                        <Image src={"/img/error-page.png"} width={270}/>
                    </Center>
                    <Title className={"page-title"} order={1}>Sayfa bulunamadı</Title>
                    <Text className={"page-text"}>Üzgünüz, istediğiniz sayfa bulunamadı <br/> Lütfen ana
                        sayfaya geri dönün

                    </Text>

                    <Button component={Link} to={'/'} className={"dark-btn"} color={"violet"}>Ana Sayfaya Dön</Button>

                </div>
            </Center>
        </div>
    )

}

export default ErrorPage;


