import {
    Button,
    Card,
    Center,
    Grid,
    Group,
    Image,
    LoadingOverlay,
    PasswordInput,
    Text,
    Title,
    useMantineTheme
} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {useDocumentTitle} from "@mantine/hooks";
import {useModals} from "@mantine/modals";
import {useForm} from "@mantine/form";
import {Link, useNavigate, useParams} from "react-router-dom";
import {getSession} from "../../../lib/util/auth";
import {notifications} from "@mantine/notifications";
import {MdOutlineCheckCircle} from "react-icons/md";
import Connect from "../../../lib/util/Connect";
import {ErrorViewing} from "../../../lib/util/ErrorViewing";
import {ErrorMessage} from "../../../lib/util/ErrorMessage";


export const ResetPassword = () => {


    useDocumentTitle('HibritCard - HibritMedya');

    const theme = useMantineTheme();
    const [loading, setLoading] = useState(false);
    const [resetStatus, setResetStatus] = useState(false);
    const modals = useModals();
    let navigate = useNavigate();
    const {id} = useParams();


    const form = useForm({
        initialValues: {
            password: '',
            rePassword: ''
        },

        validate: {
            password: (value) => (value !== '' ? null : 'Şifre girmelisiniz.'),
            rePassword: (value) => (value !== '' ? null : 'Şifre Tekrar girmelisiniz.'),
        },
    });


    useEffect(() => {

        let token = getSession();

        if (token !== undefined) {
            navigate('/select-profile');
        }

    }, []);


    const setLogin = (values) => {

        if (values.password === values.rePassword) {

            setLoading(true);


            Connect().post(`member/confirm/password`,{
                password:values.password,
                uuid:id
            }).then(({data}) =>{

                setResetStatus(true);

            }).catch((e) => {
                notifications.show({
                    title: 'Hata',
                    withCloseButton: false,
                    message: ErrorMessage(e),
                    color: 'red'
                });
            }).finally(() =>{
                setLoading(false);
            });


        } else {
            notifications.show({
                title: 'Hata',
                withCloseButton: false,
                message: 'Şifreler birbiriyle aynı olmak zorunda',
                color: 'red'
            });
        }

    }


    return (<>

        <div className={"userPage"}>
            <Grid gutter={0} className={"h-100"}>
                <Grid.Col lg={5} className={"user-left"}>
                    <Center className={"h-100"}>
                        <Image className={"user-img"} src={'/img/white-logo.svg'} fit={"contain"} width={500}/>
                    </Center>
                </Grid.Col>
                <Grid.Col lg={7}>


                    <div className="table">
                        <div className="table-cell">
                            <Card className={"default-card user-table-card"} p={40} radius={"md"} shadow={"md"}
                                  style={{width: 550, margin: '0 auto'}}>

                                {!resetStatus &&
                                    <>
                                        <LoadingOverlay visible={loading}
                                                        loaderProps={{size: 43, color: 'violet',}}
                                        />

                                        <Title order={2}>Şifreni Sıfırla</Title>
                                        <Text className={"text"}>Yeni şifrenizi girerek şifrenizi
                                            sıfırlayabilirsiniz.</Text>
                                        <form onSubmit={form.onSubmit((values) => setLogin(values))}>
                                            <PasswordInput
                                                label="Yeni Şifre"
                                                placeholder="Şifreniz"
                                                className={"form-input"}
                                                mt={13}
                                                mb={25}
                                                {...form.getInputProps('password')}
                                            />
                                            <PasswordInput
                                                label="Yeni Şifre Tekrar"
                                                placeholder="Şifreniz"
                                                className={"form-input"}
                                                mt={13}
                                                mb={25}
                                                {...form.getInputProps('rePassword')}
                                            />

                                            <Group grow>
                                                <Button disabled={loading} className={"active-btn"} color={"violet"}
                                                        type="submit">Şifremi Sıfırla</Button>
                                            </Group>

                                        </form>
                                    </>
                                }


                                {resetStatus &&
                                    <Center>
                                        <div className={"text-center"}>
                                            <MdOutlineCheckCircle size={60} color={'#1ed36b'}/>

                                            <Text mt={2} size={"lg"}>Şifreniz Sıfırlandı</Text>
                                            <Text mt={2} size={"xs"}>Giriş yapmak için aşağıdaki butonu
                                                kullanabilirsiniz.</Text>

                                            <Button  component={Link} to={'/login'} color={"violet"} size={"sm"} mt={20}>Giriş
                                                Yap</Button>

                                        </div>
                                    </Center>
                                }

                            </Card>
                        </div>
                    </div>


                </Grid.Col>
            </Grid>
        </div>

    </>)

}


export default ResetPassword;
