import {Button, Grid, NumberInput, Textarea, TextInput} from "@mantine/core";
import {useForm} from "@mantine/form";
import React, {useEffect, useState} from "react";
import Connect from "../../util/Connect";
import {ErrorViewing} from "../../util/ErrorViewing";
import {modals} from "@mantine/modals";
import {SuccessViewing} from "../../util/SuccessViewing";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'


export const BillingForm = ({info,onResult,onItem}) => {

    const [infoItem, setInfoItem] = useState(null);
    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    const [blankData, setBlankData] = useState({
        id: null,
        member_panel_id: 99999999,
        company_name: "",
        tax_office: "",
        tax_number: "",
        address: "",
        phone_code: "",
        email: "",
        is_deleted: false,
        status: true,
        created_at: "2023-06-28T15:17:47.243Z",
        updated_at: "2023-06-28T15:17:47.243Z"
    });


    const form = useForm({
        initialValues: {
            title: '',
            tax: '',
            email: '',
            tax_number: '',
            address: '',
            phone: '90',
        },

        validate: {
            title: (value) => (value !== '' ? null : 'Firma Unvanı girmelisiniz'),
        },
    });


    useEffect(()=>{


        if (info.MP_Invoice.length){

           let _info =  info.MP_Invoice[0];

           setInfoItem(_info);

            form.setValues({
                title: _info.company_name,
                address: _info.address,
                tax: _info.tax_office,
                tax_number: Number(_info.tax_number),
                phone: _info.phone_code,
                email: _info.email
            });

        }


        setTimeout(() => {
            setPageLoading(false);
        }, 150);

    },[]);



    const addBilling = (values) => {

        setLoading(true);


        Connect().post(infoItem !== null  ? `panel/item/Invoice/edit/${infoItem.id}` :  `panel/item/Invoice/${info.id}/add`,{
            company_name: values.title,
            tax_office: values.tax,
            tax_number: String(values.tax_number),
            address: values.address,
            phone_code: values.phone,
            email: values.email
        }).then(({data}) =>{

            if (infoItem === null){
                setInfoItem(data.result);
            }

            onResult();

            SuccessViewing('Güncelleme Başarılı',modals);

        }).catch((e) => ErrorViewing(e,modals)).finally(()=> setLoading(false));


    }


    useEffect(()=>{


        if (!pageLoading){

            if (infoItem !== null) {

                infoItem.company_name = form.values.title;
                infoItem.tax_office = form.values.tax;
                infoItem.tax_number = form.values.tax_number;
                infoItem.address = form.values.address;
                infoItem.phone_code = form.values.phone;
                infoItem.email = form.values.email;

                onItem(infoItem);
            }{


                let data = {...blankData};

                data.company_name = form.values.title;
                data.tax_office = form.values.tax;
                data.tax_number = form.values.tax_number;
                data.address = form.values.address;
                data.phone_code = form.values.phone;
                data.email = form.values.email;

                setBlankData(data);
                onItem(data, true);

            }

        }

    },[form.values]);


    return (
        <form onSubmit={form.onSubmit((values) => addBilling(values))}>
            <Grid gutter={25}>



                <Grid.Col lg={12}>
                    <TextInput className={"form-input"} size={"md"} label={"Firma Unvanı"}
                               placeholder={"Firma Unvanı"}
                               {...form.getInputProps('title')}
                    />
                </Grid.Col>
                <Grid.Col lg={12}>
                    <TextInput className={"form-input"} size={"md"} label={"Vergi Dairesi"}
                               placeholder={"Vergi Dairesi"}
                               {...form.getInputProps('tax')}
                    />
                </Grid.Col>
                <Grid.Col lg={12}>
                    <NumberInput hideControls  className={"form-input"} size={"md"} label={"Vergi Numarası"}
                               placeholder={"Vergi Numarası"}
                               {...form.getInputProps('tax_number')}
                    />
                </Grid.Col>

                <Grid.Col lg={12}>
                    <Textarea className={"form-input"} size={"md"} label={"Adres"} placeholder={"Adres"}
                              {...form.getInputProps('address')}
                    />
                </Grid.Col>

                <Grid.Col lg={12} style={{position:'relative'}}>

                    <div className={"phone-input form-input"}>
                        <div className="mantine-InputWrapper-label">Telefon Numarası</div>
                        <PhoneInput
                            value={form.values.phone}
                            countryCodeEditable={false}
                            autoFormat={true}
                            onChange={phone => form.setFieldValue('phone',phone)}
                        />
                    </div>

                </Grid.Col>
                <Grid.Col lg={12}>
                    <TextInput className={"form-input"} size={"md"} label={"Ofis E-postası"}
                               placeholder={"placeholder@mail.com"}
                               {...form.getInputProps('email')}
                    />
                </Grid.Col>
                <Grid.Col lg={12}>
                    <Button loading={loading} color={"violet"} fullWidth style={{height: 43}} mt={5} type={"submit"}>Güncellemeyi
                        Kaydet</Button>
                </Grid.Col>

            </Grid>
        </form>)

}


export default BillingForm;
