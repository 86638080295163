import {Badge, createStyles, Divider, Grid, Group, Paper, rem, Text} from '@mantine/core';
import {IconBoxSeam, IconCalendar, IconUser} from '@tabler/icons-react';
import {ButtonCopy} from "../../../app/user/profile/ButtonCopy";
import {BASE_URL_HIBRITCARD} from "../../util/constants";
import Moment from 'react-moment';

const useStyles = createStyles((theme) => ({
    root: {
        padding: `calc(${theme.spacing.xl} * 1.5)`,
    },

    value: {
        fontSize: rem(24),
        fontWeight: 700,
        lineHeight: 1,
    },

    diff: {
        lineHeight: 1,
        display: 'flex',
        alignItems: 'center',
    },

    icon: {
        color: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[4],
    },

    title: {
        fontWeight: 700,
        textTransform: 'uppercase',
    },
}));




export function PackageInfo({user}) {
    const {classes} = useStyles();


    const calculateRemainingDays = (date) => {
        const currentDate = new Date();
        const remainingTime = new Date(date) - currentDate;
        const remainingDays = Math.ceil(remainingTime / (1000 * 60 * 60 * 24));
        return remainingDays;
    };


    return (
        <div>
            <Grid>
                <Grid.Col xs={12} md={3}>
                    <Paper withBorder p="md" radius="md" key="pi-share" mih={100}>
                        <Group position="apart">
                            <Text size="xs" color="dimmed" className={classes.title}>
                                HESABI PAYLAŞ
                            </Text>
                            <IconUser size="1.4rem" stroke={1.5}/>
                        </Group>

                        <div style={{marginTop: 10}}>
                            <ButtonCopy url={BASE_URL_HIBRITCARD + '/id/' + user?.username} uuid={user?.username}/>
                        </div>
                    </Paper>
                </Grid.Col>

                <Grid.Col xs={12} md={3}>
                    <Paper withBorder p="md" radius="md" key="pi-info" mih={100}>
                        <Group position="apart">
                            <Text size="xs" color="dimmed" className={classes.title}>
                                HESAP OLUŞTURMA TARİHİ
                            </Text>
                            <IconCalendar size="1.4rem" stroke={1.5}/>
                        </Group>

                        <Text fz="xs" c="dimmed" mt={7}>
                            <Moment format={"DD/MM/YYYY"}>{user?.created_at}</Moment>
                        </Text>
                    </Paper>
                </Grid.Col>

                <Grid.Col xs={12} md={3}>
                    <Paper withBorder p="md" radius="md" key="pi-info" mih={100}>
                        <Group position="apart">
                            <Text size="xs" color="dimmed" className={classes.title}>
                                HESAP BİTİŞ TARİHİ
                            </Text>
                            <IconCalendar size="1.4rem" stroke={1.5}/>
                        </Group>

                        <Text fz="xs" c="dimmed" mt={7}>

                        </Text>
                        <Badge size={"lg"} variant="gradient" style={{textTransform:'initial'}} gradient={{ from: 'orange', to: 'red' }}>
                            <Moment local={"tr"} fromNow>{user?.exp_date}</Moment>
                        </Badge>
                    </Paper>
                </Grid.Col>




                <Grid.Col xs={12} md={3}>
                    <Paper withBorder p="md" radius="md" key="pi-info" mih={100}>
                        <Group position="apart">
                            <Text size="xs" color="dimmed" className={classes.title}>
                                HESAP TÜRÜ
                            </Text>
                            <IconBoxSeam size="1.4rem" stroke={1.5}/>
                        </Group>

                        <Text fz="xs" c="dimmed" mt={7}>
                            {user?.member_type}
                        </Text>
                    </Paper>
                </Grid.Col>
            </Grid>
            <Divider my="sm" variant="dashed"/>
        </div>
    );
}
