import {Image,Center, Title,Text,Button} from "@mantine/core";
import {IoArrowBack, IoArrowDown, IoArrowForward, IoCheckmarkCircle} from "react-icons/io5";
import {Link, useLocation} from "react-router-dom";
import {useEffect, useState} from "react";

export const PaymentResult = () =>{

    const location = useLocation();
    const [status,setStatus] = useState('success');

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const myParam = queryParams.get('status');
        setStatus(myParam);
    }, [location]);


    return(<Center className={"text-center"} style={{height:'100vh'}}>

      <div>
            <div style={{display:'inline-block'}}>
                <Image src={status === 'success' ? '/img/payment.png' :'/img/error.png'} width={300}/>
            </div>

          <Title mb={12}>{status === 'success' ? 'Ödeme Başarılı'  : 'Ödeme Başarısız'}</Title>
          <Text mb={6} mt={5}>{status === 'success' ? 'Ödemeniz başarıyla gerçekleşmiştir.' : 'Ödemeniz başarısız olmuştur.' }  <br/> Daha  fazla  detay için bizimle iletişime geçebilirsiniz.</Text>
          <Button component={Link} to={'/'} color={"teal"} mt={20} rightIcon={<IoArrowForward/>}>Ana Sayfaya Dön</Button>

      </div>

    </Center>)

}


export default PaymentResult;
