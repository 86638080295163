import {Button, Text} from '@mantine/core';
import {IoCloseCircleOutline} from "react-icons/io5";
import React from "react";
import _ from "lodash";
import {logOut} from "./auth";

export const ErrorViewing = async (e, modals) => {



    let message = '';



    if (e?.response?.data?.errors) {
        const errors = e.response.data.errors;

        await Promise.all(_.map(errors, (item) => message += `${item.param} ${item.msg}`));


    } else {
        message = e?.response?.data?.message ?? e.message;
    }



    modals.openConfirmModal({
        title: '',
        centered: true,
        className:"confirm-modal",
        children: (<div className={"text-center"} style={{paddingBottom: 13, paddingTop: 10}}>
            <IoCloseCircleOutline style={{marginLeft: -2, opacity: 0.9}} color={"#fa5252"} size={73}/>
            <Text color={"gray"} style={{marginTop: 1, fontSize: '28px'}} weight={500}>Hata</Text>
            <Text color={"gray"} style={{marginTop: 3}} size="md">{message}</Text>
            <Button onClick={() => modals.closeAll()} style={{marginTop: 17}} color={"indigo"}>Tamam</Button>
        </div>),


        //   color:"red",
        //  icon:<IoCloseOutline/>,
        //    message: message,
    });



    if (e?.response?.data?.auth_failed){


        logOut();
        window.location.href = '/login';


    }


}




