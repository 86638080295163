import {Button, Divider, List, ThemeIcon} from "@mantine/core";
import {AiOutlinePhone, AiOutlineUser} from "react-icons/ai";
import {SlLocationPin} from "react-icons/sl";
import {IoCopy} from "react-icons/io5";
import {useEffect, useState} from "react";
import {FaRegAddressCard, FaRegStickyNote} from "react-icons/fa";
import {VscOrganization} from "react-icons/vsc";
import {RiFileUserLine} from "react-icons/ri";
import {HiOutlineEnvelope} from "react-icons/hi2";
import {Link} from "react-router-dom";
import {BASE_URL} from "../../../util/constants";


export const ContactPanel = ({data,uuid}) => {



    const [loading, setLoading] = useState(true);


    useEffect(() => {


        if (data !== undefined) {

            if (Array.isArray(data)){
                setLoading(!data.length);

            } else{
                setLoading(false);
            }

        }

    }, [data]);


    if (loading) {
        return (<></>)
    }



    return (<>
        <div className="item-row-item">
            <List spacing={"sm"} className={"list-item-ul"}>

                {data?.firstname !== '' &&
                    <List.Item  icon={
                        <ThemeIcon variant="light" color="gray">
                            <AiOutlineUser/>
                        </ThemeIcon>}>
                    <span style={{fontWeight:600}}>{data?.firstname} {data?.lastname}</span>
                    </List.Item>
                }


                {(data?.organization !== '' && data?.organization !== null) &&
                    <List.Item icon={
                        <ThemeIcon variant="light" color="gray">
                            <VscOrganization/>
                        </ThemeIcon>}> {data?.organization}</List.Item>
                }


                {(data?.position !== '' && data?.position !== null) &&
                    <List.Item icon={
                        <ThemeIcon variant="light" color="gray">
                            <RiFileUserLine/>
                        </ThemeIcon>}> {data?.position}</List.Item>
                }

                {data.MP_ContactInformation_Phone.map((item,index) => (
                    <List.Item key={`contact-phone-${index}`} icon={
                        <ThemeIcon variant="light" color="gray">
                            <AiOutlinePhone/>
                        </ThemeIcon>}>+{item.phone_code}</List.Item>
                ))}

                {data.MP_ContactInformation_Mail.map((item,index) => (
                    <List.Item key={`contact-mail-${index}`} icon={
                        <ThemeIcon variant="light" color="gray">
                            <HiOutlineEnvelope/>
                        </ThemeIcon>}>{item.email}</List.Item>
                ))}



                {(data?.address !== '' && data?.address !== null) &&
                    <List.Item icon={
                        <ThemeIcon variant="light" color="gray">
                            <FaRegAddressCard/>
                        </ThemeIcon>}> {data?.address}</List.Item>
                }

                {(data?.country_id !== '' && data?.country_id !== null) &&
                    <List.Item icon={
                        <ThemeIcon variant="light" color="gray">
                            <SlLocationPin/>
                        </ThemeIcon>}> {data?.country?.baslik} / {data?.city?.baslik}</List.Item>
                }

                {(data?.notes !== '' && data?.notes !== null) &&
                    <List.Item icon={
                        <ThemeIcon variant="light" color="gray">
                            <FaRegStickyNote/>
                        </ThemeIcon>}> {data?.notes}</List.Item>
                }

            </List>
            {data?.id !== null &&
                <Button component={Link} to={`${BASE_URL}/member/global/${uuid}/export/vcard/ci/${data.id}`} fullWidth mt={20} className={"active-btn"} leftIcon={<IoCopy/>}>Rehbere
                    Ekle</Button>
            }


            <Divider variant={"dashed"} mt={30} mb={-10}/>
        </div>
    </>)

}

export default ContactPanel;
