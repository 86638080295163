import 'dayjs/locale/tr';
import {
    Button,
    Card,
    Collapse,
    Container,
    Divider,
    Grid,
    Group,
    PasswordInput,
    Select,
    Text,
    TextInput,
    Title,
    UnstyledButton
} from "@mantine/core";
import {useDisclosure, useDocumentTitle} from "@mantine/hooks";
import {useForm} from "@mantine/form";
import {DateInput} from '@mantine/dates';
import Navbar from "../../../lib/components/Nav";
import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import PhoneInput from "react-phone-input-2";
import Connect from "../../../lib/util/Connect";
import {notifications} from "@mantine/notifications";
import {ErrorMessage} from "../../../lib/util/ErrorMessage";
import {set_user} from "../../../data/actions/user";
import {logOut} from "../../../lib/util/auth";
import {QuestionModal} from "../../../lib/util/QuestionModal";
import {modals} from "@mantine/modals";
import Moment from "react-moment";
import moment from "moment";


export const ProfileSettings = ({user}) => {

    useDocumentTitle('Hesap Ayarları');


    const [opened, {open, close}] = useDisclosure(false);
    const [loading, setLoading] = useState(false);
    const [passLoading, setPassLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [openForm, setOpenForm] = useState(false);
    const [formLoad,setFormLoad] = useState(false);

    const form = useForm({
        initialValues: {
            username: '',
            firstname: '',
            lastname: '',
            gender: '',
            phone_code: '',
            birth_date: new Date()
        },

        validate: {
            username: (value) => (value !== '' ? null : 'Kullanıcı Adı girmelisiniz.'),
            firstname: (value) => (value !== '' ? null : 'Ad girmelisiniz.'),
            lastname: (value) => (value !== '' ? null : 'Soyad girmelisiniz.'),
        },
    });



    const formPass = useForm({
        initialValues: {
            old_password: '',
            password: '',
            new_password: '',
        },

        validate: {
            //old_password: (value) => (value !== '' ? null : 'Eski Şifreyi girmelisiniz.'),
            password: (value) => (value !== '' ? null : 'Şifre girmelisiniz.'),
            new_password: (value) => (value !== '' ? null : 'Şifre Tekrar girmelisiniz.'),
        },
    });


    const formPremium = useForm({
        initialValues: {
            code: '',

        },

        validate: {
            code: (value) => (value !== '' ? null : 'Premium kodu girmelisiniz.'),

        },
    });


    useEffect(() => {

        if (user !== null) {

            let phone = user.phone_code;

            if (phone === '' || phone === null) {
                phone = '90'
            }


            setEmail(user.email);

            form.setValues({
                username: user.username,
                firstname: user.firstname,
                lastname: user.lastname,
                gender: user.gender,
                phone_code: phone,
                birth_date: new Date(user.birth_date)
            });

        }

        console.log('user', user);


    }, [user]);


    const getUserInfo = () => {

        Connect().get('member/info').then(({data}) => {


            set_user(data.result);



        }).catch((e) => {
            notifications.show({
                title: 'Hata',
                withCloseButton:false,
                message: ErrorMessage(e),
                color: 'red'
            });
        });

    }


    const setUser = (values) => {

        setLoading(true);

        Connect().post('member/edit/info',values).then(({data}) =>{


            notifications.show({
                title: 'Başarılı',
                withCloseButton:false,
                message: 'Güncelleme Tamamlandı',
                color:'teal'
            });

            getUserInfo();



        }).catch((e) => {
            notifications.show({
                title: 'Hata',
                withCloseButton:false,
                message: ErrorMessage(e),
                color:'red'
            });
        }).finally(() => setLoading(false));

    }

    const setPassword = (values) => {


        if (values.password === values.new_password){

            setPassLoading(true);


            Connect().post('member/edit/password',{
              //  old_password:values.old_password,
                password:values.password,
                password_confirmation:values.new_password,

            }).then(({data}) =>{


                formPass.setValues({
                    old_password: '',
                    password: '',
                    new_password: '',
                });

                notifications.show({
                    title: 'Başarılı',
                    withCloseButton:false,
                    message: 'Güncelleme Tamamlandı',
                    color:'teal'
                });

            }).catch((e) => {
                notifications.show({
                    title: 'Hata',
                    withCloseButton:false,
                    message: ErrorMessage(e),
                    color: 'red'
                });
            }).finally(() => setPassLoading(false));

        } else{
            notifications.show({
                title: 'Hata',
                withCloseButton:false,
                message: 'Şifreler birbiriyle eşleşmiyor',
                color: 'red'
            });
        }

    }

    const removeAccount = () => {

        Connect().get(`member/deleted`).then(({data}) => {


            notifications.show({
                title: 'Uyarı',
                withCloseButton: false,
                message: 'Hesabınız Kalıcı Olarak Silindi',
                color: 'orange'
            });

            setTimeout(() =>{
                logOut();
            },500);


        }).catch((e) => {
            notifications.show({
                title: 'Hata',
                withCloseButton: false,
                message: ErrorMessage(e),
                color: 'red'
            });
        })

    }


    const setPremium = (values) => {

        setFormLoad(true);

        Connect().post('member/premium/code',{
            premium_code:values.code
        }).then(({data}) =>{

         //   console.log('data',data);

            notifications.show({
                title: 'Başarılı',
                withCloseButton:false,
                message: 'Premium kod tanımlanmıştır',
                color:'teal'
            });


            setTimeout(() =>{

                window.location.href = window.location.href;

            },600);


        }).catch((e) => {
            notifications.show({
                title: 'Hata',
                withCloseButton: false,
                message: ErrorMessage(e),
                color: 'red'
            });
        }).finally(() => setFormLoad(false));

    }


    return (<>

        <Navbar/>


        <Container size={1500}>
            <Grid gutter={30}>
                <Grid.Col lg={6}>
                    <Card shadow={"md"} withBorder={true} padding={30} radius="md">
                        <Title mb={20} order={2} className={"table-title-item"}>Hesap</Title>
                        <form onSubmit={form.onSubmit((values) => setUser(values))}>

                            <Grid gutter={30}>
                                <Grid.Col lg={12}>
                                    <TextInput radius={"md"} variant={"filled"} size={"md"}
                                               placeholder={"Kullanıcı Adı"}
                                               {...form.getInputProps('username')}

                                    />
                                </Grid.Col>
                                <Grid.Col lg={6}>
                                    <TextInput radius={"md"} variant={"filled"} size={"md"} placeholder={"Ad"}
                                               {...form.getInputProps('firstname')}
                                    />
                                </Grid.Col>
                                <Grid.Col lg={6}>
                                    <TextInput radius={"md"} variant={"filled"} size={"md"} placeholder={"Soyad"}
                                               {...form.getInputProps('lastname')}
                                    />
                                </Grid.Col>
                                <Grid.Col lg={12}>
                                    <TextInput radius={"md"} disabled  value={email} variant={"filled"} size={"md"}
                                               placeholder={"Email"}
                                    />
                                </Grid.Col>
                                <Grid.Col lg={12}>

                                  <div className={"phone-input-form"}>
                                      <PhoneInput
                                          value={form.values.phone_code}
                                          countryCodeEditable={false}
                                          autoFormat={true}
                                          onChange={phone => form.setFieldValue('phone_code',phone)}
                                      />
                                  </div>


                                </Grid.Col>
                                <Grid.Col lg={12}>
                                    <Select radius={"md"} size={"md"} variant={"filled"}
                                            data={[{label: 'Erkek', value: 'Male'}, {label: 'Kadın', value: 'Female'}, {label:'Belirtilmemiş', value:'Unknown'}]}
                                            {...form.getInputProps('gender')}
                                    />
                                </Grid.Col>

                                <Grid.Col lg={12}>
                                    <DateInput
                                        locale={"tr"}
                                        radius={"md"}
                                        size={"md"}
                                        variant={"filled"}
                                        placeholder="Doğum Tarihi"
                                        {...form.getInputProps('birth_date')}
                                    />
                                </Grid.Col>
                            </Grid>

                            <Group position={'right'} mt={25}>
                                <Button loading={loading} color={"violet"} radius={"sm"} type={"submit"}>Güncellemeyi
                                    Kaydet</Button>
                            </Group>
                        </form>
                    </Card>

                    {user?.member_type === 'Standart' &&
                        <Card mt={30} shadow={"md"} withBorder={true} padding={30} radius="md">

                            <Title mb={20} order={2} className={"table-title-item"}>Premium
                                Kod</Title>

                            {user.MemberPremiumCode.length === 0 &&
                                <form onSubmit={formPremium.onSubmit((values) => setPremium(values))}>


                                    <TextInput placeholder={"Premium Kodu Gir"}
                                               {...formPremium.getInputProps('code')}
                                    />

                                    <Button loading={formLoad} type={"submit"} color={"violet"} mt={20}>Premium Ol</Button>
                                </form>
                            }

                            {user.MemberPremiumCode.length > 0 &&
                                <>
                                  {user.MemberPremiumCode[0].status &&
                                      <>
                                          {user.MemberPremiumCode[0].completed &&
                                              <div>
                                                  <Text color={"dimmed"} mb={10} size={"sm"}>Premium kod süreniz
                                                      dolmuştur, yenisini girmek için <UnstyledButton
                                                          onClick={() => setOpenForm((o) => !o)}>tıklayın</UnstyledButton></Text>

                                                  <Collapse in={openForm}>
                                                      <form
                                                          onSubmit={formPremium.onSubmit((values) => setPremium(values))}>
                                                          <TextInput
                                                              placeholder={"Premium Kodu Gir"}{...formPremium.getInputProps('code')} />
                                                          <Button loading={formLoad} type={"submit"} color={"violet"} mt={20}>Premium Ol</Button>
                                                      </form>
                                                  </Collapse>

                                              </div>
                                          }

                                          {!user.MemberPremiumCode[0].completed &&
                                              <div>
                                                  <Text mt={-5} mb={10} color={"dimmed"} size={"sm"}>Premium kod zaten
                                                      girilmiştir</Text>
                                                  <Divider variant={"dashed"} mb={15}/>
                                                  <Text mb={4} size={"xs"}>Başlangıç Tarihi: <b><Moment
                                                      format={"DD/MM/YYYY HH:MM"}>{user.MemberPremiumCode[0].start_date}</Moment></b>
                                                  </Text>
                                                  <Text size={"xs"}>Bitiş Tarihi: <b style={{color: '#fa5252'}}><Moment
                                                      format={"DD/MM/YYYY HH:MM"}>{moment(user.MemberPremiumCode[0].start_date).add(30, 'days')}</Moment></b></Text>
                                              </div>
                                          }
                                      </>
                                  }
                                      {!user.MemberPremiumCode[0].status &&
                                          <form onSubmit={formPremium.onSubmit((values) => setPremium(values))}>
                                              <TextInput
                                                  placeholder={"Premium Kodu Gir"}{...formPremium.getInputProps('code')} />

                                              <Button loading={formLoad} type={"submit"} color={"violet"} mt={20}>Premium Ol</Button>
                                          </form>
                                      }
                                </>
                            }


                        </Card>
                    }


                </Grid.Col>
                <Grid.Col lg={6}>
                    <Card shadow={"md"} withBorder={true} padding={30} radius="md">
                        <Title mb={20} order={2} className={"table-title-item"}>Şifre Değiştir</Title>
                        <form onSubmit={formPass.onSubmit((values) => setPassword(values))}>

                            <Grid gutter={30}>
                                <Grid.Col lg={12}>
                                    <PasswordInput radius={"md"} variant={"filled"} size={"md"} placeholder={"Şifre"}
                                                   {...formPass.getInputProps('password')}
                                    />
                                </Grid.Col>
                                <Grid.Col lg={12}>
                                    <PasswordInput radius={"md"} variant={"filled"} size={"md"}
                                                   placeholder={"Şifre Tekrar"}
                                                   {...formPass.getInputProps('new_password')}
                                    />
                                </Grid.Col>


                            </Grid>

                            <Grid>
                                <Grid.Col lg={8}>

                                </Grid.Col>
                                <Grid.Col lg={4}>
                                    <Group position={'right'} mt={25}>
                                        <Button loading={passLoading} color={"violet"} radius={"sm"} type={"submit"}>Güncellemeyi
                                            Kaydet</Button>
                                    </Group>
                                </Grid.Col>
                            </Grid>

                        </form>

                    </Card>


                    <Card mt={30} shadow={"md"} withBorder={true} padding={30} radius="md">
                        <Title mb={20} order={2} className={"table-title-item"}>Hesap İşlemleri</Title>

                        <Grid>

                            <Grid.Col lg={9}>
                                <Button onClick={() => logOut()} color={"red"} mt={25}>Çıkış Yap</Button>

                            </Grid.Col>
                            <Grid.Col lg={3}>
                                <Group>
                                    <UnstyledButton onClick={() => {
                                        QuestionModal({
                                            modals,
                                            title: 'Hesabı Sil',
                                            text: 'Hesabı silmek',
                                            confirm: () => removeAccount()
                                        });
                                    }} mt={26}><Text size={"sm"} color={"red"}>Hesabı Sil</Text></UnstyledButton>
                                </Group>
                            </Grid.Col>



                        </Grid>
                    </Card>

                </Grid.Col>

            </Grid>
        </Container>

    </>)

}


const mapProps = (state) => ({
    user: state.user.info
});


export default connect(mapProps)(ProfileSettings);
