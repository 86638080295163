import {AppShell} from '@mantine/core';
import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import Header from "../components/Header";

function Content(props) {

    const [open, setOpen] = useState(false);


    useEffect(()=>{


        if (open) {
            document.body.classList.add('none-scroll');
        } else {
            document.body.classList.remove('none-scroll');
        }

    }, [open]);




        return (<>
            {props.children}
        </>)

}
export default Content;
