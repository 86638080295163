import React from "react";
import {
    Button,
    Card,
    Center,
    Checkbox,
    Grid,
    Image,
    LoadingOverlay,
    PasswordInput,
    Text,
    TextInput,
    Title,
    useMantineTheme
} from "@mantine/core";
import {useEffect, useState} from "react";
import {useDocumentTitle} from "@mantine/hooks";
import {useModals} from "@mantine/modals";
import {useForm} from "@mantine/form";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {getSession, setSession} from "../../../lib/util/auth";
import Connect from "../../../lib/util/Connect";
import {notifications} from "@mantine/notifications";
import {ErrorMessage} from "../../../lib/util/ErrorMessage";
import {setUser} from "../../../lib/util/user";
import {MdOutlineCheckCircle} from "react-icons/md";


function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const Register = () => {


    useDocumentTitle('HibritCard - HibritMedya');

    const theme = useMantineTheme();
    const [loading, setLoading] = useState(false);
    const modals = useModals();
    let navigate = useNavigate();
    const [login, setLogin] = useState(false);
    let query = useQuery();


    const form = useForm({
        initialValues: {
            firstname: '',
            lastname: '',
            username: '',
            email: '',
            password: '',
            new_password: '',
            termsOfService: false,
            confidentiality: false
        },

        validate: {
            firstname: (value) => (value !== '' ? null : 'Ad girmelisiniz.'),
            lastname: (value) => (value !== '' ? null : 'Soyad girmelisiniz.'),
            username: (value) => (value !== '' ? null : 'Kullanıcı Adı girmelisiniz.'),
            password: (value) => (value !== '' ? null : 'Şifre girmelisiniz.'),
            new_password: (value) => (value !== '' ? null : 'Şifre Tekrar girmelisiniz.'),
            termsOfService: (value) => (value ? null : 'KVKK onaylamanız gerekmektedir.'),
            confidentiality: (value) => (value ? null : 'Gizlilik Sözleşmesini onaylamanız gerekmektedir.'),
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Geçersiz mail adresi'),
        },
    });

    useEffect(() => {

        let token = getSession();

        if (token !== undefined) {
            navigate('/select-profile');
        }

    }, []);


    const setRegister = (values) => {


        if (values.password === values.new_password) {

           let queryId = query.get('id');

            setLoading(true);

            let objData = {
                email: values.email,
                password: values.password,
                username: values.username,
                firstname: values.firstname,
                lastname: values.lastname
            }

            if (queryId !== '' && queryId !== null && queryId !== undefined){
                objData.uuid = queryId;
            }


            Connect().post('member/register', objData).then(({data}) => {

                setLoading(false);
                setLogin(true);


            }).catch((e) => {

                notifications.show({
                    title: 'Hata',
                    withCloseButton: false,
                    message: ErrorMessage(e),
                    color: 'red'
                });

                setLoading(false);

            })

        } else {

            notifications.show({
                title: 'Hata',
                withCloseButton: false,
                message: 'Şifreler Eşleşmiyor',
                color: 'red'
            });


        }


    }





    return (<>

        <div className={"userPage"}>
            <Grid gutter={0} className={"h-100"}>
                <Grid.Col lg={5} className={"user-left"}>
                    <Center className={"h-100"}>
                        <Image className={"user-img"} src={'/img/white-logo.svg'} fit={"contain"} width={500}/>
                    </Center>
                </Grid.Col>
                <Grid.Col lg={7}>


                    <div className="table">
                        <div className="table-cell">
                            <Card className={"default-card user-table-card"} p={40} radius={"md"} shadow={"md"}
                                  style={{width: 700, margin: '0 auto'}}>
                                {!login && <>
                                    <LoadingOverlay visible={loading}
                                                    loaderProps={{size: 43, color: 'violet',}}
                                    />

                                    <Title order={2}>Kayıt Ol</Title>
                                    <Text className={"text"}>Kullanıcı bilgierinizi girerek sisteme kayıt
                                        olabilirsiniz.</Text>

                                    <form onSubmit={form.onSubmit((values) => setRegister(values))}>
                                        <Grid>
                                            <Grid.Col lg={6}>
                                                <TextInput
                                                    label="Ad"
                                                    placeholder="Ad"
                                                    className={"form-input"}
                                                    mt={17}
                                                    {...form.getInputProps('firstname')}
                                                />
                                            </Grid.Col>

                                            <Grid.Col lg={6}>
                                                <TextInput
                                                    label="Soyad"
                                                    placeholder="Soyad"
                                                    className={"form-input"}
                                                    mt={17}
                                                    {...form.getInputProps('lastname')}
                                                />
                                            </Grid.Col>


                                            <Grid.Col lg={12}>
                                                <TextInput
                                                    label="Kullanıcı Adı"
                                                    placeholder="Kullanıcı Adı"
                                                    className={"form-input"}
                                                    mt={7}
                                                    {...form.getInputProps('username')}
                                                />
                                            </Grid.Col>

                                            <Grid.Col lg={12}>
                                                <TextInput
                                                    label="E-posta"
                                                    placeholder="E-posta"
                                                    className={"form-input"}
                                                    mt={7}
                                                    {...form.getInputProps('email')}
                                                />
                                            </Grid.Col>

                                            <Grid.Col lg={6}>
                                                <PasswordInput
                                                    label="Şifre"
                                                    placeholder="Şifreniz"
                                                    mt={7}
                                                    className={"form-input"}
                                                    {...form.getInputProps('password')}
                                                />
                                            </Grid.Col>
                                            <Grid.Col lg={6}>
                                                <PasswordInput
                                                    label="Şifre"
                                                    mt={7}
                                                    placeholder="Şifre Doğrulama"
                                                    className={"form-input"}
                                                    {...form.getInputProps('new_password')}
                                                />
                                            </Grid.Col>
                                        </Grid>


                                        <Grid.Col lg={12} mt={25}>
                                            <Checkbox
                                                className={"checkbox-left"}
                                                mt="md"
                                                size={"sm"}
                                                color={"violet"}
                                                label={<><Link target={"_blank"} to={'https://www.hibritcard.com/kvkk'}>KVKK
                                                    Aydınlatma Metnini</Link> okudum, kabul ediyorum.</>}
                                                {...form.getInputProps('termsOfService', {type: 'checkbox'})}
                                            />
                                        </Grid.Col>
                                        <Grid.Col lg={12} mb={28}>
                                            <Checkbox
                                                className={"checkbox-left"}
                                                mt="md"
                                                size={"sm"}
                                                color={"violet"}
                                                label={<><Link target={"_blank"}
                                                               to={'https://www.hibritcard.com/gizlilik-ve-cerez-politikasi'}>Gizlilik
                                                    Sözleşmesini</Link> okudum, kabul ediyorum.</>}
                                                {...form.getInputProps('confidentiality', {type: 'checkbox'})}
                                            />
                                        </Grid.Col>

                                        <Button fullWidth disabled={loading} className={"active-btn"} color={"violet"}
                                                type="submit">Kayıt Ol</Button>

                                        <Center mt={15}>
                                            <Text className={"text"}>Bir hesabınız var mı? <Link to={'/login'}>Giriş
                                                yapın</Link></Text>
                                        </Center>

                                    </form>

                                </>}

                                {login &&
                                    <Center>
                                        <div className={"text-center"}>
                                            <MdOutlineCheckCircle size={60} color={'#1ed36b'}/>

                                            <Text mt={2} size={"lg"}>Kayıt İşlemi Tamamlandı</Text>
                                            <Text mt={2} size={"xs"}>Giriş yapmak için aşağıdaki butonu
                                                kullanabilirsiniz.</Text>

                                            <Button component={Link} to={'/login'} color={"violet"} size={"sm"} mt={20}>Giriş Yap</Button>

                                        </div>
                                    </Center>
                                }

                            </Card>
                        </div>
                    </div>



                </Grid.Col>
            </Grid>
        </div>

    </>)

}


export default Register;
