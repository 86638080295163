import Cookies from 'js-cookie'
import {Base64} from "js-base64";

export const setSession = (token) => {
    return Cookies.set('__session',token);
}


export const getSession = () => {
    return Cookies.get('__session')
}


export const logOut = () => {
    Cookies.remove('__session');
    Cookies.remove('__user');
    Cookies.remove('__modal');

    window.location.href = '/login';

}


export const setPreview = (token) => {


    localStorage.setItem('__profile_preview', token);
}


export const getPreview = () => {



    return localStorage.getItem('__profile_preview');

}
