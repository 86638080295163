import {Button, CopyButton, Divider, List, ThemeIcon} from "@mantine/core";
import {AiOutlineBank, AiOutlineGlobal, AiOutlineUser} from "react-icons/ai";
import {SlLocationPin} from "react-icons/sl";
import {IoCardOutline, IoCopy, IoWalletOutline} from "react-icons/io5";
import {useEffect, useState} from "react";


export const BankPanel = ({data}) => {

    const [loading, setLoading] = useState(true);


    useEffect(() => {

        console.log('data',data);

        if (data !== undefined && data.length > 0) {
            setLoading(false);
        }

    }, [data]);


    if (loading) {
        return (<></>)
    }


    return (<>

        {data.map((item,index) => (
            <div className="item-row-item" key={`bank-${index}`}>
                <List spacing={"sm"} className={"list-item-ul"}>
                    {(item.fullname !== '' && item.fullname !== null) &&
                        <List.Item icon={
                            <ThemeIcon variant="light" color="gray">
                                <AiOutlineUser/>
                            </ThemeIcon>}>{item.fullname}</List.Item>
                    }

                    {(item.bank_name !== '' && item.bank_name !== null) &&
                        <List.Item icon={
                            <ThemeIcon variant="light" color="gray">
                                <AiOutlineBank/>
                            </ThemeIcon>}>{item.bank_name}</List.Item>
                    }

                    {(item.bank_branch !== '' && item.bank_branch !== null) &&
                        <List.Item icon={
                            <ThemeIcon variant="light" color="gray">
                                <SlLocationPin/>
                            </ThemeIcon>}>{item.bank_branch} / {item.bank_branch_code}</List.Item>
                    }


                    {(item.bank_account_number !== '' && item.bank_account_number !== null) &&
                        <List.Item icon={<ThemeIcon variant="light" color="gray">
                            <IoCardOutline/>
                        </ThemeIcon>}>{item.bank_account_number}</List.Item>
                    }


                    {(item.bank_iban !== '' && item.bank_iban !== null) &&
                        <List.Item icon={<ThemeIcon variant="light" color="gray">
                            <IoWalletOutline/>
                        </ThemeIcon>}>{item.bank_iban}</List.Item>
                    }


                    {(item.bank_swift !== '' && item.bank_swift !== null) &&
                        <List.Item icon={
                            <ThemeIcon variant="light" color="gray">
                                <AiOutlineGlobal/>
                            </ThemeIcon>}>{item.bank_swift} / {item.bank_bic}</List.Item>
                    }

                </List>
                {item.bank_iban !== '' &&
                    <CopyButton value={item.bank_iban}>
                        {({copied, copy}) => (
                            <Button  fullWidth mt={20}  color={copied ? "teal" : "violet"}  onClick={copy} leftIcon={<IoCopy/>}>{copied ? "Kopyalandı" : "Iban'ı Kopyala"} </Button>
                        )}
                    </CopyButton>
                }
                {item.bank_iban !== '' &&
                <Divider variant={"dashed"} mt={30} mb={-10}/>
                }
            </div>
        ))}

    </>)

}

export default BankPanel;
