import {Button, createStyles, Grid, Radio, rem, Select, Textarea, TextInput, UnstyledButton} from "@mantine/core";
import {useForm} from "@mantine/form";
import React, {useEffect, useState} from "react";
import {IoAdd, IoTrashOutline} from "react-icons/io5";
import {useListState} from "@mantine/hooks";
import CountryData from "../../../data.json";
import _ from "lodash";
import Connect from "../../util/Connect";
import {ErrorViewing} from "../../util/ErrorViewing";
import {modals} from "@mantine/modals";
import {SuccessViewing} from "../../util/SuccessViewing";
import PhoneInput from "react-phone-input-2";


const useStyles = createStyles((theme, {opened}) => ({
    control: {
        width: rem(200),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `${theme.spacing.xs} ${theme.spacing.md}`,
        borderRadius: theme.radius.md,
        border: `${rem(1)} solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2]
        }`,
        transition: 'background-color 150ms ease',
        backgroundColor:
            theme.colorScheme === 'dark'
                ? theme.colors.dark[opened ? 5 : 6]
                : opened
                    ? theme.colors.gray[0]
                    : theme.white,

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0],
        },
    },

    label: {
        fontWeight: 500,
        fontSize: theme.fontSizes.sm,
    },

    icon: {
        transition: 'transform 150ms ease',
        transform: opened ? 'rotate(180deg)' : 'rotate(0deg)',
    },
}));


export const ContactForm = ({country, info, onResult,onItem}) => {


    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    const [phoneList, setPhoneList] = useListState([]);
    const [emailList, setEmailList] = useListState([]);
    const [typeList, setTypeList] = useState([
        {
            label: 'İş',
            value: 'Work'
        },
        {
            label: 'Ev',
            value: 'Home'
        },
        {
            label: 'Cep',
            value: 'Mobile'
        }
    ]);
    const [phoneCountry, setPhoneCountry] = useState([]);
    const [infoItem, setInfoItem] = useState(null);
    const [editId, setEditId] = useState('');
    const [countryData, setCountryData] = useState([]);
    const [cityList, setCityList] = useState([]);

    const [blankData, setBlankData] = useState({
        id: null,
        member_panel_id: 234343,
        firstname: "",
        lastname: "",
        organization: "",
        position: "",
        address: "",
        country_id: 223,
        city_id: 4121,
        city: {
            baslik: 'Adana'
        },
        country: {
            baslik: 'Turkey'
        },
        notes: "",
        is_deleted: false,
        status: true,
        created_at: "2023-06-28T11:51:53.760Z",
        updated_at: "2023-06-28T11:51:53.760Z",
        MP_ContactInformation_Phone: [],
        MP_ContactInformation_Mail: []
    })


    const form = useForm({
        initialValues: {
            firstname: '',
            lastname: '',
            organization: '',
            position: '',
            address: '',
            country: 223,
            city: 4121,
            notes: ''
        },

        validate: {
            firstname: (value) => (value !== '' ? null : 'Ad girmelisiniz'),
            lastname: (value) => (value !== '' ? null : 'Soyad girmelisiniz'),
            country: (value) => (value !== '' ? null : 'Ülke seçmelisiniz'),
            city: (value) => (value !== '' ? null : 'Şehir seçmelisiniz'),
        },
    });


    useEffect(() => {


        if (info.MP_ContactInformation.length) {

            const _info = info.MP_ContactInformation[0];


            setInfoItem(_info);


            setEditId(_info.id);


            blankData.firstname = _info.firstname;
            blankData.lastname = _info.lastname;
            blankData.organization = _info.organization;
            blankData.position = _info.position;
            blankData.address = _info.address;
            blankData.country_id = _info.country_id;
            blankData.city_id = _info.city_id;
            blankData.notes = _info.notes;


            form.setValues({
                firstname: _info.firstname,
                lastname: _info.lastname,
                organization: _info.organization,
                position: _info.position,
                address: _info.address,
                country: _info.country_id,
                city: _info.city_id,
                notes: _info.notes
            });

            let phones = [];
            let emails = [];

            let blank_phone = [];
            let blank_email = [];

            _info.MP_ContactInformation_Phone.map((item, index) => {

                phones.push({
                    id:item.id,
                    phone: item.phone_code,
                    type: item.phone_type,
                    default: item.is_default
                });

                blank_phone.push({
                    id: Math.floor(Math.random() * 10),
                    contact_information_id: Math.floor(Math.random() * 10),
                    phone_code_country: 0,
                    phone_code: item.phone_code,
                    is_default: item.is_default,
                    is_deleted: false,
                    status: true,
                    created_at: "2023-06-28T11:51:53.760Z",
                    updated_at: "2023-06-28T11:51:53.760Z",
                    phone_type: item.phone_type
                });

            });

            blankData.MP_ContactInformation_Phone = blank_phone;



            _info.MP_ContactInformation_Mail.map((item, index) => {

                emails.push({
                    id:item.id,
                    email: item.email,
                    is_default: item.is_default
                });

                blank_email.push({
                    id: Math.floor(Math.random() * 10),
                    contact_information_id: Math.floor(Math.random() * 10),
                    email: item.email,
                    is_default: item.is_default,
                    is_deleted: false,
                    status: true,
                    created_at: "2023-06-28T11:51:53.760Z",
                    updated_at: "2023-06-28T11:51:53.760Z"
                });


            });

            blankData.MP_ContactInformation_Mail = blank_email;


            setPhoneList.setState(phones);
            setEmailList.setState(emails);


        }


        let _data = [];


        CountryData.map((item) => {
            _data.push({label: item.countryName, value: item.phoneCode});
        });

        setPhoneCountry(_data);



        setTimeout(()=>{

            setPageLoading(false);

        },150);

    }, []);


    useEffect(() => {


        let countries = [];
        let cities = [];



        if (country !== null && country !== undefined) {

            country.map((item) => {
                countries.push({label: item.baslik, value: item.id});
            });

            let city = _.find(country,{
                id:form.values.country
            });


            city?.sehirler.map((item) => cities.push({label:item.baslik,value:item.id}));


            setCountryData(countries);
            setCityList(cities);


        }


    }, [country]);


    useEffect(()=>{


        if (form.values.city !== 4121){
            form.setFieldValue('city','');

        }
        let cities = [];


        let city = _.find(country,{
            id:form.values.country
        });


        city?.sehirler.map((item) => cities.push({label: item.baslik, value: item.id}));


        setCityList(cities);


    },[form.values.country]);


    const addPhone = () => {

        setPhoneList.append({
            id:null,
            phone: '90',
            type: 'Mobile',
            default: !phoneList.length
        });

    }


    const addEmail = () => {


        setEmailList.append({
            email: '',
            is_default: !emailList.length
        });


    }

    const updateDefault = (type = 'phone', index) => {


        if (type === 'phone') {

            phoneList.map((item, index) => {
                setPhoneList.setItemProp(index, 'default', false)
            });



                let data = {...blankData};


                data.MP_ContactInformation_Phone.map((item, index) => item.is_default = false);

                data.MP_ContactInformation_Phone[index].is_default = true;

                setBlankData(data);


                onItem([data], true);



            setPhoneList.setItemProp(index, 'default', true);

        } else if (type === 'email') {

            emailList.map((item, index) => {
                setEmailList.setItemProp(index, 'is_default', false);
            });


                let data = {...blankData};


                data.MP_ContactInformation_Mail.map((item, index) => item.is_default = false);

                data.MP_ContactInformation_Mail[index].is_default = true;

                setBlankData(data);

                onItem([data], true);



            setEmailList.setItemProp(index, 'is_default', true);

        }

    }


    const setContact = (values) => {

        setLoading(true);


        let phones = [];


        phoneList.map((item) => phones.push({
            phone_code_country: 0,
            phone_code: item.phone,
            phone_type: item.type,
            is_default: item.default
        }))



        if (infoItem !== null) {


            Connect().post(`panel/item/ContactInformation/edit/${infoItem.id}`, {
                firstname: values.firstname,
                lastname: values.lastname,
                country_id: values.country,
                city_id: values.city,
                organization: values.organization,
                position: values.position,
                address: values.address,
                notes: values.notes,
                MP_ContactInformation_Phone: phones,
                MP_ContactInformation_Mail: emailList
            }).then(({data}) => {

                onResult();

                SuccessViewing('Güncelleme Başarılı', modals);

            }).catch((e) => ErrorViewing(e, modals)).finally(() => setLoading(false));


        } else {

            Connect().post(`panel/item/ContactInformation/${info.id}/add`, {
                firstname: values.firstname,
                lastname: values.lastname,
                country_id: values.country,
                city_id: values.city,
                organization: values.organization,
                position: values.position,
                address: values.address,
                notes: values.notes,
                MP_ContactInformation_Phone: phones,
                MP_ContactInformation_Mail: emailList
            }).then(({data}) => {

                setInfoItem(data.result);

                onResult();

                SuccessViewing('Güncelleme Başarılı', modals);

            }).catch((e) => ErrorViewing(e, modals)).finally(() => setLoading(false));

        }


    }


    useEffect(()=>{

        if (!pageLoading) {


            let _country = _.find(countryData, {value: form.values.country});
            let _city = _.find(cityList, {value: form.values.city});


            if (infoItem !== null) {


                infoItem.firstname = form.values.firstname;
                infoItem.lastname = form.values.lastname;
                infoItem.organization = form.values.organization;
                infoItem.position = form.values.position;
                infoItem.country_id = form.values.country;
                infoItem.address = form.values.address;
                infoItem.city_id = form.values.city;
                infoItem.notes = form.values.notes;
                infoItem.country = {
                    baslik: _country !== undefined ? _country.label : ''
                };
                infoItem.city = {
                    baslik: _city !== undefined ? _city.label : ''
                }

                onItem(infoItem);
            } else {

                let data = {...blankData};

                data.firstname = form.values.firstname;
                data.lastname = form.values.lastname;
                data.organization = form.values.organization;
                data.position = form.values.position;
                data.country = {
                    baslik: _.find(countryData, {value: form.values.country}).label
                };
                data.city = {
                    baslik: _.find(cityList, {value: form.values.city}).label
                }
                data.address = form.values.address;
                data.city_id = form.values.city;
                data.notes = form.values.notes;


                setBlankData(data);

                onItem([data], true);

            }

        }

    },[form.values]);




    const phoneUpdate = (index,phone) =>{

        if (infoItem !== null){

            let phoneItem = phoneList[index];


            let find = _.find(infoItem.MP_ContactInformation_Phone, {
                id: phoneItem.id
            });



            if (find) {
                find.phone_code = phone;

                onItem(infoItem);


            } else{
                let data = {...blankData};


                if (phoneItem.phone === '90') {

                    data.MP_ContactInformation_Phone.push({
                        id: Math.floor(Math.random() * 10),
                        contact_information_id: Math.floor(Math.random() * 10),
                        phone_code_country: 0,
                        phone_code: phone,
                        is_default: phoneList.length === 1 ? true : false,
                        is_deleted: false,
                        status: true,
                        created_at: "2023-06-28T11:51:53.760Z",
                        updated_at: "2023-06-28T11:51:53.760Z",
                        phone_type: "Mobile"
                    });

                } else {

                  data['MP_ContactInformation_Phone'][index].phone_code = phone;

                }

                setBlankData(data);
                onItem(data,true);



            }



        } else {

            let data = {...blankData};

            let phoneItem = phoneList[index];


            if (phoneItem.phone === '90') {

                data.MP_ContactInformation_Phone.push({
                    id: Math.floor(Math.random() * 10),
                    contact_information_id: Math.floor(Math.random() * 10),
                    phone_code_country: 0,
                    phone_code: phone,
                    is_default: phoneList.length === 1 ? true : false,
                    is_deleted: false,
                    status: true,
                    created_at: "2023-06-28T11:51:53.760Z",
                    updated_at: "2023-06-28T11:51:53.760Z",
                    phone_type: "Mobile"
                });

            } else {

                data['MP_ContactInformation_Phone'][index].phone_code = phone;

            }



            setBlankData(data);

            onItem([data], true);


        }

    }


    const emailUpdate = (index,email) =>{

        if (infoItem !== null){

            let emailItem = emailList[index];


           let find = _.find(infoItem.MP_ContactInformation_Mail, {
                id: emailItem.id
            });

           if (find){
               find.email = email
               onItem(infoItem);

           } else{

               let data = {...blankData};

               let emailItem = emailList[index];


               if (emailItem.email === '') {

                   data.MP_ContactInformation_Mail.push({
                       id: Math.floor(Math.random() * 10),
                       contact_information_id: Math.floor(Math.random() * 10),
                       email: email,
                       is_default: emailList.length === 1 ? true : false,
                       is_deleted: false,
                       status: true,
                       created_at: "2023-06-28T11:51:53.760Z",
                       updated_at: "2023-06-28T11:51:53.760Z"
                   })

               } else {

                   data['MP_ContactInformation_Mail'][index].email = email;

               }

               setBlankData(data);

               onItem([data], true);

           }


        } else {

            let data = {...blankData};

            let emailItem = emailList[index];


            if (emailItem.email === '') {

                data.MP_ContactInformation_Mail.push({
                    id: Math.floor(Math.random() * 10),
                    contact_information_id: Math.floor(Math.random() * 10),
                    email: email,
                    is_default: emailList.length === 1 ? true : false,
                    is_deleted: false,
                    status: true,
                    created_at: "2023-06-28T11:51:53.760Z",
                    updated_at: "2023-06-28T11:51:53.760Z"
                })

            } else {

                data['MP_ContactInformation_Mail'][index].email = email;

            }

            setBlankData(data);

            onItem([data], true);


        }

    }



    return (
        <form onSubmit={form.onSubmit((values) => setContact(values))}>
            <Grid gutter={25}>

                <Grid.Col lg={6}>
                    <TextInput size={"md"} placeholder={"Ad"}
                               className={"form-input"}
                               {...form.getInputProps('firstname')}
                    />
                </Grid.Col>

                <Grid.Col lg={6}>
                    <TextInput size={"md"} placeholder={"Soyad"}
                               className={"form-input"}
                               {...form.getInputProps('lastname')}
                    />
                </Grid.Col>

                <Grid.Col lg={12}>
                    <TextInput size={"md"} placeholder={"Organizasyon"}
                               className={"form-input"}
                               {...form.getInputProps('organization')}
                    />
                </Grid.Col>

                <Grid.Col lg={12}>
                    <TextInput size={"md"} placeholder={"Pozisyon"}
                               className={"form-input"}
                               {...form.getInputProps('position')}
                    />
                </Grid.Col>


                <Grid.Col lg={12}>

                    {phoneList.map((item, index) => (
                        <div className={"phone-list-item"} key={`phone-item-${index}`}>


                            <div className={"phone-textInput"}>


                                <div className={"phone-input form-input"}>
                                    <PhoneInput
                                        value={item.phone}
                                        countryCodeEditable={false}
                                        autoFormat={true}

                                        onChange={(phone) => {
                                            phoneUpdate(index, phone)
                                            setPhoneList.setItemProp(index, 'phone', phone)

                                        }}
                                    />
                                </div>
                            </div>

                            <UnstyledButton className={"trash-btn"} onClick={() => {
                                setPhoneList.remove(index);
                            }}>
                                <IoTrashOutline/>
                            </UnstyledButton>

                            <Select className={"item-select"}
                                    style={{width: 75}}
                                    value={item.type}
                                    data={typeList}
                                    variant={'unstyled'}
                                    onChange={(val) => setPhoneList.setItemProp(index, 'type', val)}
                            />

                            <Radio
                                name={'phone'}
                                defaultChecked={item.default}
                                mt={13}
                                onChange={(event) => {
                                    updateDefault('phone', index)
                                }}
                            />

                        </div>

                    ))}

                    <Button leftIcon={ <IoAdd/>} mb={15} color={"violet"} size={"xs"} onClick={() => addPhone()}>
                        Yeni Telefon Ekle
                    </Button>


                    {emailList.map((item, index) => (
                        <div className={"phone-list-item"}>
                            <div className={"phone-textInput email-textInput"}>
                                <TextInput size={"md"} placeholder={"Email"}
                                           className={"form-input"}
                                           value={item.email}
                                           onChange={(event) => {
                                               setEmailList.setItemProp(index, 'email', event.currentTarget.value);
                                               emailUpdate(index, event.currentTarget.value)
                                           }}
                                />
                            </div>

                            <UnstyledButton className={"trash-btn"} onClick={() => {
                                setEmailList.remove(index);
                            }}>
                                <IoTrashOutline/>
                            </UnstyledButton>


                            <Radio
                                name={'email'}
                                defaultChecked={item.is_default}
                                mt={13}
                                onChange={(event) => {
                                    updateDefault('email', index)
                                }}
                            />

                        </div>
                    ))}

                   <div style={{display:'inline-block',width:'100%'}}>
                       <Button leftIcon={ <IoAdd/>} mb={10} color={"violet"} size={"xs"} onClick={() => addEmail()}>
                           Email Ekle
                       </Button>
                   </div>



                </Grid.Col>

                <Grid.Col lg={12}>
                    <Textarea size={"md"} placeholder={"Sokak Adresi"}
                              className={"form-input min-textarea"}
                              {...form.getInputProps('address')}
                    />
                </Grid.Col>

                <Grid.Col lg={6}>
                    <Select size={"md"} placeholder={"Ülke Seç"}
                            className={"form-input"}
                            data={countryData}
                            {...form.getInputProps('country')}
                    />
                </Grid.Col>


                <Grid.Col lg={6}>
                    <Select size={"md"} placeholder={"Şehir Seç"}
                            className={"form-input"}
                            data={cityList}
                            {...form.getInputProps('city')}
                    />
                </Grid.Col>


                <Grid.Col lg={12}>
                    <Textarea size={"md"} placeholder={"Not Ekle"}
                              className={"form-input min-textarea"}
                              {...form.getInputProps('notes')}
                    />
                </Grid.Col>


                <Grid.Col lg={12}>
                    <Button loading={loading} color={"violet"} fullWidth style={{height: 43}} mt={5} type={"submit"}>Güncellemeyi
                        Kaydet</Button>
                </Grid.Col>

            </Grid>
        </form>)

}


export default ContactForm;
