import {Card, Container, Grid, Loader, Select, Text, Title} from "@mantine/core";
import {useDocumentTitle} from "@mantine/hooks";
import Navbar from "../../lib/components/Nav";
import {useParams} from "react-router-dom";
import Connect from "../../lib/util/Connect";
import {ErrorViewing} from "../../lib/util/ErrorViewing";
import {useEffect, useState} from "react";
import {useModals} from "@mantine/modals";
import {IoNotifications, IoNotificationsOutline} from "react-icons/io5";


export const NoticePage = () => {

    useDocumentTitle('Bildirim Detayı')

    const {id} = useParams();
    const modals = useModals();
    const [info,setInfo] = useState({});
    const [loading, setLoading] = useState(true);


    useEffect(()=>{

        getNotice();

    },[]);

    useEffect(()=>{

      if (!loading){
          getNotice();
      }

    },[id]);

    const getNotice = () => {

        setLoading(true);

        Connect().get(`notification/get/${id}`).then(({data}) => {

            setInfo(data.result);

        }).catch((e) => ErrorViewing(e, modals)).finally(() => setLoading(false))

    }





    if (loading) {
        return (<div className={"page-full-loading"}>
            <Loader color="violet" size={"xl"} variant="dots"/>
        </div>)
    }


    return (<>

        <Navbar  id={id}/>




        <Container size={1800}>
            <Grid>
                <Grid.Col lg={12}>
                    <Card mt={30} withBorder shadow={"md"} padding={30} radius="md">
                        <Title mb={15} order={2} className={"table-title-item"}>
                            <IoNotificationsOutline style={{float:'left',marginTop:3,marginRight:6}}/>
                            {info?.title}</Title>
                        <Text size="sm" color="dimmed" mb={0}>
                            {info?.content}
                        </Text>



                    </Card>
                </Grid.Col>

            </Grid>

        </Container>

    </>)

}
export default NoticePage;
