import {
    ActionIcon,
    Badge,
    Button,
    Container,
    createStyles,
    Grid,
    Group,
    Loader,
    Modal,
    NumberInput,
    rem,
    Space,
    Switch,
    Text,
    TextInput,
    ThemeIcon,
    Title,
    Tooltip,
    UnstyledButton
} from "@mantine/core";
import {useDisclosure, useDocumentTitle, useListState} from "@mantine/hooks";
import Navbar from "../../lib/components/Nav";
import {
    IoAdd,
    IoAddCircle,
    IoClose,
    IoEyeOutline,
    IoRemove,
    IoSearch,
    IoShareSocialOutline,
    IoTrashOutline
} from "react-icons/io5";
import {DragDropContext, Draggable, Droppable} from "@hello-pangea/dnd";
import {RxDragHandleDots2} from "react-icons/rx";
import {HiOutlineDocumentAdd, HiOutlinePencilAlt} from "react-icons/hi";
import {useEffect, useState} from "react";
import {AiOutlineBank, AiOutlineFileText, AiOutlinePhone} from "react-icons/ai";
import {Link, useParams} from "react-router-dom";
import Connect from "../../lib/util/Connect";
import {modals} from "@mantine/modals";
import ContactForm from "../../lib/components/Form/contact";
import DocumentForm from "../../lib/components/Form/document";
import BillingForm from "../../lib/components/Form/billing";
import BankForm from "../../lib/components/Form/bank";
import LinkForm from "../../lib/components/Form/link";
import MemberForm from "../../lib/components/Form/member";
import SocialList from "../../lib/util/social";
import {QuestionModal} from "../../lib/util/QuestionModal";
import {useForm} from "@mantine/form";
import {notifications} from "@mantine/notifications";
import {ErrorMessage} from "../../lib/util/ErrorMessage";
import {Base64} from "js-base64";
import {getPreview, setPreview} from "../../lib/util/auth";
import { history } from '../../history';


const useStyles = createStyles((theme) => ({

    wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: `${rem(6)} ${theme.spacing.xs}`,
        borderRadius: theme.radius.sm,
        border: `${rem(1)} solid ${
            theme.colorScheme === 'dark' ? 'transparent' : theme.colors.gray[3]
        }`,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white,

        '&:focus-within': {
            borderColor: theme.colors[theme.primaryColor][6],
        },
    },

    control: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
        border: `${rem(1)} solid ${
            theme.colorScheme === 'dark' ? 'transparent' : theme.colors.gray[3]
        }`,

        '&:disabled': {
            borderColor: theme.colorScheme === 'dark' ? 'transparent' : theme.colors.gray[3],
            opacity: 0.8,
            backgroundColor: 'transparent',
        },
    },

    input: {
        textAlign: 'center',
        paddingRight: `${theme.spacing.sm} !important`,
        paddingLeft: `${theme.spacing.sm} !important`,
        height: rem(28),
        flex: 1,
    },

    item: {
        borderRadius: theme.radius.md,
        border: `${rem(1)} solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
        }`,
        padding: `${theme.spacing.sm} ${theme.spacing.xl}`,
        paddingLeft: `calc(${theme.spacing.xl} - ${theme.spacing.md})`, // to offset drag handle
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white,
        marginBottom: theme.spacing.sm,
    },

    itemDragging: {
        boxShadow: theme.shadows.sm,
    },

    symbol: {
        fontSize: rem(30),
        fontWeight: 700,
        width: rem(60),
    },


    dragHandle: {
        float: 'left',
        paddingRight: 15,
        paddingLeft: 10,
        marginTop: 36,
        cursor: 'pointer'
    },

    label: {
        textAlign: 'center',
        fontWeight: 900,
        fontSize: rem(220),
        lineHeight: 1,
        marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2],

        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(100),
        },
    },

    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        textAlign: 'center',
        fontWeight: 900,
        fontSize: rem(33),

        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(32),
        },
    },

    description: {
        maxWidth: rem(500),
        margin: 'auto',
        marginTop: theme.spacing.xl,
        marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
    },

}));


export const PanelPage = () => {

    useDocumentTitle('Panel')


    const [orderUpdate, setOrderUpdate] = useState(false);
    const [opened, {open, close}] = useDisclosure(false);
    const [pageLoading, setPageLoading] = useState(true);
    const [editOpen, setEditOpen] = useState(false);
    const [editPanel, setEditPanel] = useState(false);
    const [socialAdd, setSocialAdd] = useState(false);
    const [socialSearch, setSocialSearch] = useState('');
    const [socialItem, setSocialItem] = useState(null);
    const [editType, setEditType] = useState('');
    const [panelData, handlers] = useListState([]);
    const [panelItemData, setPanelItemData] = useListState([
        {
            id: 1,
            title: 'İletişim',
            type: 'ContactInformation',
            icon: <AiOutlinePhone size={19}/>,
            count: 0
        },
        {
            id: 2,
            title: 'Sosyal Medya / Web',
            type: 'URL_Link',
            icon: <IoShareSocialOutline size={19}/>,
            count: 0
        },
        {
            id: 3,
            title: 'Banka Hesapları',
            type: 'Bank_Info',
            icon: <AiOutlineBank size={19}/>,
            count: 0
        },
        {
            id: 4,
            title: 'Fatura Bilgileri',
            type: 'Invoice',
            icon: <AiOutlineFileText size={19}/>,
            count: 0
        },
        {
            id: 5,
            title: 'Belge Yükle',
            type: 'Document',
            icon: <HiOutlineDocumentAdd size={19}/>,
            count: 0
        },
        {
            id: 6,
            title: 'Müşter İletişim Formu',
            type: 'Customer_Form',
            icon: <AiOutlineFileText size={19}/>,
            count: 0
        },

    ]);
    const [addPanelStatus, setAddPanelStatus] = useState(false);
    const [panelAddLoading, setPanelAddLoading] = useState(false);
    const [itemInfo, setItemInfo] = useState({});
    const [baseInfo, setBaseInfo] = useState({});
    const [previewInfo, setPreviewInfo] = useState({});
    const [profileLoading, setProfileLoading] = useState(true);
    const [user, setUser] = useState({});
    const [countryData, setCountryData] = useState([]);
    const {id} = useParams();

    const {classes, cx} = useStyles();

    const form = useForm({
        initialValues: {
            title: '',
            id: '',
        },

        validate: {
            title: (value) => (value !== '' ? null : 'Başlık girmelisiniz'),
        },
    });


    useEffect(() => {

        getPanel();

        getProfile();

        getCountry();

    }, []);


    const getCountry = () => {

        Connect().get('general/countries/cities').then(({data}) =>{


            setCountryData(data.result);


        }).catch((e) =>{
            notifications.show({
                title: 'Hata',
                withCloseButton:false,
                message: ErrorMessage(e),
                color: 'red'
            });
        });

    }


    const getProfile = (type = false,itemData) => {



        Connect().get(`profile/get/${id}`).then(({data}) => {


            setUser(data.result);
            setBaseInfo(data.result);

            if (type){
                let _baseInfo = data.result;


                _baseInfo.MemberProfile = itemData;

                setPreview(Base64.encode(JSON.stringify(_baseInfo)));

                setBaseInfo(_baseInfo);
            }

            setProfileLoading(false);

        }).catch((e) => {
            notifications.show({
                title: 'Hata',
                withCloseButton: false,
                message: ErrorMessage(e),
                color: 'red'
            });

        });

    }


    const getPanel = () => {

        setOrderUpdate(false);

        Connect().get(`panel/get/${id}`).then(({data}) => {


            handlers.setState(data.result);



            setTimeout(() => {

                setPageLoading(false);
                setOrderUpdate(true);

                if (!pageLoading){

                    getProfile(true,data.result);
                }

            }, 150);





        }).catch((e) => {
            notifications.show({
                title: 'Hata',
                withCloseButton:false,
                message: ErrorMessage(e),
                color: 'red'
            });
        });

    }


    useEffect(() => {

        _addPanelStatus();


    }, [panelItemData]);


    useEffect(() => {


        if (!pageLoading) {

            let _baseInfo = {...baseInfo};


            _baseInfo.MemberProfile = panelData;

            setPreview(Base64.encode(JSON.stringify(_baseInfo)));

            setBaseInfo(_baseInfo);


        }

    }, [pageLoading]);


    const panelUpdate = (item,status,type,index) => {


        baseInfo.MemberProfile = panelData;



        if (status){

         setTimeout(()=>{
             let itemRow = {...baseInfo};

             itemRow.MemberProfile[index][`MP_${type}`] = item;

             setPreview(Base64.encode(JSON.stringify(itemRow)));
         },100);


        } else{
            let itemRow = {...baseInfo};

            itemRow.MemberProfile[index][`MP_${type}`] = item;

            setPreview(Base64.encode(JSON.stringify(itemRow)));

        }




    }


    const setCounter = (type = 'add', index = 0) => {

        let _data = [...panelItemData];

        let count = _data[index].count;


        if (type === 'add') {

            setPanelItemData.setItemProp(index, 'count', count + 1)

        } else if (type === 'remove') {

            if (count > 0) {
                setPanelItemData.setItemProp(index, 'count', count - 1)
            }
        }

    }

    const _addPanelStatus = () => {

        let status = false;

        panelItemData.map((item) => {
            if (item.count) {
                status = true;
            }
        });


        setAddPanelStatus(status);

        return status;

    }

    const FormSelect = (type, info = {},index) => {


        let form;

        switch (type) {

            case 'ContactInformation':

                form = <ContactForm onItem={(item,status) => {
                    panelUpdate(item,status,'ContactInformation',index);
                }} country={countryData} onResult={() => getPanel()} info={info}/>

                break;

            case 'Document':

                form = <DocumentForm onResult={() => getPanel()} info={info}/>

                break;

            case 'Invoice':

                form = <BillingForm onItem={(item,status) => {
                    panelUpdate(item,status,'Invoice',index);
                }} onResult={() => getPanel()} info={info}/>

                break;


            case 'Bank_Info':

                form = <BankForm onItem={(item,status) => {
                    panelUpdate(item,status,'Bank_Info',index);
                }}  onResult={() => getPanel()} info={info}/>

                break;


            case 'URL_Link':

                form = <LinkForm extraData={socialItem}  onItem={(item,status) => {
                    panelUpdate(item,status,'URL_Link',index);
                }} onResult={() => {
                    getPanel();
                    setSocialItem(null);
                }} onOpen={() => setSocialAdd(true)} info={info}/>

                break;

            default:
                form = <MemberForm onItem={(item,status) => {
                    panelUpdate(item,status,'Customer_Form',index);
                }} onResult={() => getPanel()} info={info}/>
                break;

        }


        return form;

    }

    const panelAdd = () => {

        setOrderUpdate(false);

        if (_addPanelStatus()) {

            setPanelAddLoading(true);

            let panels = {
                Bank_Info: panelItemData[2].count,
                ContactInformation: panelItemData[0].count,
                Document: panelItemData[4].count,
                Customer_Form: panelItemData[5].count,
                URL_Link: panelItemData[1].count,
                Invoice: panelItemData[3].count
            }


            Connect().post(`panel/add/${user.id}`, panels).then(({data}) => {

                getPanel();

                close();


                notifications.show({
                    title: 'Başarılı',
                    withCloseButton:false,
                    message: 'Paneller Eklendi',
                    color: 'teal'
                });

                setPanelItemData.setState([
                    {
                        id: 1,
                        title: 'İletişim',
                        type: 'ContactInformation',
                        icon: <AiOutlinePhone size={19}/>,
                        count: 0
                    },
                    {
                        id: 2,
                        title: 'Sosyal Medya / Web',
                        type: 'URL_Link',
                        icon: <IoShareSocialOutline size={19}/>,
                        count: 0
                    },
                    {
                        id: 3,
                        title: 'Banka Hesapları',
                        type: 'Bank_Info',
                        icon: <AiOutlineBank size={19}/>,
                        count: 0
                    },
                    {
                        id: 4,
                        title: 'Fatura Bilgileri',
                        type: 'Invoice',
                        icon: <AiOutlineFileText size={19}/>,
                        count: 0
                    },
                    {
                        id: 5,
                        title: 'Belge Yükle',
                        type: 'Document',
                        icon: <HiOutlineDocumentAdd size={19}/>,
                        count: 0
                    },
                    {
                        id: 6,
                        title: 'Müşter İletişim Formu',
                        type: 'Customer_Form',
                        icon: <AiOutlineFileText size={19}/>,
                        count: 0
                    },
                ])

            }).catch((e) => {
                notifications.show({
                    title: 'Hata',
                    withCloseButton:false,
                    message: ErrorMessage(e),
                    color: 'red'
                });
            }).finally(() => {
                setPanelAddLoading(false);
            })

        } else {
            notifications.show({
                title: 'Hata',
                withCloseButton:false,
                message: 'Panel Seçmelisiniz',
                color: 'red'
            });

        }


    }

    const panelRemove = (panelId = '') => {

        setOrderUpdate(false);

        Connect().get(`panel/deleted/${id}/${panelId}`).then(({data}) => {

            getPanel();

            notifications.show({
                title: 'Başarılı',
                withCloseButton: false,
                message: 'Panel Silindi',
                color: 'teal'
            });

        }).catch((e) => {

            notifications.show({
                title: 'Hata',
                withCloseButton: false,
                message: ErrorMessage(e),
                color: 'red'
            });
        })

    }


    const _editPanel = (item = {}, panelId = '', index = 0) => {

        let _baseInfo = {...baseInfo};


        _baseInfo.MemberProfile[index].status = item.status;


        setBaseInfo(_baseInfo);

        setPreview(Base64.encode(JSON.stringify(_baseInfo)));

        setOrderUpdate(false);

        Connect().post(`panel/edit/${id}/${panelId}`, item).then(({data}) => {
            getPanel();
            setEditPanel(false);

            notifications.show({
                title: 'Başarılı',
                withCloseButton: false,
                message: 'Güncelleme Tamamlandı',
                color: 'teal'
            });


        }).catch((e) => {
            notifications.show({
                title: 'Hata',
                withCloseButton:false,
                message: ErrorMessage(e),
                color: 'red'
            });
        });

    }

    const PanelItem = ({item, key, index}) => {


        return (<Grid mb={10} className={"modal-grid-item panel-grid-item"} key={key}>
            <Grid.Col span={2}>
                <ThemeIcon size={55} variant={'light'}>
                    {item.icon}
                </ThemeIcon>
            </Grid.Col>
            <Grid.Col span={6}>
                <Text ml={6} mt={17} fw={600} size={14} color={"gray"}> {item.title}</Text>
            </Grid.Col>
            <Grid.Col span={4} pl={0} mt={6} className={"panel-btn-list"}>

                <div className={classes.wrapper}>
                    <ActionIcon size={28}
                                variant="transparent"
                                onClick={() => setCounter('remove', index)}
                                className={classes.control}
                    >
                        <IoRemove size={15}/>
                    </ActionIcon>

                    <NumberInput
                        variant="unstyled"
                        value={item.count}
                        disabled
                        classNames={{input: classes.input}}
                    />

                    <ActionIcon
                        size={28}
                        variant="transparent"
                        onClick={() => setCounter('add', index)}
                        className={classes.control}
                    >
                        <IoAdd size={14}/>
                    </ActionIcon>
                </div>
            </Grid.Col>
        </Grid>)

    }

    const items = panelData.map((item, index) => (
        <Draggable key={String(item.id)} index={index} draggableId={String(item.id)}>
            {(provided, snapshot) => (
                <div
                    className={cx(classes.item, {[classes.itemDragging]: snapshot.isDragging})}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                >
                    <Grid>
                        <Grid.Col className={"drag-span-9"} span={9} lg={9}>
                            <div data-drag={"true"} className={classes.dragHandle} {...provided.dragHandleProps}>
                                <RxDragHandleDots2 size={25}/>
                            </div>
                            <div className={"panel-area-title"} style={{float: 'left', width: 'calc(100% - 50px)'}}>
                                <Group spacing={7}>
                                    <Text className={"title-panel"}>
                                        {item.title}
                                    </Text>
                                    <Tooltip withArrow label={"Başlığı Düzenle"}>
                                        <UnstyledButton onClick={() => {
                                            setEditPanel(true);
                                            form.setValues({title: item.title, id: item.id});
                                        }} style={{marginTop: 4}}>
                                            <HiOutlinePencilAlt/></UnstyledButton>
                                    </Tooltip>
                                </Group>
                                <Button className={"panel-edit-btn"} size={"xs"} mt={5} mb={8} color={"violet"}
                                        onClick={() => {
                                            setEditOpen(true);
                                            setEditType(item.panel_type);
                                            setItemInfo(item);
                                        }}>Düzenle</Button>
                                <div style={{display: 'block'}}>
                                    <Badge className={"panel-badge"} color={"violet"} variant={"light"}
                                           size={"sm"}>{item.click_count} Tıklanma</Badge>
                                </div>
                            </div>
                        </Grid.Col>
                        <Grid.Col span={3} lg={3}>
                            <div className={"mobile-position-update"}>
                                <Group position={'right'} mt={8}>
                                    <Switch defaultChecked={item.status} color={"violet"} size={"lg"} onChange={(event) => {
                                        _editPanel({status: event.currentTarget.checked}, item.id, index);
                                    }}/>
                                </Group>
                                <Group mt={20} position={'right'}>
                                    <ThemeIcon style={{cursor: 'pointer'}} variant={"light"} color={"red"}
                                               onClick={() => QuestionModal({
                                                   title: 'Paneli Sil',
                                                   text: 'Paneli silmek',
                                                   modals,
                                                   confirm: () => panelRemove(item.id),
                                               })}>
                                        <IoTrashOutline size={14}/>
                                    </ThemeIcon>
                                </Group>
                            </div>
                        </Grid.Col>
                    </Grid>


                    <div style={{display: itemInfo.id === item.id ? 'block' : 'none'}}>
                        <Text color={"white"} fw={500} size={15} style={{
                            height: 50,
                            backgroundColor: '#696969',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                            display: 'flex', marginBottom: 20,
                            marginTop: 25,
                            width: 'calc(100% + 35px)',
                            marginLeft: -10,
                            position: 'relative'
                        }}>{itemInfo?.title} Düzenle

                            <ThemeIcon radius={"xl"} className={"right-close-item"} color={"orange"} variant={"light"}
                                       onClick={() => {
                                           setEditType(false);
                                           setItemInfo({});
                                       }}><IoClose/></ThemeIcon>
                        </Text>
                        <div className={"padding-mobile-remove"} style={{padding: '20px 40px'}}>

                            {FormSelect(item.panel_type, item,index)}


                        </div>
                    </div>

                </div>
            )}
        </Draggable>
    ));


    const setOrder = () => {

        let data = [];

        panelData.map((item, index) => data.push({id: item.id, index}));

        Connect().post('panel/order', {
            values: data
        }).then(({data}) => {

            notifications.show({
                title: 'Başarılı',
                withCloseButton: false,
                message: 'Sıralama Güncellendi',
                color: 'teal'
            });


           getPanel();

        }).catch((e) => {

            notifications.show({
                title: 'Hata',
                withCloseButton: false,
                message: ErrorMessage(e),
                color: 'red'
            });

        })


    }

    useEffect(() => {


        if (orderUpdate) {
            setOrder();
        }


    }, [panelData]);


    if (pageLoading) {
        return (<div className={"page-full-loading"}>
            <Loader color="violet" size={"xl"} variant="dots"/>
        </div>)
    }

    return (<>

        <Navbar menu={'panel'} id={id}/>


        <Modal padding={30} size={500} opened={editPanel} onClose={() => setEditPanel(false)}
               title={`Başlığı Düzenle`}
               centered>
            <form onSubmit={form.onSubmit((values) => _editPanel({title: values.title}, values.id))}>

                <TextInput className={"form-input"} mb={25} placeholder={"Başlık"}
                           {...form.getInputProps('title')}
                />

                <Button fullWidth color={"violet"} type={'submit'}>Güncellemeyi Tamamla</Button>
            </form>

        </Modal>


        <Modal padding={30} size={500} opened={socialAdd} onClose={() => setSocialAdd(false)}
               title={`Yeni Hesap Ekle`}
               centered>

            <TextInput className={"form-input"} mb={25} icon={<IoSearch/>} placeholder={"Sosyal Medya Arayın"}
                       onChange={(event) => setSocialSearch(event.currentTarget.value)}/>

            <SocialList onClick={(item) => {
                setSocialItem(item);
                setSocialAdd(false);
            }} search={socialSearch}/>

        </Modal>


        <Modal padding={30} size={440} opened={opened} onClose={close} title="Panel Ekle" centered className={"panel-modal"}>

            <div>
                {panelItemData.map((item, index) => (
                    <PanelItem item={item} index={index} key={`panel-key-${index}`}/>))}
            </div>

            <Button onClick={() => panelAdd()} loading={panelAddLoading} mt={20} disabled={!addPanelStatus} fullWidth
                    color={"violet"}>
                Panel Ekle
            </Button>

        </Modal>


        <Container size={1500}>

            <Group className={"mobile-group-right-none"} position={'right'}>
                <Button onClick={() => open()} mb={30} className={"active-btn"} color={"violet"}
                        leftIcon={<IoAddCircle size={16}/>}>Panel Ekle</Button>
            </Group>

            {panelData.length === 0 &&
                <div style={{marginTop: 100}}>
                    <Title className={classes.title}>Henüz ekli panel bulunamadı.</Title>
                    <Text color="dimmed" size="sm" mt={10} align="center" className={classes.description}>
                        Yukarıdaki butona tıklayarak panel ekleyebilirsiniz.
                    </Text>
                </div>
            }

            <DragDropContext

                onDragEnd={({destination, source}) => {
                    handlers.reorder({from: source.index, to: destination?.index || 0});

                }
                }
            >
                <Droppable droppableId="dnd-list" direction="vertical">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {items}
                        </div>
                    )}

                </Droppable>
            </DragDropContext>

            <Space h={60}/>

            <div className="fixed-btn">
               <Button component={Link} to={`/profile/${user?.uuid}`} leftIcon={<IoEyeOutline/>} radius={3}
                        style={{height: 37}}
                        size={"xs"} pl={20} pr={20} color={"dark"}>Önizlemeyi Gör</Button>

            </div>

        </Container>

    </>)

}
export default PanelPage;
