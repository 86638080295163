import {
    Button,
    Card,
    Center,
    Grid,
    Group,
    Image,
    LoadingOverlay,
    Text,
    TextInput,
    Title,
    useMantineTheme
} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {useDocumentTitle} from "@mantine/hooks";
import {useModals} from "@mantine/modals";
import {useForm} from "@mantine/form";
import {Link, useNavigate} from "react-router-dom";
import {getSession} from "../../../lib/util/auth";
import Connect from "../../../lib/util/Connect";
import {notifications} from "@mantine/notifications";
import {ErrorMessage} from "../../../lib/util/ErrorMessage";
import {MdOutlineCheckCircle} from "react-icons/md";


export const Forgot = () => {


    useDocumentTitle('HibritCard - HibritMedya');

    const theme = useMantineTheme();
    const modals = useModals();
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [forgotStatus, setForgotStatus] = useState(false);


    const form = useForm({
        initialValues: {
            email: '',
        },

        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Geçersiz mail adresi'),
        },
    });


    useEffect(()=>{

        let token = getSession();

        if (token !== undefined) {
            navigate('/select-profile');
        }

    }, []);


    const setForgot = (values) => {

        setLoading(true);

        Connect().post('member/forgot/password', {
            email: values.email
        }).then(({data}) => {

            setForgotStatus(true);

        }).catch((e) => {
            notifications.show({
                title: 'Hata',
                withCloseButton: false,
                message: ErrorMessage(e),
                color: 'red'
            });

        })

    }


    return(<>

        <div className={"userPage"}>
            <Grid gutter={0} className={"h-100"}>
                <Grid.Col lg={5} className={"user-left"}>
                  <Center className={"h-100"}>
                      <Image className={"user-img"} src={'/img/white-logo.svg'} fit={"contain"} width={500}/>
                  </Center>
                </Grid.Col>
                <Grid.Col lg={7}>


                    <div className="table">
                        <div className="table-cell">
                            <Card className={"default-card user-table-card"} p={40} radius={"md"} shadow={"md"}
                                  style={{width: 550, margin: '0 auto'}}>

                                {!forgotStatus &&
                                    <>
                                        <LoadingOverlay visible={loading}
                                                        loaderProps={{size: 43, color: 'violet',}}
                                        />
                                        <Title order={2}>Şifremi Unuttum</Title>
                                        <Text className={"text"}>E-mail adresini girerek şifrenizi
                                            sıfırlayabilirsiniz.</Text>

                                        <form onSubmit={form.onSubmit((values) => setForgot(values))}>
                                            <TextInput
                                                label="E-posta"
                                                placeholder="E-posta"
                                                className={"form-input"}
                                                mt={13}
                                                mb={25}
                                                {...form.getInputProps('email')}
                                            />
                                            <Group grow>
                                                <Button  className={"active-btn"} color={"violet"}
                                                        type="submit">Şifremi Sıfırla</Button>
                                            </Group>


                                            <Center mt={15}>
                                                <Text className={"text"}>Bir hesabınız var mı? <Link to={'/login'}>Giriş
                                                    yapın</Link></Text>
                                            </Center>


                                        </form>
                                    </>
                                }

                                {forgotStatus &&
                                    <Center>
                                        <div className={"text-center"}>
                                            <MdOutlineCheckCircle size={60} color={'#1ed36b'}/>

                                            <Text mt={2} size={"lg"}>Şifre Sıfırlama Bağlantınız Gönderildi</Text>
                                            <Text mt={2} size={"xs"}>Şifre sıfırlama bağlantınız E-posta adresinize
                                                gönderildi. <br/> Hesabınıza bağlı bir telefon numarası varsa sms
                                                gönderilecektir</Text>

                                            <Button component={Link} to={'/login'} color={"violet"} size={"sm"} mt={20}>Giriş
                                                Yap</Button>

                                        </div>
                                    </Center>
                                }

                            </Card>
                        </div>
                    </div>



                </Grid.Col>
            </Grid>
        </div>

    </>)

}


export default Forgot;
