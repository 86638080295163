import {Container, Grid, Group, Image, Indicator, Menu, Text, UnstyledButton} from "@mantine/core";
import {Link} from "react-router-dom";
import {
    IoBagOutline,
    IoBarChart,
    IoChevronBack, IoLogOutOutline,
    IoNotificationsOutline,
    IoPersonOutline,
    IoPower,
    IoSettings,
    IoSettingsOutline
} from "react-icons/io5";
import {FaRegUser} from "react-icons/fa";
import {BsGrid} from "react-icons/bs";
import {connect} from "react-redux";
import {useEffect, useState} from "react";
import withRouter from "../../util/withRouter";
import {logOut} from "../../util/auth";


export const Navbar = ({menu = '', id = '', notice,user}) => {

    const [noticeList, setNoticeList] = useState([]);
    const [viewLength, setViewLength] = useState(0);


    useEffect(() => {

        let _viewLength = 0;


        if (notice.length) {
            notice.map((item) => {
                if (!item.NotificationRead.length) {
                    _viewLength += 1;
                }
            })
        }

        setNoticeList(notice);
        setViewLength(_viewLength);

    }, [notice]);






    return (<header className={"navbar"}>
        <Container size={"100%"} style={{height: '100%'}}>
            <Grid style={{height: '100%'}}>
                <Grid.Col span={2} sm={4} lg={4}>

                    <Group mt={17}>

                        <Link to={'/'} style={{padding: '5px 15px', marginRight: 15}}>
                            <IoChevronBack style={{marginTop: 3}} size={25} color={"#000"}/>
                        </Link>
                        <Image className={"logo-size-update"} src={'/img/hibritcard-logo.svg'} width={150}/>
                    </Group>
                </Grid.Col>


                <Grid.Col lg={4} span={8} sm={6} style={{height: '100%'}}>
                    {menu !== '' &&
                        <Group position={'center'} className={"menu-row-left"} style={{height: '100%'}}>
                            <Link className={menu === 'profile' ? "menu-item active" : "menu-item"} to={`/view/${id}`}>
                                <div>
                                    <FaRegUser size={18} color={"#868e96"}/>
                                    <Text fw={600} size={"sm"} color={"dimmed"}>Profil</Text>
                                </div>
                            </Link>
                            <Link className={menu === 'panel' ? "menu-item active" : "menu-item"} to={`/panel/${id}`}>
                                <div>
                                    <BsGrid style={{marginBottom: -2}} size={19} color={"#868e96"}/>
                                    <Text fw={600} size={"sm"} color={"dimmed"}>Panel</Text>
                                </div>
                            </Link>
                            <Link className={menu === 'analysis' ? "menu-item active" : "menu-item"}
                                  to={`/analysis/${id}`}>
                                <div>
                                    <IoBarChart style={{marginBottom: -2}} size={20} color={"#868e96"}/>
                                    <Text fw={600} size={"sm"} color={"dimmed"}>Analiz</Text>
                                </div>
                            </Link>
                        </Group>
                    }
                </Grid.Col>


                <Grid.Col span={2} lg={4}>
                    <Group position={'right'} mt={30} mr={25} className={"menu-item-size"}>
                        {/*
                          <Menu position={"bottom-end"} shadow="md" width={300}>
                            <Menu.Target>
                                <UnstyledButton>
                                    <Indicator label={viewLength} color={"violet"} size={15}>
                                        <IoNotificationsOutline className={"menu-size"} size={25}/>
                                    </Indicator>
                                </UnstyledButton>
                            </Menu.Target>

                            <Menu.Dropdown>
                                <Menu.Label>Bildirimler</Menu.Label>
                                {noticeList.length === 0 &&
                                    <Menu.Item>Bildirim Eklenmemiş</Menu.Item>
                                }
                                {noticeList.map((item, index) => (<>
                                    <Menu.Item className={"menu-link-item"} component={Link} to={`/notice/${item.id}`}>
                                        <b>{item.title}</b>
                                        <p>{item.content}</p>
                                    </Menu.Item>

                                </>))}
                            </Menu.Dropdown>
                        </Menu>
                        */}


                        <Menu position={"bottom-end"} shadow="md" width={240}>
                            <Menu.Target>
                                <UnstyledButton>
                                    <IoPersonOutline className={"menu-size"} size={25}/>
                                </UnstyledButton>
                            </Menu.Target>

                            <Menu.Dropdown>
                                <Menu.Label>{user?.firstname} {user?.lastname}</Menu.Label>

                                {/*

                                <Menu.Item icon={<IoBagOutline/>} component={Link} to={`/subscription/${id}`}>Aboneliklerim</Menu.Item>
                                <Menu.Divider  />
                                */}
                                <Menu.Item icon={<IoSettingsOutline/>}  component={Link} to={`/user-settings`}>Ayarlar</Menu.Item>
                                <Menu.Divider />
                                <Menu.Item color={"red"} icon={<IoLogOutOutline size={16}/>} onClick={() => logOut()} component={Link} to={`/`}>Çıkış Yap</Menu.Item>


                            </Menu.Dropdown>

                        </Menu>

                    </Group>
                </Grid.Col>
            </Grid>

        </Container>
    </header>)

}


const mapProps = (state) => ({
    notice: state.user.notice,
    user:state.user.info
});


export default connect(mapProps)(withRouter(Navbar));
