import Cookies from 'js-cookie'

export const setUser = (user) => {
    return Cookies.set('__user', JSON.stringify(user));
}

export const getUser = () => {
    const user = Cookies.get('__user');
    return JSON.parse(user !== undefined ? user : "{}");
}


export const setModalInfo = () => {
    return Cookies.set('__modal','ok', {expires:3});
}


export const getModalInfo = () => {
    const user = Cookies.get('__modal');

    return user !== undefined;

}


export const setMember = () => {
    return Cookies.set('__member', 'true');
}

export const getMember = () => {
    const member = Cookies.get('__member');
    return member !== undefined
}
