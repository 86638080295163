import {Button, CopyButton, Divider, List, ThemeIcon} from "@mantine/core";
import {AiOutlineBank, AiOutlineGlobal, AiOutlineUser} from "react-icons/ai";
import {SlLocationPin} from "react-icons/sl";
import {IoCardOutline, IoCopy, IoWalletOutline} from "react-icons/io5";
import {SocialLink} from "../../../util/socialLink";
import {SocialIcon} from "../../../util/socialcon";
import {Link} from "react-router-dom";


export const LinkPanel = ({data,theme}) => {


    return (<>

        {data.map((item,index) => (
            <div className="item-row-item" key={`social-list-${index}`}>
                <List spacing={"sm"} className={"list-item-ul"}>
                    <List.Item   icon={
                        <ThemeIcon variant="light" color="gray">
                            {SocialIcon(item.param_type)}
                        </ThemeIcon>}>
                        <Link target={"_blank"} style={{color:theme === 'light' ? '#000' : "#C1C2C5",textDecoration:'none'}} to={SocialLink(item.param_type)+item.param}>
                            {SocialLink(item.param_type)}{item.param}
                        </Link>
                    </List.Item>
                </List>

                <Divider variant={"dashed"} mt={15} mb={-20}/>
            </div>
        ))}

    </>)

}

export default LinkPanel;
