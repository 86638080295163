import {Card, Container, Grid, Select, Text, Title} from "@mantine/core";
import {useDocumentTitle} from "@mantine/hooks";
import Navbar from "../../lib/components/Nav";
import {useParams} from "react-router-dom";
import HomeChart from "../../lib/components/Chart/home";
import Chart from 'react-apexcharts';
import {useEffect, useState} from "react";
import Connect from "../../lib/util/Connect";
import moment from "moment";


export const AnalysisPage = () => {

    useDocumentTitle('Analiz')
    const {id} = useParams();


    const [monthlyData, setMonthlyData] = useState([]);
    const [year, setYear] = useState(moment().year());

    const [monthlyData2, setMonthlyData2] = useState([]);
    const [year2, setYear2] = useState(moment().year());


    useEffect(() => {


        getYearSavedData();
        getYearSavedData2();
        // setMonthlyData

    }, []);

    const getYearSavedData = (year =  moment().year()) => {

        Connect().get(`/profile/saved/card/stats/${id}/${year}`).then(({data}) => {

            console.log('saved cards stats', data);

            setMonthlyData(data.result);

        });

    };

    const getYearSavedData2 = (year =  moment().year()) => {

        Connect().get(`/profile/stats/${id}/${year}`).then(({data}) => {

            console.log('profile stats', data);

            setMonthlyData2(data.result);

        });

    };


    const generateChartOptions = () => {
        return {
            chart: {
                type: 'line',
            },
            xaxis: {
                categories: monthlyData.map(item => getMonthName(item.month)), // Ayların adlarını içeren kategorileri oluşturuyoruz
            },
            yaxis: {
                labels: {
                    formatter: function (value) {
                        return value.toFixed(0); // veya uygun bir formatlama işlemi uygulayabilirsiniz
                    },
                },
            }
        };
    };

    const generateChartData = () => {
        const chartData = {
            series: [
                {
                    name: 'Kaydedilen',
                    data: monthlyData.map(item => item.count),
                },
            ],
        };

        return chartData;
    };


    const generateChartOptions2 = () => {
        return {
            chart: {
                type: 'line',
            },
            xaxis: {
                categories: monthlyData2.map(item => getMonthName(item.month)), // Ayların adlarını içeren kategorileri oluşturuyoruz
            },
            yaxis: {
                labels: {
                    formatter: function (value) {
                        return value.toFixed(0); // veya uygun bir formatlama işlemi uygulayabilirsiniz
                    },
                },
            }
        };
    };

    const generateChartData2 = () => {
        const chartData2 = {
            series: [
                {
                    name: 'Ziyaret',
                    data: monthlyData2.map(item => item.count),
                },
            ],
        };

        return chartData2;
    };

    const getMonthName = (month) => {
        const monthNames = [
            'Ocak',
            'Şubat',
            'Mart',
            'Nisan',
            'Mayıs',
            'Haziran',
            'Temmuz',
            'Ağustos',
            'Eylül',
            'Ekim',
            'Kasım',
            'Aralık',
        ];
        return monthNames[month - 1];
    };

    // Bulunduğumuz yılın alınması
    const currentYear = moment().year();

    const yearList = [];

    for (let i = currentYear; i >= currentYear - 5; i--) {
        const yearItem = {
            label: `${i}`,
            value: `${i}`,
        };
        yearList.push(yearItem);
    }


    return (<>

        <Navbar menu={'analysis'} id={id}/>


        <Container size={1800}>
            <Grid>
                <Grid.Col lg={6}>
                    <Card mt={30} withBorder shadow={"md"} padding={30} radius="md">
                        <Title mb={2} order={2} className={"table-title-item"}>HibritCard Kullanımı</Title>
                        <Text size="xs" color="dimmed" mb={15}>
                            HibritCard profil etkileşim sayısı
                        </Text>
                        <Select data={yearList}
                                defaultValue={`${year}`}
                                onChange={(value) => getYearSavedData2(value)}
                                className={"form-input"}
                        />


                        <div style={{marginTop: 30}}>
                            <div>
                                {monthlyData2.length > 0 ? (
                                    <Chart options={generateChartOptions2()} series={generateChartData2().series}
                                           type="line" height={400}/>
                                ) : (
                                    <div>Yükleniyor...</div>
                                )}
                            </div>
                        </div>
                    </Card>
                </Grid.Col>
                <Grid.Col lg={6}>
                    <Card mt={30} withBorder shadow={"md"} padding={30} radius="md">
                        <Title mb={2} order={2} className={"table-title-item"}>Rehbere Kaydetme</Title>
                        <Text size="xs" color="dimmed" mb={15}>
                            Rehbere ekleme sayısı
                        </Text>
                        <Select data={yearList}
                                defaultValue={`${year}`}
                                onChange={(value) => getYearSavedData(value)}
                                className={"form-input"}
                        />


                        <div style={{marginTop: 30}}>
                            <div>
                                {monthlyData.length > 0 ? (
                                    <Chart options={generateChartOptions()} series={generateChartData().series}
                                           type="line" height={400}/>
                                ) : (
                                    <div>Yükleniyor...</div>
                                )}
                            </div>
                        </div>

                    </Card>
                </Grid.Col>
            </Grid>

        </Container>

    </>)

}
export default AnalysisPage;
