import {IoInformationCircleOutline} from "react-icons/io5";
import {Text} from "@mantine/core";


export const QuestionModal = (props) => {
    props.modals.openConfirmModal({
        title: '',
        centered: true,
        hideCloseButton: true,
        children: (<div className={"text-center"} style={{paddingBottom:4}}>
            <IoInformationCircleOutline color={"#abaaab"} size={73}/>
            <Text color={"gray"} style={{marginTop: 1, fontSize: '28px'}} weight={500}>{props.title}</Text>
            <Text color={"gray"} style={{marginTop: 3}} size="md">{props.text} istediğinize emin misiniz?</Text>

        </div>),
        cancelProps: {
            style: {backgroundColor: '#228be6', color: '#fff', borderColor: '#228be6'},
            className: "backButton"
        },
        confirmProps: {color: 'red', className: "confirmButton"},
        groupProps: {
            style: {
                "-webkit-box-pack": "start",
                "justify-content": "center",
                marginBottom: 35,
                marginTop: -5
            }
        },
        labels: {confirm: props.removeBtnText ?? 'Sil', cancel: "Geri dön"},
        onCancel: () => console.log('Cancel'),
        onConfirm: () => props.confirm(),
    });
}
