import BankPanel from "../components/Profile/Panel/bank";
import LinkPanel from "../components/Profile/Panel/link";
import BillingPanel from "../components/Profile/Panel/billing";
import ContactPanel from "../components/Profile/Panel/contact";
import DocumentPanel from "../components/Profile/Panel/document";
import MemberPanel from "../components/Profile/Panel/member";


export const panelType = (type = '', data, theme = 'light',uuid = '') => {


    let panel;

    switch (type) {

        case "ContactInformation":


            panel = <ContactPanel uuid={uuid} theme={theme} data={data[0] !== undefined ? data[0] : data}/>;

            break;

        case "URL_Link":

            panel = <LinkPanel theme={theme} data={data}/>;

            break;

        case "Bank_Info":

            panel = <BankPanel theme={theme} data={data}/>;

            break;

        case "Document":

            panel = <DocumentPanel theme={theme} data={data}/>;

            break;

        case "Customer_Form":

            panel = <MemberPanel theme={theme} data={data[0] !== undefined ? data[0] : data}/>;

            break;

        case "Invoice":

            panel = <BillingPanel theme={theme} data={data[0] !== undefined ? data[0] : data}/>;

            break;


        default:

            panel = '';

            break
    }


    return panel;

}


export const panelArrType = (type) => {


    return `MP_${type}`;

}
